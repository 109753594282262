import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router";
import { useTracking } from "react-tracking";

import AppBar from "../../Awesome-ui/AppBar/AppBar";

import SideNav from "../SideNav/SideNav";
import Home from "../../Pages/Home/Home";
import Profile from "../../Pages/Profile/Profile";
import SessionLogs from "../../Pages/SessionLogs/SessionLogs";
import Subscription from "../../Pages/Subscription/Subscription";
import UniversalLoginIn from "../../Pages/UniverselLogin";

import { connect } from "react-redux";
import OneSignUp from "../../Pages/OneSignUp";
import {
  LogUserWithToken,
  getAccessToken,
  logoutUser,
  setGlobalLoading,
} from "../../Actions/UserActions";
import ForgotPassword from "../../Pages/ForgotPassword/GetOtp/GetOtp";
import ConfirmOtp from "../../Pages/ForgotPassword/ConfirmOtp/ConfirmOtp";
import UpdatePass from "../../Pages/ForgotPassword/UpdatePass/UpdatePass";
import Message from "../Message/Message";
import User from "./User/index";
import ResetPassword from "../../Pages/ForgotPassword/ResetPassword";
import { OWNER } from "../../constants";
import BillingDetails from "../../Pages/BillingDetails/BillingDetails";
import Organization from "../../Pages/Orgnization/Organization";
import LogOut from "../../Pages/logout/LogOut";
import { useHistory } from "react-router-dom";
import GsuitLogin from "../../Pages/gsuit-login/GsuitLogin";
import Refer from "../../Pages/Refer";

function PublicRoutes(props) {
  const { Track, trackEvent } = useTracking();
  return (
    <>
      <Route
        path="/logout"
        exact
        component={(prop) => <LogOut {...prop} token={props.token} />}
      />
      <Route
        path="/login"
        exact
        component={(prop) => {
          return (
            <Track>
              <UniversalLoginIn
                {...prop}
                track={(type, data) =>
                  trackEvent({ page: "login", type, ...data })
                }
                token={props.token}
              />
            </Track>
          );
        }}
      />
      <Route
        path="/signup/confirm"
        exact
        component={(props) => {
          return (
            <Track>
              <ConfirmOtp
                {...props}
                signup={true}
                track={(type, data) =>
                  trackEvent({ page: "login", type, ...data })
                }
                token={props.token}
              />
            </Track>
          );
        }}
      />
      <Route
        path="/signup"
        exact
        component={(prop) => {
          return (
            <OneSignUp
              {...prop}
              track={(type, data) =>
                trackEvent({ page: "signup", type, ...data })
              }
              token={props.token}
            />
          );
        }}
      />
      <Route
        path="/:code/signup"
        exact
        component={(props) => {
          return (
            <OneSignUp
              {...props}
              signupVoucher
              track={(type, data) =>
                trackEvent({
                  page: "signupVoucher",
                  type,
                  ...data,
                })
              }
            />
          );
        }}
      />
      <Route
        path="/forgotpass"
        exact
        component={(props) => {
          return (
            <Track>
              <ForgotPassword
                track={(type, data) =>
                  trackEvent({ page: "forgotPassword", type, ...data })
                }
              />
            </Track>
          );
        }}
      />
      <Route
        path="/forgotpass/confirm"
        exact
        component={(props) => {
          return <ConfirmOtp {...props} />;
        }}
      />
      <Route
        path="/forgotpass/reset"
        exact
        component={(props) => {
          return <UpdatePass {...props} />;
        }}
      />
      <Route path="/gsuit-login" exact component={GsuitLogin} />

      <Route
        path={"/setpassword"}
        exact
        component={(props) => <ResetPassword {...props} />}
      />
      {/* {props.token &&
        !window.location.pathname.includes("login") &&
        localStorage.getItem("auth_token") && <Redirect exact from="/login" to="/" />}   */}
    </>
  );
}

function RoutWithLayout({ component: Component, name, role, user, ...rest }) {
  const [userDropDown, setUserDropDown] = useState(false);
  const { Track, trackEvent } = useTracking({ page: name });

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Track>
            <div style={{ display: "flex" }}>
              <SideNav role={role} />
              <div style={{ width: "90%", flex: 1 }}>
                <AppBar justify="space-between">
                  <div></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <User
                      userDetails={user}
                      logoutHandler={logoutUser}
                      showUserDropDown={userDropDown}
                      setShowUserDropDown={setUserDropDown}
                      track={(type, data) => trackEvent({ type, ...data })}
                    />
                  </div>
                </AppBar>
                <div
                  style={{
                    padding: "16px",
                    height: "92vh",
                    overflowY: "auto",
                    background: "#f4f8fd",
                  }}
                >
                  <Component
                    track={(type, data) => trackEvent({ type, ...data })}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </Track>
        );
      }}
    />
  );
}

const OwnerUI = ({ currentRole, token, ...props }) => {
  return (
    <>
      <Route
        path={"/billing"}
        exact
        render={(props) => <BillingDetails {...props} />}
      />
      <RoutWithLayout
        name={"home"}
        path="/"
        exact
        component={Home}
        user={props.user}
        role={currentRole}
      />
      <RoutWithLayout
        name={"profile"}
        path="/profile"
        exact
        component={Profile}
        user={props.user}
        role={currentRole}
      />
      <RoutWithLayout
        name={"sessionLogs"}
        path="/sessionLogs"
        exact
        component={SessionLogs}
        user={props.user}
        role={currentRole}
      />
      {/* <RoutWithLayout
        name={"refer"}
        path="/refer"
        component={Refer}
        user={props.user}
        role={currentRole}
      /> */}
      <RoutWithLayout
        name={"subscription"}
        path="/subscription/:view"
        exact
        component={Subscription}
        user={props.user}
        role={currentRole}
      />
      <Route
        path="/challenge/org"
        exact
        component={(props) => (
          <Organization {...props} user={props.user} role={currentRole} />
        )}
      />
      <PublicRoutes token={token} currentRole={currentRole} {...props} />
    </>
  );
};

const OtherUI = ({ currentRole, token, ...props }) => {
  return (
    <>
      <RoutWithLayout
        name={"home"}
        path="/"
        exact
        component={Home}
        user={props.user}
        role={currentRole}
      />
      <RoutWithLayout
        name={"profile"}
        path="/profile"
        exact
        component={Profile}
        user={props.user}
        role={currentRole}
      />
      <Route
        path="/challenge/org"
        exact
        component={(props) => (
          <Organization {...props} user={props.user} role={currentRole} />
        )}
      />
      <PublicRoutes token={token} currentRole={currentRole} {...props} />
    </>
  );
};

/**
 * Main layout component
 * @param {*} param0
 */
const Layout = ({ token, currentRole, ...props }) => {
  let history = useHistory();

  useEffect(() => {
    (async () => {
      if (
        token &&
        !window.location?.search?.includes("d=101") &&
        !window.location.pathname.includes("logout")
      ) {
        let data = await getAccessToken(token);
        if (!data) return logout();
        LogUserWithToken({ token: data });
      } else setGlobalLoading(false);
    })();
  }, []);

  const logout = () => {
    if (window.location.pathname === "/") return history.push("/logout");

    const redirect = [
      "/profile",
      "/security",
      "/sessionLogs",
      "/subscription",
      "/billing",
      "/challenge",
    ];

    return redirect.forEach((url, index) => {
      if (window.location.pathname.includes(url)) {
        return history.push("/logout");
      }
    });
  };

  if (currentRole === OWNER) {
    return (
      <div>
        <React.Suspense>
          <Switch>
            {!token && logout()}
            <OwnerUI token={token} currentRole={currentRole} {...props} />
          </Switch>
          <Message />
        </React.Suspense>
      </div>
    );
  } else {
    return (
      <div>
        <React.Suspense>
          <Switch>
            {!token && logout()}
            <OtherUI token={token} currentRole={currentRole} {...props} />
          </Switch>
          <Message />
        </React.Suspense>
      </div>
    );
  }
};

const mapStateToProps = ({ UserReducer, RoleReducer }) => ({
  token: UserReducer.token,
  user: UserReducer.user,
  currentRole: RoleReducer.currentRole,
});

export default connect(mapStateToProps)(Layout);
