import React from "react";
import SideDrawer from "../../Awesome-ui/SideDrawer";
import "./UserSideBar.scss";
import Avatar from "../../Awesome-ui/Avatar/Avatar";
// import ButtonWithRadial from "../../Awesome-ui/ButtonWithRadial";

// import { ReactComponent as GiftIcon } from "./images/giftbox.svg";
import { ReactComponent as LogoutIcon } from "./images/logout.svg";
// import DrawerElementWrapper from "../../Awesome-ui/DrawerElementWrapper";

//TODO: add this later 
// const sideBarOptions = [
//   {
//     name: "SideNav Lite",
//     value: "slim",
//   },
//   {
//     name: "SideNav",
//     value: "named",
//   },
//   {
//     name: "Top Nav",
//     value: "NO_SIDEBAR",
//   },
// ];
// add this later
// const themeOptions = [
//   {
//     name: "Sign Theme",
//     value: "dark",
//   },
//   {
//     name: "White Theme",
//     value: "light",
//   },
// ];

/**
 * user sidebar for the sign
 *
 * @param {*} param0
 * @returns
 */
const UserSideBar = ({
  showUserDropDown,
  setShowUserDropDown,
  userDetails,
  signOut,
  sideBarType,
  setSideBarType,
  sideBarColor,
  setSideBarColor,
}) => {
  if (!showUserDropDown) return null;

  return (
    <SideDrawer
      width="424px"
      maxWidth={"424px"}
      vis
      position="right"
      toggle={() => setShowUserDropDown((prev) => !prev)}
      toggleBtn={<div className="user-sideBar-cross">+</div>}
      className={"user-sideBar"}
    >
      <div className="drawer-top-bar">
        <Avatar
          width="50px"
          height="50px"
          user={`${userDetails?.first_name}`}
          icon={userDetails && userDetails.picture}
        />
        <div className="user-info">
          <div className="usr-name">
            {userDetails &&
              `${userDetails.first_name} ${userDetails.last_name}`}
          </div>
          <div className="usr-email">{userDetails && userDetails.email_id}</div>
          {/* <div className="setting-button">Edit Profile</div> */}
        </div>
      </div>
      <div className="usr-sidebar-body">
        {/* <div className="usr-side-bar-title">Personalize</div> */}

        {/* <DrawerElementWrapper title="Navigation Style">
                    {sideBarOptions.map((item) => (
                        <ButtonWithRadial
                            name={item.name}
                            active={item.value === sideBarType}
                            onClick={() => setSideBarType(item.value)}
                        />
                    ))}
                </DrawerElementWrapper> */}
        {/* <DrawerElementWrapper title="Theme Style">
                    {themeOptions.map((item) => (
                        <ButtonWithRadial
                            name={item.name}
                            active={item.value === sideBarColor}
                            onClick={() => setSideBarColor(item.value)}
                        />
                    ))}
                </DrawerElementWrapper> */}
        {/* <div className="appication-note">
          Application Level Note can be made for each app. So Just make a
          component
        </div>
        <div className="application-offer">
          <div>
            <b>Get upto 2 months free</b>
            <p>
              Share your experience with Awesome Social and Upto 2 Months of
              subscription free.
            </p>
          </div>
          <GiftIcon />
        </div> */}
        <div className="usr-bottom-nav">
          <div className="usr-button"></div>
          <div className="usr-button" onClick={signOut}>
            <LogoutIcon />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </SideDrawer>
  );
};

export default UserSideBar;
