import React, { useState } from "react";
import "./Profile.scss";

import { connect } from "react-redux";
import SideBar from "./Components/SideBar";

import Avatar from "../../Awesome-ui/Avatar/Avatar";

import Chip from "../../Awesome-ui/Chip/Chip";
import Tabs from "../../Awesome-ui/Tabs";
import TabPanel from "../../Awesome-ui/TabPanel";
import ProfileWithName from "../../Awesome-ui/ProfileWithName/ProfileWithName";
import ImageSelector from "../../Awesome-ui/ImageSelector";
import ProfileSecurity from "./Components/ProfileSecurity";
import {
  updateUserData,
  getAllUsersInOrganization,
  getOrganization,
} from "../../Actions/UserActions";
import Loader from "../../Awesome-ui/Loader/Loader";
import { getLinkToService, productMapping } from "../../Data/productsMapping";
import { APP_REDIRECT, CLICK, OWNER, PAGE_VIEW } from "../../constants";
import ProfileDescription from "./Components/ProfileDescription";

import crownIcon from "../../assets/icons/crown.svg";
import memberIcon from "../../assets/icons/role_user.svg";

const tabsName = {
  0: "Profile",
  1: "App",
  2: "Security",
};

const Product = ({ Icon, name, link, token, track }) => {
  function handleButtonClick() {
    const encodedToken = encodeURIComponent(token);
    let redirectLink = getLinkToService(name);
    const newLinkWithToken = `${redirectLink}?p=${encodedToken}`;
    track(APP_REDIRECT, { title: `redirecting to app ${name}` });
    window.location.assign(newLinkWithToken);
  }

  return (
    <div className="awesomsuit-apps" onClick={handleButtonClick}>
      <ProfileWithName
        title={name}
        image={Icon}
        imgHeight="50px"
        imgWidth="50px"
        fontSize="20"
        style={{ marginRight: "1rem" }}
      />
    </div>
  );
};

const Profile = ({ track, orgName, ...props }) => {
  const {
    user,
    otherUsers,
    orgUsersLoading,
    subscription,
    token,
    currentRole,
  } = props;
  const [value, setValue] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [profileImg, setProfile] = React.useState(null);
  const [activeUser, setActiveUser] = React.useState(null);
  const [activeUserDetails, setActiveUserDetails] = React.useState(null);
  const [saving, setsaving] = useState(false);

  React.useEffect(() => track(PAGE_VIEW), []);

  React.useEffect(() => {
    async function loadAllDetails() {
      await getOrganization();
      await getAllUsersInOrganization();
    }

    if (currentRole === OWNER && otherUsers === null) loadAllDetails();
  }, [otherUsers, currentRole, token]);

  React.useEffect(() => {
    if (user) {
      setActiveUserDetails(user);
      setActiveUser(user.id);
    }
  }, [user]);

  function handlerUserUpdate(id) {
    if (user && user.id === id) {
      setActiveUser(user.id);
      setActiveUserDetails(user);
    } else {
      if (otherUsers) {
        const indexOfUser = otherUsers.findIndex((item) => item.id === id);
        if (indexOfUser !== -1) {
          setActiveUser(otherUsers[indexOfUser].id);
          setActiveUserDetails(otherUsers[indexOfUser]);
        }
      }
    }
  }

  if (orgUsersLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader width="40px" height="40px" />
      </div>
    );
  }

  return (
    <div className="profile">
      {currentRole === OWNER && (
        <SideBar
          users={otherUsers}
          orgUser={user}
          activeUser={activeUser}
          handlerUserChange={handlerUserUpdate}
        />
      )}
      <div className="profile-main">
        <div className="profile-intro">
          <div className="profile-user">
            {!edit ? (
              <Avatar
                user={
                  activeUserDetails?.first_name ||
                  activeUserDetails?.email_id.substr(0, 1)
                }
                icon={
                  activeUserDetails && activeUserDetails.picture !== ""
                    ? activeUserDetails.picture
                    : null
                }
                height={80}
                width={80}
                fontSize={"1.5rem"}
              />
            ) : (
              <ImageSelector
                src={profileImg}
                setSrc={(s) => setProfile(s)}
                small
                width="5rem"
                height="5rem"
                color="green"
              />
            )}
          </div>
          <h2>
            {activeUserDetails?.first_name
              ? activeUserDetails.first_name + " " + activeUserDetails.last_name
              : "-"}
          </h2>
          <div className="profile-user-details">
            <div>{activeUserDetails ? activeUserDetails.email_id : "-"}</div>
            <div>
              {activeUserDetails?.phone_number
                ? activeUserDetails.phone_number
                : " - "}
            </div>
            {user && activeUser === user.id && (
              <Chip
                label={currentRole?.toUpperCase()}
                placement="right"
                image={
                  currentRole?.toUpperCase() === "OWNER"
                    ? crownIcon
                    : memberIcon
                }
                color={
                  currentRole?.toUpperCase() === "OWNER" ? "classic" : "member"
                }
              />
            )}
          </div>
        </div>
        <div className="profile-content">
          <Tabs
            value={value}
            tabChange={(index) => {
              track(CLICK, { title: `tab click ${tabsName[index]}` });
              setValue(index);
            }}
            noBorder
          >
            <TabPanel label="Profile" index={0} noBorder>
              <ProfileDescription
                activeUserDetails={activeUserDetails}
                user={user}
                track={track}
                setEdit={setEdit}
                edit={edit}
                profileImg={profileImg}
                orgName={orgName}
                updateUserData={updateUserData}
              />
            </TabPanel>
            <TabPanel label="Apps" index={1} noBorder>
              <UserProduct
                subscription={subscription}
                token={token}
                track={track}
              />
            </TabPanel>
            {user && activeUser === user.id && (
              <TabPanel label="Security" index={2} noBorder>
                <ProfileSecurity
                  user={user}
                  saving={saving}
                  setsaving={setsaving}
                  track={track}
                />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ UserReducer, transactionReducer, RoleReducer }) => ({
  currentRole: RoleReducer.currentRole,
  user: UserReducer.user,
  token: UserReducer.token,
  otherUsers: UserReducer.organizationUsers,
  orgUsersLoading: UserReducer.organizationUsersLoading,
  subscription: transactionReducer.subscription,
  subscriptionLoading: transactionReducer.subscriptionLoading,
  orgName: RoleReducer.orgName,
});

export default connect(mapStateToProps)(Profile);

const UserProduct = ({ subscription, token, track }) => {
  return (
    <div className="users-access-apps">
      {productMapping ? (
        productMapping.map((item) => {
          if (!subscription) return null;
          let foundSub = subscription.filter(
            (sub) => sub.productId === item.id
          );
          if (foundSub && foundSub.length === 1)
            return Product({ ...item, token, track });
        })
      ) : (
        <div></div>
      )}
    </div>
  );
};
