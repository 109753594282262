import firebase from "firebase/compat/app";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: "G-JTFT74G4SM",
};

firebase.initializeApp(firebaseConfig);

export const signWithGoogle = async (callBack) => {
  try {
    const auth = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account"});
    auth.signInWithPopup(provider)
    .then((result) => callBack(result))
    .catch(error => console.log(error));
  } catch (error) {
    console.log(error);
  }
};

export const signInWithToken = (creadentials) => {
  const cred = GoogleAuthProvider.credential(creadentials);
  return signInWithCredential(firebase.auth(), cred);
};
export default firebase;
