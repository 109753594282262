import React from "react";
import Times from "./x-lg.svg";
import "./SideBarHeader.scss";

/**
 * Side Bar Header
 *
 * Contained Notification Title & Close Icon
 *
 * @param {String} title
 * @param {String} color
 * @param {Function} setShowBar
 *
 */
const SideBarHeader = ({ title, color, setShowBar, style }) => {
  return (
    <div
      className="side-bar-header"
      style={{ backgroundColor: color && color, ...style }}
    >
      <p>{title}</p>
      <div onClick={() => setShowBar(false)}>
        <img src={Times} alt="close" />
      </div>
    </div>
  );
};

export default SideBarHeader;
