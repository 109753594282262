import React from "react";
import "./ConfirmOtp.scss";
import Template from "../components/Template";

import { userApi } from "../../../api/axios";
import { servicesMapping } from "../../../Data/productsMapping";
import { useHistory } from "react-router-dom";
import {
  forgotpassAddtoken,
  forgotpassAddEmail,
  completeSignUpFlow,
  redirectToPage,
  redirectToService,
} from "../../../Actions/UserActions";
import { createMessage } from "../../../Actions/MessageAction";
import { DANGER } from "../../../ActionType/MessageActionTypes";
import verifyEmail from "../../../assets//LoginAndSignUpIcon/verifyemail.svg";
import { connect } from "react-redux";
import { Alert } from "@awesomesuite-frontend/awesome-nebula";
import Loader from "../../../Awesome-ui/Loader/Loader";

//123456
const ConfirmOtp = (props) => {
  const [inputs, setInputs] = React.useState([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const history = useHistory();
  const [focus, setFocus] = React.useState(0);
  const FocusRef = React.useRef([null, null, null, null, null, null]);
  const [email, setEmail] = React.useState(null);
  const [service, setService] = React.useState(null);
  const [alerts, setalerts] = React.useState(null);
  const [redirectQuery, setredirectQuery] = React.useState("");
  const [coupon, setcoupon] = React.useState(null);
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    handelParams();
    // eslint-disable-next-line
    return () => {
      setcoupon(null);
    };
  }, []);

  async function handleOtpSubmit() {
    const allComplete = inputs.filter(
      (item) => item != null && item.length !== 0
    );
    if (allComplete.length !== 6) {
      return createMessage(DANGER, "Please enter correct OTP.");
    }

    try {
      const otp = inputs.reduce((prev, next) => prev + next);
      setloading(true);
      const result = await userApi.post(`/login/otp?email=${email}&otp=${otp}`);

      if (result.data.code === 200) {
        const token = result.data.model.auth_token;

        if (props.signup) {
          const signUpData = props.signUpData;
          await completeSignUpFlow(
            signUpData.email || email,
            signUpData.companyName || email,
            signUpData.phoneNumber,
            token,
            servicesMapping[service]?.id,
            coupon
          );

          if (service) {
            await redirectToService(
              service,
              token,
              "onboard",
              props.signup,
              redirectQuery
            );
          } else {
            await redirectToPage(token, null, null, props.signup);
          }
          // setloading(false);
        } else {
          forgotpassAddtoken(otp, token);
          // setloading(false);
          history.push("/forgotpass/reset" + getSearch());
        }
      } else {
        setloading(false);
        createMessage(DANGER, "Could not confirm otp.");
      }
    } catch (error) {
      setloading(false);
      createMessage(DANGER, "Could not confirm otp.");
    }
  }

  function getSearch() {
    if (window.location.search.length < 2) return "";
    let search = new URLSearchParams(window.location.search);
    search.delete("email");
    return `?${search.toString()}`;
  }

  function handelParams() {
    const search = props.location.search;
    let parsedUrl;
    if (search) parsedUrl = new URLSearchParams(search);

    let rdParams = "";
    if (parsedUrl) {
      parsedUrl.forEach((val, key, _) => {
        if (key != "service" && key != "rd") {
          rdParams = rdParams + `${key}=${val}&`;
        }
      });
    }
    rdParams = rdParams.slice(0, -1);
    setredirectQuery(rdParams);

    if (parsedUrl && parsedUrl.get("service")) {
      if (servicesMapping[parsedUrl.get("service")])
        setService(parsedUrl.get("service"));
      else setService(null);
    }
    if (parsedUrl && parsedUrl.get("email")) setEmail(parsedUrl.get("email"));
    if (parsedUrl && parsedUrl.get("couponCode")) {
      setcoupon(parsedUrl.get("couponCode"));
    }
  }

  function handleFocus(index) {
    setFocus(index);
  }

  function handleChange(index, value) {
    if (value.length > 6) return setInputs(["", "", "", "", "", ""]);
    if (value.length === 1) {
      setInputs((perv) => {
        const newInputs = [...perv];
        newInputs[index] = value;
        return newInputs;
      });

      if (!value) return;

      setFocus((prev) => prev + 1);
      const next = FocusRef.current[index + 1];
      if (next) {
        setInputs((prev) => {
          let newInputs = [...prev];
          newInputs[index + 1] = null;
          return newInputs;
        });
        next.focus();
      }
    } else if (value.length === 6 && index == 0) {
      setInputs((perv) => {
        let newInputs = [...perv];
        newInputs = newInputs.map((_, i) => value[i]);
        return newInputs;
      });
    } else {
      setInputs((perv) => {
        let newInputs = [...perv];
        newInputs[index] = "";
        return newInputs;
      });
      if (index > 0 && value.length == 0) {
        const next = FocusRef.current[index - 1];
        next.focus();
      }
    }
  }

  async function sendOtp() {
    setalerts({
      title: "Email Sent",
      message:
        "Check your email for the one-time password sent to your registered email associated with this account.",
    });
    await forgotpassAddEmail(email, props.signup ? "signup" : "forgotpassowrd");
  }

  return (
    <Template>
      <div>
        <div className="otp-form">
          <div className="forgot-pass-title">
            <h1 style={{ marginTop: 0 }}>Please enter the OTP</h1>
            <p>
              Check your email <b>{email}</b> and enter the one time password
              sent on your registered email associated with this account.
            </p>
          </div>
          <div className="enter-otp-cut-input">
            {[0, 1, 2, 3, 4, 5].map((item) => {
              const dot =
                (inputs[item] === null && focus !== item) ||
                inputs[item] === "";
              return (
                <span className={dot && "otp-input-wrapper-active"} key={item}>
                  <input
                    className="otp-input"
                    ref={(el) => (FocusRef.current[item] = el)}
                    autoFocus={focus === item}
                    value={inputs[item]}
                    onClick={() => handleFocus(item)}
                    onFocus={() => handleFocus(item)}
                    onChange={(e) => handleChange(item, e.target.value)}
                    blurOnSubmit={false}
                  />
                </span>
              );
            })}
          </div>
          <button
            className="otp-verify-btn"
            onClick={handleOtpSubmit}
            style={{ background: loading && "#7f89c0" }}
          >
            <div>
              {loading && (
                <Loader width="18px" height="18px" marginRight="8px" />
              )}
              <div>{loading ? "Verifying..." : "Verify OTP"}</div>
            </div>
          </button>
          <p className="otp-not-received">
            Didn't receive OTP ?{" "}
            <span onClick={sendOtp}>click to re-send otp.</span>
          </p>
        </div>
      </div>
      {props.signup && !service && (
        <div className="side-image">
          <img src={verifyEmail} alt="reset" />
        </div>
      )}
      {props.signup && service && servicesMapping[service].SocialOTPImg && (
        <div className="side-image">
          <img
            src={servicesMapping[service].SocialOTPImg}
            alt={service}
            width="100%"
            height="100%"
          />
        </div>
      )}
      <div className={`alerts ${alerts ? "alertsOpen" : "alertsClose"}`}>
        <Alert
          onClose={() => {
            setalerts(null);
          }}
          {...alerts}
        />
      </div>
    </Template>
  );
};

const mapStateToProps = ({ UserReducer }) => ({
  signUpData: UserReducer.signUpData,
});

export default connect(mapStateToProps)(ConfirmOtp);
