import React, { memo } from "react";
import "./Input.scss";

import { ReactComponent as EyeOnIcon } from "../../assets/utilIcons/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/utilIcons/eye-off.svg";
import { createUseStyles } from "react-jss";
import { useEffect } from "react";

const useStyles = createUseStyles((theme) => ({
  showEye: {
    width: 25,
    display: "block",
    position: "absolute",
    right: 12,
    top: 8,
  },
  closeEye: {
    display: "none",
  },
}));

/**
 * Custom input and text-field
 *
 *  Future update Validators will come later
 */
const Input = memo(
  ({
    id,
    rows,
    value,
    type,
    placeHolder,
    disabled,
    label,
    changeHandler,
    textField,
    style,
    inputStyle,
    labelStyle,
    icon,
    noEye
  }) => {
    const classes = useStyles();
    const [select, setSelect] = React.useState(false);
    const [inValue, setValue] = React.useState(value);
    const [show, setshow] = React.useState(false);

    useEffect(() => {
      if(value != inValue) {
        setValue(value);
      }
    }, [value]);

    return (
      <div className="aui-input" style={{ ...style }}>
        {label && (
          <label
            htmlFor={id}
            className="aui-input-label"
            style={{ ...labelStyle }}
          >
            {label}
          </label>
        )}
        <div
          className={`aui-input-wrapper ${disabled && "input-disabled"} ${
            label && "input-label-spacer"
          } ${select ? "aui-input-select" : ""}`}
        >
          {icon && <div className="input-icon"></div>}
          {textField ? (
            <textarea
              onFocus={() => setSelect(true)}
              onBlur={() => setSelect(false)}
              id={id}
              value={inValue}
              rows={rows ? rows : 1}
              placeholder={placeHolder && placeHolder}
              disabled={disabled}
              onChange={(e) => {
                setValue(e.target.value);
                changeHandler(e.target.value);
              }}
              style={{ ...inputStyle }}
              spellCheck={true}
            />
          ) : (
            <input
              onFocus={() => setSelect(true)}
              onBlur={() => setSelect(false)}
              id={id}
              value={inValue}
              type={show ? show : type}
              placeholder={placeHolder && placeHolder}
              disabled={disabled}
              onChange={(e) => {
                setValue(e.target.value);
                changeHandler(e.target.value);
              }}
              style={{ ...inputStyle }}
            />
          )}
          {type === "password" && !noEye && (
            <div className={classes.showEye}>
              {show ? (
                <EyeOnIcon onClick={() => setshow(false)} />
              ) : (
                <EyeOffIcon onClick={() => setshow("text")} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
