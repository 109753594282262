import React from "react";
import "./WidgetContent.css";

const WidgetContent = ({ alignItems, style, ...props }) => {
  return (
    <div
      className="widget-content"
      style={{ alignItems: alignItems, ...style }}
    >
      {props.children}
    </div>
  );
};

export default WidgetContent;
