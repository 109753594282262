import React, { useState } from "react";
// import PriceCard from "../../Awesome-ui/priceCard";
import SocialPriceCard from "../../Awesome-ui/SocialPriceCard";
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";

import { product_price_mapping, socialPrice } from "./staticPlans";
import { createUseStyles } from "react-jss";
import SignPriceCard from "../../Awesome-ui/SignPriceCard/SignPriceCard";
import DialPriceCard from "../../Awesome-ui/DialPriceCard";

const useStyles = createUseStyles((theme) => ({
  planSelector: {
    display: "flex",
    alignItems: "center",
    width: 195,
    padding: "2px 12px",
    border: "1px solid #2D3E50",
    justifyContent: "center",
    cursor: "pointer",
  },
  selected: {
    background: "#425B76",
    color: "#fff",
  },
  tag: {
    height: "fit-content",
    width: "fit-content",
    borderRadius: 12,
  },
  tagDisable: {
    color: "#fff",
    background: "#25C6FE",
  },
  tagEnable: {
    color: "#25C6FE",
    background: "#fff",
  },
  giftWrapper: {
    position: "relative",
  },
  gift: {
    top: -20,
    right: 10,
    position: "absolute",
  },
  planSelected: {
    border: "1px solid #2D3E50",
    background: "#425B76",
    borderRadius: "30px",
    color: "#fff",
  },
  backBtn: {
    borderRadius: 3,
    color: "#000",
    outline: "1px solid #d3d3d3",
    padding: "10px 20px",
    border: "none",
    background: "#f8f8f8",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

const planTypes = {
  social: [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Annually" },
    // { id: 3, name: "24 Months"},
  ],
  sign: [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Annually" },
  ],
};

const planTypesObj = { 1: "1 Month", 2: "12 Months", 3: "24 Months" };

const PlanSelector = ({ classes, planTypes, setPlan, plan }) => {
  return (
    <Grid
      item
      xs={planTypes.length == 3 ? 8 : 6}
      marginLeft="auto"
      marginRight={12}
    >
      <Grid
        container
        item
        xs={11}
        sm={11}
        padding={2}
        border="1px solid #2D3E50"
        radius="30px"
        height="inherit"
        style={{ cursor: "pointer" }}
      >
        {planTypes.map((item) => (
          <Grid
            container
            item
            xs={planTypes.length == 3 ? 4 : 6}
            align="center"
            justify="center"
            padding={10}
            border="0"
            id={item.name}
            height="inherit"
            onClick={() => setPlan(item.id)}
            className={plan == item.id ? classes.planSelected : ""}
          >
            <Text
              varient="h6"
              margin="0"
              fontSize="18px"
              color={plan == item.id ? "#fff" : "#33475b"}
            >
              {item.name}
            </Text>
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={11} sm={11}>
        {planTypes.map((item) => {
          if (!item.saving)
            return (
              <Grid
                item
                xs={planTypes.length == 3 ? 4 : 6}
                padding={10}
                border="0"
              ></Grid>
            );
          return (
            <Grid
              item
              height="inherit"
              container
              align="center"
              xs={planTypes.length == 3 ? 4 : 6}
              justify="center"
              padding={4}
              border="0"
            >
              <Grid
                container
                align="center"
                justify="center"
                xs={10}
                height="inherit"
                padding={6}
                background="#18C7C9"
                radius="30px"
              >
                <Text varient="body" fontSize="14px" color="#fff" margin="0">
                  {item.saving}
                </Text>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const Plans = ({
  product,
  plans,
  currentPlan,
  showPlans,
  classes,
  closePlan,
  plansPrice,
  upgradePlan,
  nextPlanDetails,
  loading,
}) => {
  const pClasses = useStyles();
  const [type, settype] = useState(1);

  function productFilter(item) {
    if (product === "social") return item != "Free";

    if (product === "sign") return item;

    return true;
  }

  return (
    <Grid container className={classes.plansWrapper} justify="center">
      <Grid container item xs={12} sm={11}>
        <Grid item xs={6} margin="auto 0">
          <button className={pClasses.backBtn} onClick={closePlan}>
            <span style={{ lineHeight: "112%" }}>&larr;</span>&nbsp;{" "}
            <span>Back</span>
          </button>
        </Grid>
        <Grid height={53} container item xs={6}>
          <PlanSelector
            classes={pClasses}
            planTypes={planTypes[product]}
            setPlan={settype}
            plan={type}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} justify="center" margin="3rem 0">
        {Object.keys(plansPrice)
          .filter(productFilter)
          .map((plan, key) => {
            const data = plansPrice[plan].find(
              (p) => p.planValidity == planTypesObj[type]
            );
            if (!data) return null;
            let isActive = false;
            if (nextPlanDetails) {
              isActive = nextPlanDetails.id == currentPlan?.id && false;
            } else {
              isActive = data.id === currentPlan?.id;
            }
            if (
              data.amount < currentPlan.amount &&
              data.days <= currentPlan.days
            ) {
              data.downgrade = true;
            }
            switch (product) {
              case "social":
                return (
                  <SocialPriceCard
                    xs={11}
                    sm={3}
                    {...data}
                    isActive={isActive}
                    amount={data?.amount}
                    loading={loading}
                    onClick={() => {
                      isActive ? closePlan() : upgradePlan(plan, data);
                    }}
                    data={{
                      ...data,
                      brands: parseInt(data?.noOfBrands),
                      users: data?.numberOfUsers,
                    }}
                    key={key}
                  />
                );
              case "sign":
                return (
                  <SignPriceCard
                    xs={11}
                    sm={4}
                    isActive={isActive}
                    amount={data?.amount}
                    onClick={() => {
                      isActive ? closePlan() : upgradePlan(plan, data);
                    }}
                    loading={loading}
                    data={{
                      ...data,
                      features: data.features
                        ?.split("|||")
                        ?.map((item) => item.trim()),
                    }}
                    key={key}
                  />
                );
              case "dial":
                return (
                  <DialPriceCard
                    xs={11}
                    sm={4}
                    loading={loading}
                    isActive={isActive}
                    amount={data?.amount}
                    onClick={() => {
                      isActive ? closePlan() : upgradePlan(plan, data);
                    }}
                    data={data}
                    key={key}
                  />
                );
            }
          })}
      </Grid>
    </Grid>
  );
};

export default Plans;

