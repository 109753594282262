import React, { useState } from "react";

import "./input.scss";
import { ReactComponent as EyeOnIcon } from "../../assets/utilIcons/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/utilIcons/eye-off.svg";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  showEye: {
    width: 25,
    display: "block",
    position: "absolute",
    right: 20,
    top: 18,
  },
  closeEye: {
    display: "none",
  },
}));

const InputBox = ({
  placeHolder,
  complete,
  type,
  name,
  value,
  changeFunc,
  req,
  brdr,
  src,
  focus,
  onFocus,
  // onBlur,
  reverse,
  clickFun,
  ind,
  style,
}) => {
  const classes = useStyles();
  const FocusRef = React.useRef([null]);
  const [show, setshow] = useState(false);

  const handleFocus = () => {
    onFocus();
    const next = FocusRef.current[0];
    if (next) {
      next.focus();
    }
  };

  return (
    <div
      className={`inputBox ${focus === ind ? "actBox" : ""}`}
      style={{
        borderColor: brdr ? brdr : null,
        flexDirection: reverse ? "row-reverse" : "row",
        ...style,
      }}
      onFocus={handleFocus}
      onFocusCapture={handleFocus}
      onClick={handleFocus}
    >
      <div
        className="icon"
        onClick={(e) => (clickFun ? clickFun() : e.preventDefault())}
      >
        <img src={src} alt={src} width={"100%"} />
      </div>
      <div className={`inn`}>
        <div className="label">{placeHolder}</div>
        <input
          ref={(el) => (FocusRef.current[0] = el)}
          autoComplete={`${complete}`}
          type={show ? show : type}
          name={name}
          value={value}
          onChange={(e) => changeFunc(e.target.value)}
          required={req}
          autoFocus={ind === focus ? true : false}
          // onFocus={(e) => e.currentTarget.select()}
        />
      </div>
      {type === "password" && (
        <div className={classes.showEye}>
          {show ? (
            <EyeOnIcon onClick={() => setshow(false)} />
          ) : (
            <EyeOffIcon onClick={() => setshow("text")} />
          )}
        </div>
      )}
    </div>
  );
};

export default InputBox;
