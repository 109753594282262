import React from 'react';
import "./TableRow.scss";

const TableRow = ({style, dark, ...props}) => {
    return (
        <tr className={`table-row ${dark && "dark-color"}`} style={{...style}}> 
            {props.children}
        </tr>
    )
}

export default TableRow
