import React from 'react';
import "./WidgetTitle.scss";

const WidgetTitle = ({style, ...props}) => {
    return (
        <div className="widget-top" style={{...style}}>
            {props.children}
        </div>
    )
}

export default WidgetTitle;
