import React from 'react';
import "./WidgetBottom.scss";

const WidgetBottom = ({color, ...props}) => {
    return (
        <div className="widget-bottom" style={{backgroundColor: color && color}}>
            {props.children}
        </div>
    )
}

export default WidgetBottom;
