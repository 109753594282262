import React from "react";
import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid";
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text";
import { createUseStyles } from "react-jss";
import { ReactComponent as AlertIcon } from "../../../assets/pricing/alert-modal.svg";
import Chip from "../../../Awesome-ui/Chip/Chip";
import {
  getLinkToService,
  servicesMapping,
} from "../../../Data/productsMapping";
import { useBasicUtils } from "../../../hooks/BasicUtility/basicUtils";
import Loader from "../../../Awesome-ui/Loader/Loader";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/remove-outline.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-outline.svg";

const currency = {
  USD: "$",
};

const alertIssues = [
  {
    category: "Brands",
    key: "remainingNoOfAccounts",
    buttonTitle: "Manage Brands",
  },
  {
    category: "Users",
    buttonTitle: "Manage Users",
    key: "remainingNoOfUser",
  },
  {
    category: "Media Library",
    buttonTitle: "Manage Media",
    key: "remainingStorageLimit",
  },
  {
    category: "Posts",
    buttonTitle: "Manage Posts",
    key: "remainingNoOfEvent",
  },
];

const useStyles = createUseStyles((theme) => ({
  card: {
    border: "1px solid #DBE3ED",
    boxShadow: "0px 3px 6px #DBE3ED",
    padding: 12,
    marginBottom: 12,
  },
  dateCard: {
    boxShadow: "0px 3px 6px #DBE3ED",
  },
  planLeft: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  planDescription: {
    display: "flex",
    alignItems: "center",
    margin: "0",
    marginRight: "1rem",
    marginBottom: "0.7rem",
  },
  planSelection: {
    marginBottom: "1rem",
  },
  updatebtn: {
    marginLeft: "auto",
    color: "#00A7FD",
    padding: "6px 12px",
    fontWeight: 600,
    height: "fit-content",
    cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
  planCard: {
    width: "100%",
    border: "1px solid #DBE3ED",
    padding: 12,
    display: "flex",
    marginBottom: 6,
    alignItems: "center",
    cursor: "pointer",
  },
  planCardSelected: {
    background: "#E5F2FD",
    border: "1px solid #84D8F6",
  },
  alertBox: {
    width: "100%",
    border: "1px solid #FAE0B5",
    backgroundColor: "#FEF8F0",
    boxShadow: "0px 2px 5px #aeaeae4f",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  alertBoxTop: {
    margin: "1rem",
    display: "flex",
  },
  alertBoxTopLeft: {
    flex: 1,
  },
  alertBoxTopRight: {
    margin: "auto 1rem",
  },
  alertBoxContent: {
    padding: "0 12px",
    display: "flex",
    justifyContent: "space-start",
  },
  alertBoxContentButton: {
    border: "1px solid #CBD6E2",
    borderRadius: 5,
    backgroundColor: "#ffffff",
    cursor: "pointer",
  },
  alertBoxContentEach: {
    margin: "1rem",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBox: {
    width: 20,
    height: 20,
    accentColor: "#169bc9",
  },
  planSavings: {
    padding: 6,
    borderRadius: 4,
    background: "#2ABBFE",
    color: "#ffff",
    height: "fit-content",
    marginLeft: "auto",
    fontSize: 12,
  },
  addCardBtn: {
    cursor: "pointer",
    padding: "4px 12px",
    border: "1px solid #DBE3ED",
  },
  addCardNum: {
    background: "#E5F2FD",
    padding: "4px 12px",
    border: "1px solid #E5F2FD",
  },
  cardRight: {
    marginLeft: 10,
  },
}));

function getValidity(validity) {
  if (!validity) return "";

  if (validity == "1 Month") return "Monthly";
  if (validity == "12 Months") return "Annually";
  if (validity == "24 Months") return "Billed 24 Months";
  return validity;
}

function getChipColor(status) {
  if (status) return "primary";
  else return "danger";
}

const BillingPageRight = ({
  extraResource,
  plans,
  plan,
  updateCurrentPlan,
  setplan,
  token,
  subscription,
  currentPlan,
  service,
  costCalculated,
  selectNewPlan,
  changePlanLoading,
  addons,
  addonHandler,
  isActive,
}) => {
  const classes = useStyles();
  const { namedDate } = useBasicUtils();

  return (
    <Grid container item xs={8} justify="space-between">
      <Grid item xs={7} padding={12}>
        <p>
          <b>Your Plan</b>
        </p>
        <div className={classes.card}>
          <div className={classes.planLeft}>
            <div className={classes.planSelection}>
              <h2 className={classes.planDescription}>
                {currentPlan.title}&nbsp;
                {changePlanLoading && <Loader width={16} height={16} />}
              </h2>
              {currentPlan.isActive && (
                <Chip
                  label={"Current Plan"}
                  size="medium"
                  color={getChipColor(currentPlan.isActive)}
                />
              )}
            </div>
            <h2
              className={classes.planDescription}
              style={{ alignItems: "flex-start" }}
            >
              {currency[currentPlan.currency]} {currentPlan.amount}
            </h2>
          </div>
          <div className={classes.planLeft} style={{ alignItems: "center" }}>
            <Grid container>
              {subscription.endDate && (
                <>
                  <Text margin="0" color="light" fontSize="16px">
                    Next Billing :
                  </Text>
                  &nbsp;
                  <Text margin="0" color="hard" fontSize="16px">
                    {namedDate(subscription.endDate)}
                  </Text>
                </>
              )}
            </Grid>
            <div
              className={classes.updatebtn}
              onClick={!changePlanLoading && selectNewPlan}
            >
              Update Plan
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={5} padding={12}>
        <p>
          <b>Billing Frequency</b>
        </p>
        <div>
          {plans?.map((item, key) => (
            <PlanCheckBox
              validity={item.planValidity}
              amountText={item.amountText}
              offer={item.offer}
              classes={classes}
              key={key}
              index={item.id}
              selected={item.id === plan}
              setActive={async (index) => {
                let resp = await updateCurrentPlan(currentPlan.title, item);
                if (resp) setplan(index);
              }}
            />
          ))}
        </div>
      </Grid>
      <div className={classes.planDetails}>
        {Object.keys(addons || {}).length ? (
          <Grid container xs={12} spacing={3}>
            {Object.keys(addons).map((item) => (
              <AdditionCard
                classes={classes}
                title={addons[item].title}
                pricing={addons[item].pricing}
                description={addons[item].description}
                count={addons[item].count}
                priceText={addons[item].priceText}
                inc={() => addonHandler(item, 1)}
                dec={() => addonHandler(item, -1)}
              />
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </div>
      {extraResource && (
        <AlertModal
          classes={classes}
          content={alertIssues}
          extraResource={extraResource}
          service={servicesMapping[service].name}
          token={token}
        />
      )}
      {costCalculated?.creditsEarned > 0 && (
        <Grid
          container
          justify="space-between"
          align="center"
          padding="18px 28px"
          marginBottom={10}
          marginTop={16}
          width="100%"
          background="#E5F2FD"
          border="1px solid #84D8F6"
        >
          <Grid item xs={11}>
            <Text varient="h6" marginBottom={6}>
              Downgrade Prorated Credits
            </Text>
            <Text varient="subText" margin="0" color="light" fontSize="12px">
              Adjusted in next billing cycle
            </Text>
          </Grid>
          <Grid container item xs={1} justify="center" align="center">
            <Text varient="h6" margin="0" color="#00BDA5">
              $ {costCalculated.creditsEarned}
            </Text>
          </Grid>
        </Grid>
      )}
      {costCalculated && (
        <Grid
          container
          justify="space-between"
          align="center"
          padding="18px 28px"
          marginBottom={10}
          className={classes.dateCard}
          width="100%"
          border="1px solid #DBE3ED"
        >
          <Text varient="h6">Next Billing Date</Text>
          <div>
            <Text margin="0">{namedDate(costCalculated?.nextBillingDate)}</Text>
            <Text varient="subText" margin="0" color="light" fontSize="12px">
              {getValidity(costCalculated?.newPlan?.planValidity)}
            </Text>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default BillingPageRight;

const AlertModal = ({ token, service, classes, extraResource }) => {
  let uiContent = Object.keys(extraResource)
    .map((item) => {
      if (["referenceNextPlanId", "id"].indexOf(item) != -1) return null;
      let data = alertIssues.find((issue) => issue.key === item);
      if (extraResource[item] >= 0) return null;
      data.numberOfIssues = -extraResource[item];
      return data;
    })
    .filter((item) => item);

  function buttonAction() {
    let link = getLinkToService(service?.toLowerCase());
    window.location.assign(`${link}?p=${encodeURIComponent(token)}`);
  }

  return (
    <div className={classes.alertBox}>
      <div className={classes.alertBoxTop}>
        <div className={classes.alertBoxTopLeft}>
          <Text weight={600} fontSize={18} margin="1rem 0">
            Trouble downgrading your plan
          </Text>
          <Text fontSize={13} margin="1rem 0" style={{ maxWidth: 600 }}>
            There are active resources in your current plan. Therefore, you have
            to deactivate these extra resources in your app before downgrading.
          </Text>
        </div>
        <div className={classes.alertBoxTopRight}>
          <AlertIcon />
        </div>
      </div>
      <div className={classes.alertBoxContent}>
        {uiContent.map((item, index) => {
          const { category, numberOfIssues, buttonTitle } = item;
          return (
            <div className={classes.alertBoxContentEach} key={index}>
              <div className={classes.alertBoxContentTitle}>
                <Text fontSize={15} weight={600} align="center">
                  {category}
                </Text>
              </div>
              <div className={classes.alertBoxContentNumber}>
                <Text align="center" color="#516F90">
                  {numberOfIssues}
                </Text>
              </div>
              <div
                className={classes.alertBoxContentButton}
                onClick={buttonAction}
              >
                <Text
                  fontSize={14}
                  align="center"
                  color="#516F90"
                  margin="0.5rem"
                >
                  {buttonTitle}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PlanCheckBox = ({
  classes,
  selected,
  amountText,
  validity,
  offer,
  index,
  setActive,
}) => {
  return (
    <div
      className={`${classes.planCard} 
          ${selected ? classes.planCardSelected : ""}`}
      onClick={() => setActive(index)}
    >
      <div className={`${classes.displayFlex} ${classes.planbtnRadio}`}>
        <input className={classes.checkBox} checked={selected} type="radio" />
      </div>
      <div className={classes.cardRight}>
        <Grid container align="center">
          {/* <Text margin="0" color="hard" fontSize="16px">
            {amount}&nbsp;
          </Text> */}
          <div>
            {/* {amountText?.split("\\n").map((item) => (
              <div>{item}</div>
            ))} */}
          </div>
        </Grid>
        <Text  margin="0" fontSize="16px">
          {getValidity(validity)}
        </Text>
      </div>
      {offer && <div className={classes.planSavings}>{offer}</div>}
    </div>
  );
};

const AdditionCard = ({
  classes,
  title,
  pricing,
  description,
  count,
  inc,
  dec,
  priceText,
}) => {
  return (
    <Grid container item xs={4} className={classes.card} height={190}>
      <div>
        <Text varient="h6" marginBottom={2}>
          {title}
        </Text>
        <Text varient="subText" weight="100%" marginTop="8px">
          {priceText}
        </Text>
      </div>
      <Text varient="subText" color="light" fontSize={14}>
        {description}
      </Text>
      <Grid container item xs={12} justify="center">
        <span className={classes.addCardBtn} onClick={dec}>
          <RemoveIcon height={14} />
        </span>
        <span className={classes.addCardNum}>{count}</span>
        <span className={classes.addCardBtn} onClick={inc}>
          <AddIcon height={14} />
        </span>
      </Grid>
    </Grid>
  );
};
