import React from "react";
import "./CircleCheckBox.css";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Disabled} from "./disable.svg";

const CircleCheckBox = ({ select, selectChange, disabled, ...props }) => {
  return (
    <div
      className={`aui-checkbox-select ${select && "aui-checkbox-selected"} ${disabled && "aui-checkbox-disabled"}`}
      onClick={() => {
        selectChange && selectChange((prev) => !prev);
      }}
      {...props}
    >
      {select ? <Check /> : disabled && <Disabled />}
    </div>
  );
};

export default CircleCheckBox;
