import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../Actions/UserActions";

const LogOut = () => {
  const history = useHistory();
  useEffect(() => {
    async function call() {
      await logoutUser(false);
      history.push("/login" + window.location.search);
    }
    call();
  }, []);

  return <div></div>;
};

export default LogOut;
