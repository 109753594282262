import React, { useState } from "react";

import AwesomeSuite from "../../assets/platformLogo/awesomesuite_full.svg";
import OrgIcon from "../../assets/LoginAndSignUpIcon/organisation.svg";
import AppBar from "../../Awesome-ui/AppBar/AppBar";
import InputBox from "../../Awesome-ui/InputBox";
import { useHistory } from "react-router-dom";
import Loader from "../../Awesome-ui/Loader/Loader";
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import { createUseStyles } from "react-jss";
import { createOrganization } from "../../Actions/UserActions";
import { connect } from "react-redux";
import { createMessage } from "../../Actions/MessageAction";
import { DANGER } from "../../ActionType/MessageActionTypes";

const useStyles = createUseStyles((theme) => ({
  btn: {
    width: "100%",
    height: "3.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 500,
    cursor: "pointer",
    background: "#33475B",
    "&:hover": {
      opacity: 0.8,
    },
  },
  myAcc: {
    padding: "6px 12px",
    border: "1px solid #D6DFE8",
    borderRadius: 6,
    marginRight: 12,
    cursor: "pointer",
  },
}));

const Organization = ({ token, props }) => {
  const [loading, setloading] = useState(false);
  const [orgnization, setorgnization] = useState("");
  const history = useHistory();
  const classes = useStyles();

  async function submitHandler() {
    try {
      setloading(true);
      const orgCreated = await createOrganization(orgnization, "-", "-", token);
      setloading(false);
      if (orgCreated) {
          history.replace("/");
      }
    } catch (error) {
      createMessage(DANGER, "Could not create organization.");
    }
  }

  return (
    <>
      <Grid container justify="center">
        <AppBar justify="space-between">
          <div style={{ width: "250px" }}>
            <img
              src={AwesomeSuite}
              width="100%"
              height="100%"
              alt={"awesomesuite"}
            />
          </div>
          <div>
            <div className={classes.myAcc} onClick={() => history.push("/")}>
              My account
            </div>
          </div>
        </AppBar>
      </Grid>
      <Grid
        container
        justify="center"
        align="center"
        style={{ height: "80vh" }}
      >
        <Grid item xs={4}>
          <Text varient="h3" marginBottom="1.5rem">
            Create Organization
          </Text>
          <div className="form">
            <form
              className="formSpace"
              onKeyDown={(e) => {
                if (e.key === "Enter") submitHandler();
              }}
            >
              <InputBox
                placeHolder="Organization"
                complete={"off"}
                type="text"
                name="Organization"
                value={orgnization}
                req={true}
                src={OrgIcon}
                changeFunc={(val) => setorgnization(val)}
                ind={0}
                onFocus={() => {}}
                onBlur={() => {}}
                focus={0}
              />
              <div
                className={classes.btn}
                tabIndex="0"
                onClick={submitHandler}
                style={{ backgroundColor: "#33475B" }}
              >
                Create Organization
                {loading && (
                  <span>
                    <Loader
                      height="14px"
                      width="14px"
                      border="2px solid #fff"
                      borderTop="2px solid #D6DFE8"
                    />
                  </span>
                )}
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ UserReducer }) => ({
  token: UserReducer.token,
});

export default connect(mapStateToProps)(Organization);
