import React from "react";
import Icon from "../../Awesome-ui/Icon/Icon";
import SideBar from "../../Awesome-ui/sideBar/SideBar";

import { ReactComponent as Home } from "../../assets/navIcons/Home.svg";
// import {ReactComponent as Security } from "../../assets/navIcons/Security.svg";
import { ReactComponent as Subscriptions } from "../../assets/navIcons/Subscriptions.svg";
import { ReactComponent as Sessions } from "../../assets/navIcons/Sessions & logs.svg";
import { ReactComponent as ReferIcon } from "../../assets/navIcons/refer.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { OWNER, ANY_ROLE } from "../../constants";

const iconName = [
  { name: "Home", link: "/", Icon: Home, allow: ANY_ROLE },
  {
    name: "Profile",
    link: "/profile",
    Icon: Profile,
    allow: ANY_ROLE,
  },
  {
    name: "Subscriptions",
    link: "/subscription/plans",
    Icon: Subscriptions,
    allow: OWNER,
  },
  // { name: "Security", link: "/security", Icon: Security },
  {
    name: "Sessions & logs",
    link: "/sessionLogs",
    Icon: Sessions,
    allow: OWNER,
  },
  // {
  //   name: "Become a promoter",
  //   link: "/refer",
  //   Icon: ReferIcon,
  //   allow: OWNER,
  // },
];

const SideNav = ({ role }) => {
  return (
    <SideBar>
      {iconName
        .filter((item) => (item.allow === ANY_ROLE ? true : item.allow === role))
        .map((item, index) => (
          <Icon
            SvgIcon={item.Icon}
            clicked={(ind) => {
              // this.setState({ active: ind });
            }}
            link={item.link}
            name={item.name}
            ind={index}
            key={index}
            // active={i === active}
          />
        ))}
    </SideBar>
  );
};

export default SideNav;
