import axios from "axios";

const getApiURLs = () => {
  let enviroment = process.env.REACT_APP_ENV;
  if(enviroment) enviroment = enviroment.trim();

  console.log(enviroment);
  switch (enviroment) {
    case "production":
      return prodApis;
    case "release":
      return releaseApis;
    case "testing":
      return releaseApis;
    case "dev":
      return devApis;
    case "dev-local":
      return devApis;
    default:
      return devApis;
  }
};

const prodApis = {
  USER_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
  AUTH_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
  ANALYTIC_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service/activity",
  TRANSACTION_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/payment-service",
  SESSIONS_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service",
  WORKSPACE_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workspace-service",
  SUBSCRIPTION_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/subscription-service",
  MEDIA_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/media-service",
};

const releaseApis = {
  USER_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/user-management-service",
  AUTH_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/user-management-service",
  ANALYTIC_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/activity-service/activity",
  TRANSACTION_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/payment-service",
  SESSIONS_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/activity-service",
  WORKSPACE_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/workspace-service",
  SUBSCRIPTION_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/subscription-service",
  MEDIA_API:
    "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/media-service",
};

const devApis = {
  USER_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/user-management-service",
  MEDIA_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/media-service",
  CONFIGURATION_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/configuration-service",
  PERMISSION_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/workspace-service",
  SUBSCRIPTION_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/subscription-service",
  AUTH_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/user-management-service",
  ANALYTIC_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/activity-service/activity",
  TRANSACTION_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/payment-service",
  SESSIONS_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/activity-service",
  WORKSPACE_API:
    "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/workspace-service",
};

export const APIS = getApiURLs();

export const TRANSACTION_API = APIS.TRANSACTION_API;

/**
 * static api for the media service
 */
export const medialApi = APIS.MEDIA_API;

export const workspaceApi = axios.create({
  baseURL: APIS.WORKSPACE_API,
});

export const api = axios.create({
  baseURL: APIS.SESSIONS_API,
});

export const userApi = axios.create({
  baseURL: APIS.USER_API,
});

export const authApi = axios.create({
  baseURL: APIS.AUTH_API,
});

export const analyticApi = axios.create({
  baseURL: APIS.ANALYTIC_API,
});

export const configurationApi = axios.create({
  baseURL: APIS.CONFIGURATION_API,
});

export const permissionApi = axios.create({
  baseURL: APIS.PERMISSION_API,
});

export const subscriptionApi = axios.create({
  baseURL: APIS.SUBSCRIPTION_API,
});

export const paymentApi = axios.create({
  baseURL: APIS.TRANSACTION_API,
});

export const sessionsApi = axios.create({
  baseURL: APIS.SESSIONS_API,
});
