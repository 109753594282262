import React from "react";

import "./normInput.scss";

const InputBox = ({
	placeHolder,
	complete,
	type,
	name,
	value,
	changeFunc,
	req,
	brdr,
	src,
	focus,
	onFocus,
	onBlur,
	reverse,
	disable,
	clickFun,
	ind,
	style
}) => {
	return (
		<div
			className={`normInputBox ${focus === ind ? "actBox" : ""} ${disable && "disable"}`}
			style={{
				borderColor: brdr ? brdr : null,
				flexDirection: reverse ? "row-reverse" : "row",
				...style
			}}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyPress={(e) => {
				if (e.key === "Enter") {
					if (clickFun === undefined) {
						return e.preventDefault();
					}
					clickFun();
				}
			}}
		>
			<div
				className="icon"
				onClick={(e) => {
					if (clickFun === undefined) {
						return e.preventDefault();
					}
					clickFun();
				}}
				style={{
					margin: !reverse ? "0 0.35rem 0 0.20rem" : "0 0.20rem 0 0.35rem",
				}}
			>
				<img src={src} alt={src} />
			</div>
			<div className="inputCont">
				<input
					placeholder={`${placeHolder}`}
					autoComplete={`${complete}`}
					type={type}
					name={name}
					value={value}
					disabled={disable}
					onChange={(e) => changeFunc(e.target.value)}
					required={req}
					autoFocus={ind === focus ? true : false}
					style={{
						marginRight: reverse ? "0" : "0.35rem",
						marginLeft: !reverse ? "0" : "0.35rem",
					}}
				/>
			</div>
		</div>
	);
};

export default InputBox;
