import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Dropdown, Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import tick from "../../assets/pricing/tick.svg";
import Loader from "../Loader/Loader";

const colorsMapping = {
  A: { color: "#FFB404" },
  B: { color: "#F2547D" },
  C: { color: "#635AC7" },
  D: { color: "#FFB404" },
  E: { color: "#F2547D" },
  F: { color: "#635AC7" },
  G: { color: "#FFB404" },
  H: { color: "#F2547D" },
  I: { color: "#FFB404" },
  J: { color: "#F2547D" },
  K: { color: "#635AC7" },
  L: { color: "#FFB404" },
  M: { color: "#FFB404" },
  N: { color: "#F2547D" },
  O: { color: "#FFB404" },
  P: { color: "#635AC7" },
  Q: { color: "#F2547D" },
  R: { color: "#F2547D" },
  S: { color: "#635AC7" },
  T: { color: "#FFB404" },
  U: { color: "#FFB404" },
  V: { color: "#635AC7" },
  W: { color: "#F2547D" },
  X: { color: "#F2547D" },
  Y: { color: "#F2547D" },
  Z: { color: "#FFB404" },
};

const usePriceStyles = createUseStyles((theme) => ({
  priceCard: {
    maxWidth: 390,
    padding: "12px",
    border: "1px solid #DFE3EB",
    borderRadius: 4,
    flexBasis: "24%",
    "&:hover": {
      boxShadow: "3px 3px 11px #cbd6e2b8",
    },
  },
  higilight: {
    background: "#2D3E50",
  },
  priceImg: {
    margin: "12px auto",
  },
  tick: {
    margin: "auto 0",
    marginRight: 12,
    marginTop: 5,
  },
  btn: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    width: "100%",
    padding: "14px",
    border: "2px solid #2D3E50",
    fontSize: 16,
    background: "white",
    margin: "12px 0",
    cursor: "pointer",
    "&:hover": {
      "& span": {
        color: "#fff !important",
      },
      background: "#2d3e50",
    },
  },
  btnSelected: {
    border: (props) => `2px solid ${props.color}`,
    "& span": {
      color: (props) => `${props.color} !important`,
    },
  },
  btnRecommend: {
    border: "2px solid #fff",
    background: "#2D3E50",
    "& span": {
      color: "#fff",
    },
    "&:hover": {
      "& span": {
        color: "#2D3E50 !important",
      },
      background: "#fff",
    },
  },
  popular: {
    padding: "6px 12px",
    borderRadius: 18,
    background: "white",
    color: "#425B76",
    fontSize: "12px",
    margin: "1.5rem 0 1rem 12px",
  },
  socialFlex: {
    display: "flex",
    alignItems: "center",
  },
  dropDown: {
    border: "1px solid #DFE3EB",
  },
  popularText: {
    color: "white",
  },
  normalText: {
    color: "#33475B",
  },
}));

let socialsICons = [
  "https://www.awesomesuite.com/social/pricing/socials/instagram.svg",
  "https://www.awesomesuite.com/social/pricing/socials/linkedin.svg",
  "https://www.awesomesuite.com/social/pricing/socials/facebook.svg",
  "https://www.awesomesuite.com/social/pricing/socials/pinterest.svg",
  "https://www.awesomesuite.com/social/pricing/socials/twitter.svg",
];

const SocialPriceCard = ({
  onClick,
  amount,
  description,
  amountText,
  isActive,
  data,
  isRecommend,
  xs,
  sm,
  md,
  features,
  link,
  icon,
  title,
  loading,
  id,
}) => {
  let { img, plan, users } = data;
  const { calText, socials = socialsICons } = data;
  let { brands, options } = data;
  let color = isRecommend ? "#fff" : "#2D3E50";
  const [brandsVal, setbrandsVal] = useState(brands);
  const classes = usePriceStyles({
    color: color,
  });

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      margin="0px 2px"
      marginBottom="1rem"
      className={`${classes.priceCard} ${isRecommend ? classes.higilight : ""}`}
    >
      <Grid container padding="0 13px">
        <Text
          varient="h4"
          margin="1.5rem 0 1rem 0"
          color={`${isRecommend ? "white" : "#2D3E50"}`}
        >
          {plan || title}
        </Text>
        {isRecommend && <div className={classes.popular}>Recommend</div>}
        <Text
          varient="body"
          color={`${isRecommend ? "white" : "light"}`}
          marginBottom="1.5rem"
          fontSize={14}
        >
          {description}
        </Text>
        <Grid container justify="space-between" item xs={12} align="center">
          <Grid item xs={5}>
            <Text
              varient="h2"
              margin="0"
              color={`${isRecommend ? "white" : "#33475B"}`}
            >
              ${amount}
            </Text>
          </Grid>
          <Grid item xs={7}>
            {options && options.length > 0 && (
              <Dropdown
                value={brandsVal}
                className={classes.dropDown}
                dropdown={{
                  dropdownContent: options,
                }}
                onChange={setbrandsVal}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} margin="12px 0">
          <Text varient="body" color={`${isRecommend ? "white" : "light"}`}>
            {amountText || calText}
          </Text>
        </Grid>
        <button
          className={`${classes.btn} ${isActive ? classes.btnSelected : ""} ${
            isRecommend ? classes.btnRecommend : ""
          }`}
          onClick={loading !== id && onClick}
        >
          {isActive ? <span>Current</span> : <span>Select Plan</span>}
          {loading === id && (
            <>
              &nbsp;
              <Loader width={16} height={16} />
            </>
          )}
        </button>
        <Grid container item xs={12} marginTop={12}>
          <Text varient="body" margin="0" marginBottom={12}>
            <b
              className={`${
                isRecommend ? classes.popularText : classes.normalText
              }`}
            >
              1 Brand = 1 Social Set &nbsp;
            </b>
          </Text>
          {socials && (
            <Text
              varient="body"
              margin="0"
              color={`${isRecommend ? "white" : "light"}`}
              className={classes.socialFlex}
            >
              {"("} 1 of each &nbsp;
              {socials?.map((item, key) => (
                <img
                  src={item}
                  alt="social"
                  key={key}
                  width={20}
                  style={{ margin: "0 4px" }}
                />
              ))}
              &nbsp;{")"}
            </Text>
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Text varient="body" margin="0">
            <b
              className={`${
                isRecommend ? classes.popularText : classes.normalText
              }`}
            >
              {users} {users > 1 ? "Users" : "User"}
            </b>
          </Text>
        </Grid> */}
        <Grid item xs={12} marginTop="1.8rem">
          <Text varient="body" margin="0" marginBottom={18}>
            <b
              className={`${
                isRecommend ? classes.popularText : classes.normalText
              }`}
            >
              {features?.split("|||")[0]}
            </b>
          </Text>
          {features
            ?.split("|||")
            .splice(1)
            .map((item, key) => (
              <Grid container align="flex-start" key={key} margin="16px 0">
                <Grid width="20px">
                  {typeof item === "string" && (
                    <img className={classes.tick} src={tick} alt="tick" />
                  )}
                </Grid>

                <Grid width="calc( 100% - 30px)">
                  <Text
                    varient="body"
                    margin="0"
                    fontSize={15}
                    color={`${isRecommend ? "white" : "light"}`}
                  >
                    &nbsp; {item}
                  </Text>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12} margin={"16px 0"}>
          <a href={link} style={{ color: "#33475b" }}>
            <Text
              margin="0"
              align="center"
              color={`${isRecommend ? "white" : "#33475B"}`}
            >
              or see all features
            </Text>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SocialPriceCard;
