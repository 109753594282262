import React, { useState } from "react";
import Widget from "../../../../Awesome-ui/widget/Widget";
import WidgetTitle from "../../../../Awesome-ui/WidgetTitle/WidgetTitle";
import WidgetContent from "../../../../Awesome-ui/WidgetContent/WidgetContent";
import WidgetBottom from "../../../../Awesome-ui/WidgetBottom/WidgetBottom";
import Table from "../../../../Awesome-ui/Table/Table";
import TableRow from "../../../../Awesome-ui/TableRow/TableRow";
import TableCell from "../../../../Awesome-ui/TableCell/TableCell";
import TableHeader from "../../../../Awesome-ui/TableHeader/TableHeader";
import ProfileIcon from "../../../../assets/bannerSvg/Profile-Tab.svg";
import Button from "../../../../Awesome-ui/Button/Button";
import Input from "../../../../Awesome-ui/Input/Input";
import { CLICK } from "../../../../constants";
import { ReactComponent as Edit } from "../../../../assets/utilIcons/editpen.svg";
import { DANGER } from "../../../../ActionType/MessageActionTypes";
import { createMessage } from "../../../../Actions/MessageAction";
import mediaHandler from "../../../../helper/mediaHandler/mediaHandler";
import { medialApi } from "../../../../api/axios";

let widgetStyle = {
  border: "none",
  padding: "14px 16px",
  display: "flex",
  justifyContent: "space-between",
};

const ProfileDescription = ({
  activeUserDetails,
  user,
  track,
  profileImg,
  orgName,
  updateUserData,
  edit,
  setEdit,
}) => {
  let mediaService = new mediaHandler(medialApi);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [disabled, setdisabled] = useState(false);

  React.useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setEmail(user.email_id);
      setLastName(user.last_name);
      setPhoneNumber(user.phone_number);
    }
  }, [user]);

  function handleEdit(id, first_name) {
    if (id === user.id && first_name === user.first_name) {
      setEdit(true);
    }
  }

  async function handleSubmit() {
    if (!firstName && !lastName && !phoneNumber && !email) {
      createMessage(DANGER, "Fields can't be empty !");
      return;
    }

    if (firstName.trim() === "") {
      createMessage(DANGER, "First Name can not be empty !");
      return;
    }
    if (lastName.trim() === "") {
      createMessage(DANGER, "Last Name can not be empty !");
      return;
    }
    if (phoneNumber.trim() === "") {
      createMessage(DANGER, "Phone Number can not be empty !");
      return;
    }
    if (email.trim() === "") {
      createMessage(DANGER, "Email can not be empty !");
      return;
    }
    setdisabled(true);
    let imgRes;
    if (profileImg) {
      let res = await mediaService.uploadMedia(profileImg);
      imgRes = res.mediaPath;
    }

    const { email_id, first_name, last_name, phone_number } = user;

    const data = {
      email: email_id,
      firstName: first_name,
      lastName: last_name,
      phoneNumber: phone_number,
    };

    if (phone_number !== phoneNumber) data.phoneNumber = phoneNumber;
    if (first_name !== firstName) data.firstName = firstName;
    if (last_name !== lastName) data.lastName = lastName;
    if (imgRes) data.picture = imgRes;

    await updateUserData(data);
    setdisabled(false);
    setEdit(false);
  }

  function handlerUpdateCancel() {
    track(CLICK, { title: "edit user data cancle" });
    if (user) {
      setFirstName(user.first_name);
      setEmail(user.email_id);
      setLastName(user.last_name);
      setPhoneNumber(user.phone_number);
    }
    setEdit(false);
  }



  return (
    <>
      <div className="profile-content">
        <Widget width={840} style={{ padding: "0" }}>
          <WidgetTitle style={widgetStyle}>
            <div className="text-content-big">
              <h2>Profile</h2>
              <p>
                All the accounts in this organization that you have access to.
                Click to open
              </p>
            </div>
            <div className="image">
              <img src={ProfileIcon} alt="Profile" />
            </div>
          </WidgetTitle>
          <WidgetContent>
            <Table>
              <TableRow>
                <TableCell colSpan={5}>
                  {!edit &&
                    activeUserDetails &&
                    activeUserDetails.id === user.id && (
                      <div
                        className="edit-icon"
                        onClick={() => {
                          track(CLICK, { title: "edit user data" });
                          handleEdit(
                            activeUserDetails.id,
                            activeUserDetails.first_name
                          );
                        }}
                      >
                        <span>Edit</span> <Edit />
                      </div>
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="12px"></TableCell>
                <TableHeader align="left" width="20%">
                  FIRST NAME
                </TableHeader>
                <TableCell>
                  {activeUserDetails && !edit
                    ? activeUserDetails.first_name
                    : ""}
                  {edit && (
                    <Input
                      inputStyle={{ padding: "6px 8px" }}
                      type="text"
                      value={firstName}
                      style={{ width: "90%" }}
                      changeHandler={(e) => {
                        setFirstName(e);
                      }}
                    />
                  )}
                </TableCell>
                <TableHeader align="left" width="20%">
                  LAST NAME
                </TableHeader>
                <TableCell>
                  {activeUserDetails && !edit
                    ? activeUserDetails.last_name
                    : ""}
                  {edit && (
                    <Input
                      inputStyle={{ padding: "6px 8px" }}
                      type="text"
                      value={lastName}
                      style={{ width: "90%" }}
                      changeHandler={(e) => {
                        setLastName(e);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="12px"></TableCell>
                <TableHeader align="left" width="20%">
                  EMAIL
                </TableHeader>
                <TableCell>
                  {activeUserDetails && !edit ? activeUserDetails.email_id : ""}
                  {edit && (
                    <Input
                      inputStyle={{ padding: "6px 8px" }}
                      type="text"
                      value={email}
                      disabled
                      style={{ width: "90%" }}
                      changeHandler={(e) => {}}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="12px"></TableCell>
                <TableHeader align="left" width="20%">
                  MOBILE
                </TableHeader>
                <TableCell>
                  {activeUserDetails && !edit
                    ? activeUserDetails.phone_number
                    : ""}
                  {edit && (
                    <Input
                      inputStyle={{ padding: "6px 8px" }}
                      type="number"
                      value={phoneNumber}
                      style={{ width: "90%" }}
                      changeHandler={(e) => {
                        setPhoneNumber(e);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="12px"></TableCell>
                <TableHeader align="left" width="20%">
                  TIME ZONE
                </TableHeader>
                <TableCell colSpan={3}>
                  Indian Standard Time (Asia/Kolkata)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="12px"></TableCell>
                <TableHeader align="left" width="20%">
                  ORGANIZATION
                </TableHeader>
                <TableCell>{orgName && orgName}</TableCell>
              </TableRow>
            </Table>
          </WidgetContent>
          {edit && (
            <WidgetBottom>
              <div className="edit-buttons">
                <Button
                  varient="medium"
                  color="transparent"
                  style={{
                    width: "90px",
                    marginRight: "1rem",
                    fontSize: "1rem",
                  }}
                  onClick={handlerUpdateCancel}
                  disabled={disabled}
                >
                  cancel
                </Button>
                <Button
                  varient="medium"
                  color="primary"
                  style={{ width: "90px", fontSize: "1rem" }}
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  save
                </Button>
              </div>
            </WidgetBottom>
          )}
        </Widget>
      </div>
    </>
  );
};

export default ProfileDescription;
