import React from "react";
import Tab from "../Tab";
import "./Tabs.scss";

/**
 * This is the tab component which is a wraper for the tab componets
 * you require to wrap you Tabpanel component with Tabs and provide >>index<< to every pannel
 * and tab changes will be controlled by this parent only
 *
 * @param {children} its TabPanel Compnent children
 *
 * @param {tabChange} it is a handler function with give new index and change the active tab value (you must provide)
 *
 * @param {value} you must provide the current value
 *
 */
const Tabs = ({
  children,
  value,
  tabChange,
  noRadius,
  noPadding,
  noBorder,
  ...props
}) => {
  function handlerChange(index) {
    tabChange(index);
  }
  return (
    <div className="tabs">
      <div className="tab-nav">
        {children
          .filter((child) => child)
          .map((child) => {
            return (
              <Tab
                key={child.props.index}
                activeTab={value}
                noBorder={noRadius}
                handleChange={handlerChange}
                index={child.props.index}
                label={child.props.label}
              />
            );
          })}
        <div className="tab-rest"></div>
      </div>
      <div
        className={`tab-content ${noPadding ? "noPadding" : ""}  ${
          noBorder ? "noBorder" : ""
        }`}
      >
        {children
          .filter((child) => child)
          .map((child) => {
            if (child.props.index !== value) return undefined;
            return child.props.children;
          })}
      </div>
    </div>
  );
};

export default Tabs;
