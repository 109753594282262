import React from "react";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import { deleteCard } from "../../../Actions/transactionAction";
import { ReactComponent as CardIcon } from "../../../assets/utilIcons/mastercard.svg";
import { getLinkToService } from "../../../Data/productsMapping";

const useStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    padding: "24px 12px",
  },
  contentLeft: {
    width: "60%",
    padding: 12,
  },
  contentRight: {
    width: "40%",
    padding: 12,
    display: "flex",
    justifyContent: "flex-end",
  },
  card: {
    border: "1px solid #DBE3ED",
    boxShadow: "0px 3px 6px #DBE3ED",
    padding: 12,
    display: "flex",
    marginBottom: 12,
  },
  cardIcon: {
    height: 50,
    width: 90,
    marginRight: 12,
  },
  cardDetails: {
    color: "#33475B",
  },
  textlt: {
    color: "#99ACC2",
  },
  button: {
    height: "fit-content",
    outline: "none",
    padding: "12px 24px",
    background: "#6A78D1",
    color: "#fff",
    border: "none",
    cursor: "pointer"
  },
  deleteTx: {
    marginLeft: "auto",
    color: "#F2545B",
    padding: "6px 12px",
    height: "fit-content",
    cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
}));

const PaymentMethod = ({ card, token }) => {
  const classes = useStyles();

  function handlerAddCardButton() {
    if (token) {
      const checkouturl = getLinkToService("checkout");
      window.open(
        `${checkouturl}/addcard/?token=${token}&redirect=${window.location.origin}/subscription/payment-methods`
      );
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentLeft}>
        {card ? (
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <CardIcon />
            </div>
            <div className={classes.cardDetails}>
              <div>{card.masked_number}</div>
              <div className={classes.textlt}>
                Expiration: {card.expiry_month + "/" + card.expiry_year}
              </div>
              <div className={classes.textdk}>{card.first_name}</div>
            </div>
            <div className={classes.deleteTx} onClick={deleteCard}>
              Remove
            </div>
          </div>
        ) : (
          <div>No Card Available</div>
        )}
      </div>
      <div className={classes.contentRight}>
        <button className={classes.button} onClick={handlerAddCardButton}>
          Add Payment Method
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ transactionReducer, UserReducer }) => ({
  card: transactionReducer.card,
  token: UserReducer.token,
});

export default connect(mapStateToProps)(PaymentMethod);
