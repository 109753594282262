import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid";
import React from "react";
import { signWithGoogle } from "../../configuration/firebase";
import firebase from "../../configuration/firebase";
import { oAuthLoginGsuite } from "../../Actions/UserActions";
import { ReactComponent as GoogleIcon } from "../../assets/LoginIcon/google.svg";
import { createUseStyles } from "react-jss";
import AwesomeSuite from "../../assets/platformLogo/awesomesuite_full.svg";
import AppBar from "../../Awesome-ui/AppBar/AppBar";

const useStyles = createUseStyles((theme) => ({
  googleBtn: {
    width: "100%",
    minWidth: "min-content",
    backgroundColor: "#1a73e8",
    border: "none",
    color: "#fff",
    borderRadius: 4,
    boxSizing: "border-box",
    transition: "background-color 0.218s, border-color 0.218s",
    backgroundImage: "none",
    border: "1px solid #dadce0",
    cursor: "pointer",
    fontFamily: `"Google Sans", arial, sans-serif`,
    fontSize: 14,
    height: 46,
    letterSpacing: 0.25,
    outline: "none",
    overflow: "hidden",
    padding: "0 12px",
    position: "relative",
    textAlign: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  googleBtnSps: {
    transition: "background-color 0.218s",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  googleBtnSpa: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    height: "100%",
    position: "relative",
    width: "100%",
  },
  googleBtnSpb: {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    height: 36,
    marginLeft: -10,
    marginRight: 12,
    minWidth: 36,
    width: 36,
  },
  googleBtnImg: {
    margin: 0,
    padding: 0,
    height: 18,
    minWidth: 18,
    width: 18,
  },
  googleBtnTxt: {
    "-webkit-flex-grow": 1,
    flexGrow: 1,
    fontFamily: '"Google Sans", arial, sans-serif',
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "top",
  },
}));

const GsuitLogin = () => {
  let clx = useStyles();

  async function oAuthSignIn(user) {
    if (!user) return;

    let data = { response_type: "code", state: null, redirect_uri: null };
    let params = new URLSearchParams(window.location.search);
    if (params.get("response_type"))
      data.response_type = params.get("response_type");
    if (params.get("redirect_uri"))
      data.redirect_uri = params.get("redirect_uri");
    if (params.get("state")) data.state = params.get("state");

    const tokens = await firebase.auth()?.currentUser?.getIdToken();
    data.email = user?.additionalUserInfo?.profile?.email;
    oAuthLoginGsuite(tokens, data);
  }

  return (
    <Grid>
      <Grid container justify="center">
        <AppBar justify="space-between">
          <div style={{ width: "220px" }}>
            <img
              src={AwesomeSuite}
              width="100%"
              height="100%"
              alt={"awesomesuite"}
            />
          </div>
          <div>
            <div className={clx.myAcc}></div>
          </div>
        </AppBar>
      </Grid>
      <Grid container align="center" justify="center" height="90vh">
        <Grid item xs={5} sm={4} md={2}>
          <div onClick={() => signWithGoogle(oAuthSignIn)}>
            <div
              tabindex="0"
              role="button"
              aria-labelledby="button-label"
              className={clx.googleBtn}
            >
              <div className={clx.googleBtnSps}></div>
              <div className={clx.googleBtnSpa}>
                <div className={clx.googleBtnSpb}>
                  <div className={clx.googleBtnImg}>
                    <GoogleIcon />
                  </div>
                </div>
                <span className={clx.googleBtnTxt}>Sign in with Google</span>
                <span style={{ display: "none" }} id="button-label">
                  Sign in with Google
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GsuitLogin;
