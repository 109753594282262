import React from "react";

import "./Table.scss";

const Table = ({ border,style, ...props }) => {
  return (
    <table className={`table ${border ? "border" : ""}`} style={{...style}}>
      <tbody>{props.children}</tbody>
    </table>
  );
};

export default Table;
