import { getHeaders } from "../../Actions/UserActions";
import Axios from "axios";

/**
 * mediaHandler for handling all media related related stuff
 * use by initializing it with the end point and then use the handler provided by this.
 *
 * @example
 * ```
 *     const useMedia = new mediaHandler(mediaApi);
 * ```
 */
class mediaHandler {
  constructor(apiEndPoint) {
    this.mediaApi = Axios.create({
      baseURL: apiEndPoint,
    });
  }

  async getBobFromFile(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          const dataWithOtherInfo = e.target.result.split(",")[1];
          resolve(dataWithOtherInfo);
        };
      } catch (error) {
        reject("Failed to Get the uploaded file data");
      }
    });
  }

  removeTypeDescription(blobUrl) {
    const splitData = blobUrl.split(",");
    return {
      data: splitData[1],
      otherDescription: splitData[0],
    };
  }

  /**
   * Upload file to  the server and get the media id
   *
   * @example
   * ```
   *     {
   *      "id": 9,
   *      "mediaId": "media_1630355551",
   *      "mediaType": "image/jpeg",
   *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
   *      "isEnabled": 1,
   *      "userId": 12,
   *      "relatedId": "",
   *      "status": "success"
   *     }
   *```
   *
   * */
  async uploadMedia(file, otherFileName, authToken) {
    if (!(file instanceof File)) return;

    let fileNameToSend = file.name;

    if (otherFileName) fileNameToSend = otherFileName;

    var fileToSend = await this.getBobFromFile(file);

    const data = {
      fileName: fileNameToSend,
      dataType: file.type,
      data: fileToSend,
    };

    try {
      const mediaUploadResult = await this.mediaApi.post(
        "/media?action=upload",
        data,
        getHeaders()
      );

      if (mediaUploadResult.data.code === 200) {
        return mediaUploadResult.data.model;
      } else {
        return new Error(
          "Could not upload the file due to some technical issue"
        );
      }
    } catch (error) {
      return new Error("Could not upload the media");
    }
  }

  /**
   * Upload file data url directly.
   *
   * @param dataUrl - data url like "data:image/png;base64,soifjiosdjfoisdf"
   * @example
   * ```
   *     {
   *      "id": 9,
   *      "mediaId": "media_1630355551",
   *      "mediaType": "image/jpeg",
   *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
   *      "isEnabled": 1,
   *      "userId": 12,
   *      "relatedId": "",
   *      "status": "success"
   *     }
   *```
   *
   * */
  async uploadMediaBlob(dataUrl, authToken, alterName = "sign.png") {
    if (!dataUrl) return;

    let fileNameToSend = alterName;

    var fileToSend = await this.removeTypeDescription(dataUrl);

    const data = {
      fileName: fileNameToSend,
      dataType: "image/png",
      data: fileToSend.data,
    };

    try {
      const mediaUploadResult = await this.mediaApi.post(
        "/media?action=upload",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (mediaUploadResult.data.code === 200) {
        return mediaUploadResult.data.model;
      } else {
        return new Error(
          "Could not upload the file due to some technical issue"
        );
      }
    } catch (error) {
      return new Error("Could not upload the media");
    }
  }

  /**
   * get Uploaded media details with the media id
   *
   * @example
   * ```
   *     {
   *      "id": 9,
   *      "mediaId": "media_1630355551",
   *      "mediaType": "image/jpeg",
   *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
   *      "isEnabled": 1,
   *      "userId": 12,
   *      "relatedId": "",
   *      "status": "success"
   *     }
   *```
   *
   * */
  async getMediaById(mediaId, authToken) {
    if (!mediaId)
      return new Error("Could not find the media id in the parameters");

    try {
      const mediaUploadResult = await this.mediaApi.get(
        `/media?media_id=${mediaId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (mediaUploadResult.data.code === 200) {
        return mediaUploadResult.data.model;
      } else {
        return new Error("Could not load the file due to some technical issue");
      }
    } catch (error) {
      return new Error("Could not upload the media");
    }
  }

  /**
   * get all Uploaded media details for the user using the auth token
   *
   * @example
   * ```
   *     [ {
   *      "id": 9,
   *      "mediaId": "media_1630355551",
   *      "mediaType": "image/jpeg",
   *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
   *      "isEnabled": 1,
   *      "userId": 12,
   *      "relatedId": "",
   *      "status": "success"
   *     } ]
   *```
   *
   * */
  async getAllMediaForUser(authToken) {
    try {
      const mediaUploadResult = await this.mediaApi.get(`/media`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (mediaUploadResult.data.code === 200) {
        return mediaUploadResult.data.model;
      } else {
        return new Error("Could not load the file due to some technical issue");
      }
    } catch (error) {
      return new Error("Could not upload the media");
    }
  }

  async uploadMediaOther(fileName, type, fileId, platform = "drive", token) {
    try {
      const data = {
        fileName: fileName,
        dataType: type || "image/jpeg",
        fileId: fileId,
        relatedId: null,
        token: token,
      };

      const header = getHeaders();
      const mediaUploadResult = await this.mediaApi.post(
        `/media_platform?platform=${platform}`,
        data,
        header
      );

      if (mediaUploadResult.data.code === 200) {
        return mediaUploadResult.data.model;
      } else {
        return new Error(
          "Could not upload the file due to some technical issue"
        );
      }
    } catch (error) {
      throw new Error("Could not upload the media");
    }
  }
}

export default mediaHandler;
