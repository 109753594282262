import React from 'react'
import "./AppBar.scss";

/**
 * To be used as wrapper for the users
 * 
 * @param {String} color 
 * @param {String} justify Type [space-around, space-between, space-evenly]
 */
const AppBar = ({color, justify, ...props}) => {
    return (
        <div className={`AppBar`}>
            <div className={`ToolBar ${justify}`}>
                {props.children}
            </div>
        </div>
    )
}

export default AppBar;
