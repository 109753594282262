import React, { Component } from "react";

import "./sideBar.scss";
import AwesomeSuiteFull from "../../assets/platformLogo/awesomesuite_full.svg";
import AwesomeSuiteSmall from "../../assets/platformLogo/awesomesuite_small.svg";

/**
 * This is Side Bar or a wrapper to wrap the nav icons.
 * 
 * @param {[Icons]} Icons List to show
 * It needs only List of Icons
 */
class SideBar extends Component {
	state = {
		active: 0,
		expand:
			localStorage.getItem("SidebarOpen") &&
			localStorage.getItem("SidebarOpen") === "true"
				? 1
				: 0,
	};

	render() {
		const { expand } = this.state;
		return (
			<>
			<div className={`sideBar ${!expand ? "slim" : "expand"}`}>
				<div className="topIcons">
					<div
						onClick={() => {
							this.setState({ expand: !this.state.expand }, () =>
								localStorage.setItem("SidebarOpen", this.state.expand)
							);
						}}
						className="logo"
					>
						<div>
							<img
								src={`${expand ? AwesomeSuiteFull :  AwesomeSuiteSmall}`}
								alt="Awesome accounts"
							/>
						</div>
					</div>
					{this.props.children}
				</div>
			</div>
			<div className={`spacer ${expand ? "space-expand" : "space-slim"}`}></div>
			</>
		);
	}
}

export default SideBar;
