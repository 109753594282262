import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Home.scss";
import Avatar from "../../Awesome-ui/Avatar/Avatar";
import Widget from "../../Awesome-ui/widget/Widget";
import WidgetBottom from "../../Awesome-ui/WidgetBottom/WidgetBottom";
import WidgetTitle from "../../Awesome-ui/WidgetTitle/WidgetTitle";
import WidgetContent from "../../Awesome-ui/WidgetContent/WidgetContent";
import ProfileWithName from "../../Awesome-ui/ProfileWithName/ProfileWithName";
import Button from "../../Awesome-ui/Button/Button";

import paymentsBanner from "../../assets/bannerSvg/Home-Payments.svg";
import securityBanner from "../../assets/bannerSvg/Home-Security.svg";

import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { UpdateUserData } from "../../Actions/UserActions";
import { getLinkToService, productMapping } from "../../Data/productsMapping";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import {
  APP_REDIRECT,
  CLICK,
  OWNER,
  PAGE_VIEW,
  PRODUCT_TRY,
} from "../../constants";
import { subscribeFreePlan } from "../../Actions/transactionAction";

const ONE_DAY = 86400000;

const Products = ({
  item,
  icon,
  name,
  title,
  active,
  token,
  history,
  track,
  organization,
  user,
  signInactive = false, //flag to toggle "try it for free sign" link
}) => {
  const [loading, setloading] = useState(false);
  async function handleButtonClick() {
    const encodedToken = encodeURIComponent(token);
    const newLink = getLinkToService(name?.toLowerCase());
    const newLinkWithToken = `${newLink}?p=${encodedToken}`;

    if (active) {
      window.location.assign(newLinkWithToken);
    } else {
      setloading(true);
      let result = await subscribeFreePlan(item.id, organization?.id, user.id);
      if (result) {
        window.location.assign(newLinkWithToken);
      }
      track(PRODUCT_TRY, { title: `user click try ${name}`, product: name });
      setloading(false);
      // history.push("/subscription/plans");
    }
  }

  return (
    <div className="home-products" key={item}>
      <div className="products-avatar">
        {icon ? (
          <div className="product-icon">
            <img src={icon} height="40px" width="100%" alt={name} />
          </div>
        ) : (
          <Avatar
            height="40px"
            width="40px"
            user={name}
            style={{ marginRight: "12px" }}
          />
        )}
        <div>
          <p>
            <b>{name ? name : "Sample"}</b>
          </p>
        </div>
      </div>
      <div>
        <p style={{ margin: 0 }}>
          {title
            ? title
            : "Edit and share photos on-the-go with collage and auto-adjustment tools."}
        </p>
      </div>
      <div className="product-actions">
        {active ? (
          <Button varient="large" color="primary" onClick={handleButtonClick}>
            Launch
          </Button>
        ) : (
          <Button
            varient="large"
            color="transparent"
            style={{ marginRight: "8px" }}
            disabled={loading}
            onClick={handleButtonClick}
          >
            Try for free
          </Button>
        )}
      </div>
    </div>
  );
};

const SubscribedProduct = ({
  Icon,
  name,
  token,
  track,
  subscription,
  history,
}) => {
  let dayLeft = new Date(subscription?.endDate).getTime() - Date.now();

  function handleButtonClick() {
    if (dayLeft < ONE_DAY) {
      return history.push(`/billing?service=${name?.toLowerCase()}`);
    }

    const encodedToken = encodeURIComponent(token);
    const newLink = getLinkToService(name?.toLowerCase());
    const newLinkWithToken = `${newLink}?p=${encodedToken}`;
    track(APP_REDIRECT, { title: `redirecting to ${name}`, product: name });
    window.location.assign(newLinkWithToken);
  }

  return (
    <div className="sub-cards" onClick={handleButtonClick}>
      {dayLeft < ONE_DAY && (
        <div className="sub-expire">
          <ErrorIcon />
        </div>
      )}
      <ProfileWithName
        title={name}
        image={Icon}
        imgHeight="70px"
        imgWidth="70px"
        fontSize="30"
      />
    </div>
  );
};

const Home = ({ track, organization, ...props }) => {
  const { user, token, subscription, currentRole } = props;
  const history = useHistory();

  useEffect(() => {
    track(PAGE_VIEW);
    // !user && UpdateUserData();
  }, [subscription]);

  return (
    <div className="home">
      <div className="home-intro">
        <div className="user-info">
          <Avatar
            user={user?.first_name ? user.first_name : user?.email_id}
            icon={user && user.picture && user.picture}
            height={100}
            width={100}
            fontSize={"2rem"}
          />
        </div>
        <h2>{user && `Welcome, ${user.first_name + " " + user.last_name}`}</h2>
        <p>
          Manage your organization, security and subscription from one place.
        </p>
      </div>
      {currentRole === OWNER && (
        <div className="home-content">
          <Widget
            height={225}
            width={410}
            gutters="right"
            style={{ padding: "0" }}
            actions={<div className="actions">Manage your account</div>}
          >
            <WidgetContent>
              <div className="text-content">
                <h2>Profile</h2>
                <p>
                  Manage your organization member, your profile details at one
                  place. for your apps
                </p>
              </div>
              <div className="image">
                <img src={securityBanner} alt="Security" />
              </div>
            </WidgetContent>
            <WidgetBottom color="#ffffff">
              <div className="actions">
                <Link
                  to="/profile"
                  className="links"
                  onClick={() => track(CLICK, { title: "go to profile" })}
                >
                  Manage profile settings
                </Link>
              </div>
            </WidgetBottom>
          </Widget>
          <Widget height={225} width={410} style={{ padding: "0" }}>
            <WidgetContent>
              <div className="text-content">
                <h2>Billing & Subscription</h2>
                <p>
                  Manage all your products and their subscriptions from one
                  place.
                </p>
              </div>
              <div className="image">
                <img src={paymentsBanner} alt="Security" />
              </div>
            </WidgetContent>
            <WidgetBottom color="#ffffff">
              <Link
                to="/subscription/plans"
                onClick={() => track(CLICK, { title: "go to subscription" })}
                className="links"
              >
                <div className="actions">Manage your subscriptions</div>
              </Link>
            </WidgetBottom>
          </Widget>
        </div>
      )}
      <div className="home-content">
        <Widget height={243} width={840} style={{ padding: "0" }}>
          <WidgetContent style={{ padding: "12px" }}>
            <div className="comp-content-big">
              <h2>My Apps</h2>
              <p>
                All Apps you have access to are listed here. Click to open app.
              </p>
              <div className="accounts">
                {productMapping ? (
                  productMapping.map((item) => {
                    if (!subscription) return null;
                    let foundSub = subscription.filter(
                      (sub) => sub.productId === item.id
                    );
                    if (foundSub && foundSub.length > 0)
                      return (
                        <SubscribedProduct
                          key={item.id}
                          {...item}
                          subscription={foundSub[0]}
                          token={token}
                          track={track}
                          history={history}
                        />
                      );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </WidgetContent>
        </Widget>
      </div>
      <div className="home-content">
        <Widget height={"auto"} width={840} style={{ padding: "0" }}>
          <WidgetTitle>
            <div className="text-content-big">
              <h2>Explore Our Products</h2>
              <p>
                Please checkout our other product. Click to start your free
                trial.
              </p>
            </div>
          </WidgetTitle>
          <WidgetContent
            alignItems={"center"}
            style={{ flexDirection: "column" }}
          >
            {productMapping && subscription ? (
              productMapping.map((item) => {
                let foundSub = subscription?.filter(
                  (sub) => sub.productId === item.id
                );
                if (foundSub && foundSub.length > 0) return null;
                return (
                  <Products
                    item={item}
                    icon={item.Icon}
                    name={item.name}
                    title={item.description}
                    active={false}
                    token={token}
                    history={history}
                    track={track}
                    organization={organization}
                    user={user}
                  />
                );
              })
            ) : (
              <div></div>
            )}
          </WidgetContent>
        </Widget>
      </div>
    </div>
  );
};

const mapStateToProps = ({ UserReducer, transactionReducer, RoleReducer }) => ({
  currentRole: RoleReducer.currentRole,
  user: UserReducer.user,
  token: UserReducer.token,
  subscription: transactionReducer.subscription,
  subscriptionLoading: transactionReducer.subscriptionLoading,
  organization: UserReducer.organization,
});

export default connect(mapStateToProps)(Home);
