import React from "react";
import ResourceCenterCard from "../ResourceCenterCard/ResourceCenterCard";
import "./ResourceCenterContainer.scss";

/**
 * Resource Center Container Used In HelpSideNavbar Component
 *
 * Mapping Resources
 *
 */

const ResourceCenterContainer = () => {
  return (
    <div className="resource-center-container">
      <p>Resource Center</p>
      {resources.map((resource, index) => (
        <ResourceCenterCard key={index} {...resource} />
      ))}
    </div>
  );
};

export default ResourceCenterContainer;

// Sample Data
const resources = [
  {
    name: "Getting Started",
    description: "How to start using Teamwork",
  },
  {
    name: "Getting Started",
    description: "How to start using Teamwork",
  },
  {
    name: "Getting Started",
    description: "How to start using Teamwork",
  },
];
