import React from "react";

import "./Chip.scss";

const Chip = ({
  label,
  image,
  placement = "left",
  variant,
  color = "primary",
  size = "small",
  ...props
}) => {
  return (
    <div
      className={`chip ${variant === "round" && "chip-round"} 
      ${color && "chip-" + color} 
      ${size && !variant && size}`}
    >
      {placement === "left" && image && (
        <div className={`chip-image ${variant === "round" && "img-round"}`}>
          <img
            src={image}
            height="100%"
            width="100%"
            style={{ objectFit: "contain" }}
          />
        </div>
      )}
      <div className="chip-label">{label}</div>
      {placement === "right" && image && (
        <div
          className={`chip-image right ${variant === "round" && "img-round"}`}
        >
          <img
            src={image}
            height="100%"
            width="100%"
            style={{ objectFit: "contain" }}
          />
        </div>
      )}
    </div>
  );
};

export default Chip;
