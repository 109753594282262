import React, { useEffect, useState } from "react";

import Input from "../../../Awesome-ui/Input/Input";
import Button from "../../../Awesome-ui/Button/Button";

import { useHistory } from "react-router-dom";
import {
  forgetPassReset,
  redirectToPage,
  redirectToService,
} from "../../../Actions/UserActions";
import CircleCheckBox from "../../../Awesome-ui/CircleCheckBox/CircleCheckBox";
import Template from "../components/Template";
import { servicesMapping } from "../../../Data/productsMapping";

const RestPassword = (props) => {
  const history = useHistory();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(null);
  const [token, setToken] = useState(null);
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const [validation, setValidation] = React.useState([
    {
      id: "8CHAR",
      label: "At least 8 characters long",
      isValid: false,
    },
    {
      id: "1UC",
      label: "One UPPERCASE character",
      isValid: false,
    },
    {
      id: "1LC",
      label: "One lowercase character",
      isValid: false,
    },
    {
      id: "1SC",
      label: "One Special Character",
      isValid: false,
    },
  ]);

  useEffect(() => {
    function checkForUrl() {
      const search = props.location.search;
      let parsedUrl;
      if (search) parsedUrl = new URLSearchParams(search);

      if (!parsedUrl) return;

      if (parsedUrl.get("service")) {
        if (servicesMapping[parsedUrl.get("service")]) {
          setService(parsedUrl.get("service"));
        } else setService(null);
      }

      if (parsedUrl.get("p")) {
        setToken(parsedUrl.get("p"));
      } else setToken(null);

      setLoading(false);
    }
    checkForUrl();
  }, []);

  async function submitHandler() {
    let isValid = true;
    validation.forEach((item) => {
      if (item.isValid === false) isValid = false;
    });
    if (
      newPass !== "" &&
      confirmPass !== "" &&
      newPass === confirmPass &&
      isValid
      ) {
      setLoading(true)
      await forgetPassReset(newPass, confirmPass, token);
      setLoading(false);
      if (service) redirectToService(service, token);
      else redirectToPage(token, history);
      history.push("/login");
    }
  }

  function inputHandler(value) {
    setNewPass(value);
    setValidation((valid) => {
      return valid.map((item) => {
        if (item.id === "8CHAR") item.isValid = value && value.length >= 8;
        if (item.id === "1UC") item.isValid = value && /[A-Z]{1}/.test(value);
        if (item.id === "1LC") item.isValid = value && /[a-z]/.test(value);
        if (item.id === "1SC")
          item.isValid = value && /[!@#$%^&*)(+=._-]/.test(value);

        return item;
      });
    });
  }
  return (
    <Template>
      <div>
        <Input
          style={{ width: "460px", marginBottom: "1.2rem" }}
          id="newPass"
          value={newPass}
          type="password"
          label="Set Password"
          changeHandler={inputHandler}
        />
      </div>
      <div>
        <Input
          style={{ width: "460px", marginBottom: "1.2rem" }}
          id="confirmPass"
          value={confirmPass}
          type="password"
          label="Confirm Password"
          changeHandler={(val) => {
            setConfirmPass(val);
          }}
        />
      </div>
      <div>
        {validation.map((item) => {
          return (
            <div key={item.id} className="flex-it validation">
              <CircleCheckBox
                select={item.isValid}
                style={{ marginRight: "1rem" }}
              />
              <span>{item.label}</span>
            </div>
          );
        })}
      </div>
      <Button
        color="primary"
        varient="large"
        disabled={
          newPass === "" || confirmPass === "" || newPass !== confirmPass ||loading
        }
        onClick={submitHandler}
        round
        style={{
          width: "100%",
          height: "45px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1.2rem",
        }}
      >
        <span>Continue</span>
      </Button>
    </Template>
  );
};

export default RestPassword;
