import React, { useState, useEffect } from "react";
import { ReactComponent as CancelIcon } from "../../assets/utilIcons/cross.svg";
import { getLinkToService, servicesMapping } from "../../Data/productsMapping";
import Chip from "../../Awesome-ui/Chip/Chip";
import { Text } from "@awesomesuite-frontend/awesome-nebula";
import { getBestPrice } from "../../Actions/transactionAction";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete_two.svg";
import BillingPageRight from "./Components/BillingPageRight";

import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-outline.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-outline.svg";

import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid";

function getChipColor(status) {
  if (status) return "primary";
  else return "danger";
}

function getMultiplication(days) {
  if (!days) return 0;
  return (days / 30).toFixed(0);
}

const BillingComponent = ({
  classes,
  token,
  selectNewPlan,
  service,
  updateCurrentPlan,
  orgId,
  currentPlan,
  subscription,
  nextPlanDetails,
  serviceId,
  extraResource,
  costCalculated,
  credit,
  changePlanLoading,
}) => {
  const [plan, setplan] = useState(1);
  const [addons, setaddons] = useState({});
  const [plans, setPlans] = useState(null);
  const [code, setcode] = useState("");
  const [couponAdded, setCouponAdded] = useState(false);
  const [applyCredit, setapplyCredit] = useState(false);
  const [bestPrice, setbestPrice] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      if (!currentPlan?.options) return;
      setPlans(currentPlan.options);
      setplan(currentPlan.id);
      if (currentPlan.isActive) setQuantity(0);
      if (currentPlan.addOns) {
        let addonMap = {};
        currentPlan.addOns.forEach(
          (item) => (addonMap[item.externalId] = { ...item, count: 0 })
        );
        setaddons(addonMap);
      }

      let bestPrice = await getBestPriceForPlan();
      setbestPrice(bestPrice);
    }
    init();
  }, [currentPlan]);

  async function addonHandler(type, count) {
    if (loading) return;
    const newAdds = { ...addons };
    if (newAdds[type].count + count >= 0) newAdds[type].count += count;
    setaddons(newAdds);
    let addonsPlans = Object.values(newAdds)
      .map(({ count, externalId }) => ({
        addOnPlanId: externalId,
        quantityAddon: count,
      }))
      .filter((i) => i.quantityAddon);

    setLoading(true);
    let bestPrice = await getBestPriceForPlan(null, null, null, addonsPlans);
    setbestPrice(bestPrice);
    setLoading(false);
  }

  async function proceedToPay() {
    let priceData = await getBestPriceForPlan();
    const checkouturl = getLinkToService("checkout");
    const amount = priceData.bestPriceAmount;
    let addonsList = Object.values(addons)
      .filter((i) => i.count)
      .map(({ count, externalId }) => `${externalId}-${count}`)
      .join(",");
    let url = `${checkouturl}/?app=${service}&plan=${currentPlan.chargebeePlanId}`;
    url += `&price=${amount}&token=${token}&planId=${currentPlan.id}`;
    url += `&redirect=${window.location.origin}&credit=${applyCredit}`;
    url += `&quantity=${quantity}`;
    url += `&addons=${addonsList}`;

    if (code && code.length > 0) {
      url += `&coupon=${code}`;
    }
    if (orgId) {
      url += `&orgId=${orgId}`;
    }
    window.open(url);
  }

  async function handleCredit(credit) {
    setapplyCredit(credit);
    let bestPrice = await getBestPriceForPlan(credit);
    setbestPrice(bestPrice);
  }

  async function handleCoupon(enable) {
    let bestPrice = await getBestPriceForPlan(null, enable);
    if (!bestPrice) return;
    setCouponAdded(enable);
    setbestPrice(bestPrice);
  }

  async function getBestPriceForPlan(credit, enable, qt, addonsList) {
    let addonsPlans = Object.values(addons)
      .map(({ count, externalId }) => ({
        addOnPlanId: externalId,
        quantityAddon: count,
      }))
      .filter((i) => i.quantityAddon);
    let data = await getBestPrice({
      productId: serviceId,
      organisationId: orgId,
      planId: currentPlan.chargebeePlanId,
      creditApply: typeof credit === "boolean" ? credit : applyCredit,
      code: enable || couponAdded ? code : null,
      quantityPlan: qt || quantity,
      addonPlans: addonsList || addonsPlans,
    });

    if (data?.totalPlanAmount < 0) data.totalPlanAmount = 0;
    return data;
  }

  async function addQuantity() {
    if (loading || currentPlan.isActive) return;
    setLoading(true);
    let bestPrice = await getBestPriceForPlan(null, null, quantity + 1);
    setbestPrice(bestPrice);
    setQuantity(quantity + 1);
    setLoading(false);
  }

  async function removeQuantity() {
    if (loading || currentPlan.isActive) return;
    setLoading(true);
    let bestPrice = await getBestPriceForPlan(null, null, quantity - 1);
    setbestPrice(bestPrice);
    setQuantity(quantity - 1);
    setLoading(false);
  }

  if (!currentPlan) return null;

  return (
    <Grid container item xs={12} className={classes.page}>
      <Grid container item xs={11} margin="0 auto" maxWidth={1390}>
        <BillingPageRight
          nextPlanDetails={nextPlanDetails}
          extraResource={extraResource}
          plans={plans}
          plan={plan}
          updateCurrentPlan={updateCurrentPlan}
          setplan={setplan}
          selectNewPlan={selectNewPlan}
          subscription={subscription}
          currentPlan={currentPlan}
          token={token}
          service={service}
          costCalculated={costCalculated}
          changePlanLoading={changePlanLoading}
          quantity={quantity}
          addons={addons}
          addonHandler={addonHandler}
          isActive={currentPlan.isActive}
        />
        <Grid container item xs={4} padding={14} justify="flex-end">
          <Grid item xs={11}>
            <p>
              <b>Invoice Details</b>
            </p>
            <div className={classes.checkoutBox}>
              <div className={classes.displayFlexSpace}>
                <div
                  className={classes.displayFlex}
                  style={{ justifyContent: "flex-start" }}
                >
                  <img
                    src={servicesMapping[service].IconSmall}
                    width="40px"
                    style={{ marginRight: 8 }}
                  />
                  <h1>{servicesMapping[service].name}</h1>
                </div>
                <div>
                  <Chip
                    label={currentPlan.isActive ? "Current" : "New Plan"}
                    size="medium"
                    color={getChipColor(currentPlan.isActive)}
                  />
                </div>
              </div>
              <div
                className={`${classes.displayFlexSpace} ${classes.sectionMargin}`}
              >
                <span style={{ width: "70%" }}>
                  <h3 className={classes.noMargin}>{currentPlan.title}</h3>
                </span>
                <span>
                  {currentPlan.amount} {currentPlan.currency}
                </span>
              </div>
              {/* <div
                className={`${classes.displayFlexSpace} ${classes.sectionMargin}`}
              > */}
              {/* <h3 className={classes.noMargin}></h3>
                <div className={classes.quantity}>
                  <button
                    className={classes.quantityBtn}
                    onClick={removeQuantity}
                  >
                    <RemoveIcon height={20} />
                  </button>
                  <div className={classes.quantityNum}>{quantity}</div>
                  <button className={classes.quantityBtn} onClick={addQuantity}>
                    <AddIcon height={20} />
                  </button>
                </div> */}
              {/* </div> */}
              {!currentPlan.isActive && (
                <div>
                  <div>Effective today</div>
                  <div
                    className={`${classes.addOns} ${classes.lightTxt} ${classes.paddingWrap}`}
                  >
                    <div className={classes.displayFlexSpace}>
                      <Text varient="subText" marginTop="6px" color="light">
                        Brands
                      </Text>
                      <span>{quantity * currentPlan.noOfBrands}</span>
                    </div>
                    <div className={classes.displayFlexSpace}>
                      <Text varient="subText" marginTop="6px" color="light">
                        Users
                      </Text>
                      <span>{quantity * currentPlan.numberOfUsers}</span>
                    </div>
                  </div>
                </div>
              )}
              {!currentPlan.isActive && (
                <div className={classes.details}>
                  <div>Billing Details</div>
                  <div
                    className={`${classes.addOns} ${classes.lightTxt} ${classes.paddingWrap}`}
                  >
                    <div className={classes.displayFlexSpace}>
                      <Text varient="subText" marginTop="6px" color="light">
                        Plan Cost{" "}
                        {currentPlan.amount *
                          Math.ceil(currentPlan.days / 30.417) ===
                        bestPrice?.totalPlanAmount
                          ? `(${currentPlan.amount} X ${Math.ceil(
                              currentPlan.days / 30.417
                            )} months)`
                          : ""}
                      </Text>
                      <span>$ {bestPrice?.totalPlanAmount}</span>
                    </div>
                    {bestPrice?.applyCreditScore > 0 && (
                      <div className={classes.displayFlexSpace}>
                        <Text varient="subText" marginTop="6px" color="light">
                          Credits Availed
                        </Text>
                        <span>- $ {bestPrice?.applyCreditScore}</span>
                      </div>
                    )}
                    {bestPrice?.discount > 0 && (
                      <div className={classes.displayFlexSpace}>
                        <Text varient="subText" marginTop="6px" color="light">
                          Coupon Discount
                        </Text>
                        <span>- $ {bestPrice?.discount}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                {bestPrice?.addOnPlans.length > 0 && (
                  <h4 style={{ margin: 0 }}>Plan Add-ons</h4>
                )}
                <div className={classes.addOns}>
                  {bestPrice?.addOnPlans.map((item, key) => {
                    if (item.count <= 0) return;
                    let data = Object.values(addons).find(
                      (i) => i.externalId == item.addOnPlanId
                    );
                    if (!data) return;
                    return (
                      <AddOnCard
                        key={key}
                        classes={classes}
                        title={data?.title}
                        pricing={item.amount}
                        set={data?.priceText}
                        count={item.quantityAddon}
                        curr={data?.currency}
                        amount={item.quantityAddon * item.amount}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={`${classes.total}`}>
                <div
                  className={`${classes.displayFlexSpace}`}
                  style={{ width: "100%" }}
                >
                  <h3>Total Cost</h3>
                  <h3>
                    {bestPrice?.bestPriceAmount}{" "}
                    {costCalculated?.currency || currentPlan.currency}
                  </h3>
                </div>
              </div>
            </div>
            <Grid marginTop="1rem">
              {!currentPlan.isActive && couponAdded && (
                <CouponCodeSelected
                  classes={classes}
                  code={code}
                  setCode={setcode}
                  setCouponAdded={setCouponAdded}
                  bestPrice={bestPrice}
                  handleCoupon={handleCoupon}
                />
              )}
              {!currentPlan.isActive && !extraResource && !couponAdded && (
                <CouponCode
                  classes={classes}
                  code={code}
                  planId={currentPlan?.chargebeePlanId}
                  setCode={setcode}
                  handleCoupon={handleCoupon}
                />
              )}
            </Grid>
            {!currentPlan.isActive && (
              <CreditBox
                classes={classes}
                handleCredit={handleCredit}
                applyCredit={applyCredit}
                creditData={credit}
              />
            )}
            {(!currentPlan.isActive || bestPrice?.addOnPlans.length > 0) &&
              !extraResource && (
                <button
                  className={`${classes.payBtn} ${classes.displayFlex}`}
                  onClick={proceedToPay}
                >
                  <span>Checkout</span>
                </button>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingComponent;

const CreditBox = ({
  classes,
  creditData = { creditScore: 0 },
  handleCredit,
  applyCredit,
}) => {
  return (
    <div
      className={`${classes.planCard} 
        ${applyCredit ? classes.planCardSelected : ""}`}
      onClick={() => handleCredit(!applyCredit)}
      style={{ marginTop: "1rem" }}
    >
      <div className={`${classes.displayFlex} ${classes.planbtnRadio}`}>
        <input
          className={classes.checkBox}
          checked={applyCredit}
          type="radio"
        />
      </div>
      <div>
        <div>Use Prorated Credit</div>
        <Text varient="subText" margin="0" color="light" fontSize="12px">
          Adjusted in next billing cycle
        </Text>
      </div>
      <Text varient="h6" margin="0" marginLeft="auto" color="#2bc3a4">
        $ {creditData?.creditScore || "0"}
      </Text>
    </div>
  );
};

const AddOnCard = ({ classes, title, set, priceText, count, curr, amount }) => {
  return (
    <div className={classes.addonWrapper}>
      <div className={classes.addonCard}>
        <div>
          <div className={classes.addOnCardTitle}>
            {title} : &nbsp; {count}
          </div>
          <div className={classes.lightTxt}>{set}</div>
        </div>
        <div>
          {curr === "USD" ? "$" : curr} &nbsp;
          {amount}
        </div>
      </div>
    </div>
  );
};

const CouponCode = ({ classes, setCode, code, handleCoupon }) => {
  return (
    <div className={`${classes.displayFlex} ${classes.coupon}`}>
      <input
        placeholder="Apply Coupon"
        type="text"
        className={classes.couponInput}
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <button
        className={classes.addCouponBtn}
        onClick={() => code && handleCoupon(true)}
      >
        ADD
      </button>
    </div>
  );
};

const CouponCodeSelected = ({
  classes,
  code,
  setCode,
  bestPrice,
  handleCoupon,
}) => {
  return (
    <React.Fragment>
      <div className={`${classes.displayFlex} ${classes.saveBill}`}>
        <div>🎉 Saved on this bill</div>
        <div>
          {bestPrice?.discount} {bestPrice?.currency}
        </div>
      </div>
      <div className={`${classes.displayFlex} ${classes.couponSelect}`}>
        <div>
          <Text varient="h5" margin="0">
            {code}
          </Text>
          <Text varient="subText" fontSize={10} margin="0">
            Coupon Applied
          </Text>
        </div>
        <button
          className={classes.removeCouponBtn}
          onClick={() => {
            handleCoupon(false);
            setCode("");
          }}
        >
          <DeleteIcon />
        </button>
      </div>
    </React.Fragment>
  );
};