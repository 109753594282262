/**
 * This is the TabPanel component wrapper for the children's of Tabs
 * 
 * @param {index} its index (no two index can be same)
 * 
 * @param {label} label for the Tab Menue
 *  
 * @param {children} whatever content you want to insert 
 * 
 */
const TabPanel = ({index, label, children}) => {
    return children;
}

export default TabPanel;
