import React from "react";
import "./TableBottom.scss";
import TableCell from "../TableCell/TableCell";
import TableRow from "../TableRow/TableRow";

const TableBottom = ({
  activePage,
  totalPages,
  pageChangeHandler,
  ...props
}) => {
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    if (totalPages > 0) {
      const list = [];
      for (let i = 1; i <= totalPages / 10; i++) list.push(i);
      setPages(list);
    }
  }, [totalPages]);

  return (
    <TableRow>
      <TableCell>
        <div className="aui-page-num-list">
        {
            activePage > 1 &&
            <div
            className={`aui-page-num aui-page-next`}
            onClick={() => pageChangeHandler(activePage - 1)}
          >
            {`<`}Back
          </div>}
          {pages
            .slice(activePage > 1 && activePage - 2, activePage + 1)
            .map((item, index) => {
              return (
                <div
                  className={`${
                    activePage === item && "aui-page-active"
                  } aui-page-num`}
                  onClick={() => {
                    pageChangeHandler(item);
                  }}
                  key={index}
                >
                  <div>{item}</div>
                </div>
              );
            })}
          {
            totalPages / 10 >= activePage &&
            <div
            className={`aui-page-num aui-page-next`}
            onClick={() => pageChangeHandler(activePage + 1)}
          >
            Next{`>`}
          </div>}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableBottom;
