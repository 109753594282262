import React from "react";
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import { createUseStyles } from "react-jss";
import tick from "../../assets/pricing/tick.svg";
import SignPrice1 from "../../assets/pricing/sign/price1.svg";
import SignPrice2 from "../../assets/pricing/sign/price2.svg";

const colorsMapping = {
  A: { color: "#FFB404" },
  B: { color: "#F2547D" },
  C: { color: "#635AC7" },
  D: { color: "#FFB404" },
  E: { color: "#F2547D" },
  F: { color: "#635AC7" },
  G: { color: "#FFB404" },
  H: { color: "#F2547D" },
  I: { color: "#FFB404" },
  J: { color: "#F2547D" },
  K: { color: "#635AC7" },
  L: { color: "#FFB404" },
  M: { color: "#FFB404" },
  N: { color: "#F2547D" },
  O: { color: "#FFB404" },
  P: { color: "#635AC7" },
  Q: { color: "#F2547D" },
  R: { color: "#F2547D" },
  S: { color: "#635AC7" },
  T: { color: "#FFB404" },
  U: { color: "#FFB404" },
  V: { color: "#635AC7" },
  W: { color: "#F2547D" },
  X: { color: "#F2547D" },
  Y: { color: "#F2547D" },
  Z: { color: "#FFB404" },
};

const useStyles = createUseStyles((theme) => ({
  priceCard: {
    maxWidth: 390,
    border: "1px solid #DFE3EB",
    borderRadius: 4,
    borderTop: (props) => `10px solid ${props.color}`,
    "&:hover": {
      border: (props) => `1px solid ${props.color}`,
      borderTop: (props) => `10px solid ${props.color}`,
    },
  },
  popular: {
    width: "fit-content",
    padding: "6px 12px",
    borderRadius: 18,
    background: "#D5C8FB",
    color: "#754DE4",
    fontSize: "12px",
    marginLeft: "auto",
  },
  tick: {
    margin: "auto 0",
    marginRight: 12,
  },
  priceImg: {
    marginRight: 12,
  },
  btn: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    width: "100%",
    padding: "14px",
    border: "2px solid #2D3E50",
    fontSize: 16,
    background: "transparent",
    margin: "12px 0",
    cursor: "pointer",
    "&:hover": {
      "& span": {
        color: "#ffff !important",
      },
      border: (props) => `2px solid ${props.color}`,
      background: (props) => props.color,
    },
  },
  higilight: {
    transform: "translateY(-16px)",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    border: (props) => `1px solid ${props.color}`,
    borderTop: (props) => `8px solid ${props.color}`,
  },
}));

let iconMap = {
  Free: null,
  Professionals: SignPrice1,
  Business: SignPrice2,
};

const SignPriceCard = ({ data, amountText, isActive, onClick, xs, sm, md }) => {
  const { title, amount, icon, featureTitle, features, downgrade } = data;
  const { description, isRecommend, calText } = data;
  let color = colorsMapping[title?.slice(0, 1)?.toUpperCase()]?.color;
  const classes = useStyles({ color: color });

  return (
    <Grid
      container
      item
      xs={xs}
      sm={sm}
      md={md}
      padding={"12px 28px"}
      margin="0 4px"
      className={`${classes.priceCard}  ${
        isRecommend ? classes.higilight : ""
      }`}
    >
      <Grid container item xs={12} align="center">
        {(icon || iconMap[title]) && (
          <img
            className={classes.priceImg}
            src={icon || iconMap[title]}
            alt={title}
          />
        ) }
        <Text varient="h4" color={color} margin="1.5rem 0 1rem 0">
          {title}
        </Text>
      </Grid>
      <Grid height={104}>
        <Text varient="subText" color="light" marginBottom="1.5rem">
          {description}
        </Text>
      </Grid>

      <Grid item xs={6}>
        <Text varient="h2" margin="0">
          $ {amount}
        </Text>
      </Grid>
      <Grid item xs={6}>
        {isRecommend && <div className={classes.popular}>Most popular</div>}
      </Grid>
      <Grid item xs={12} margin="12px 0">
        <Text varient="body" color="light" margin="0">
          {amountText || calText}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <button
          className={`${classes.btn} ${isActive ? classes.btnSelected : ""}`}
          onClick={onClick}
        >
          {isActive ? (
            <span>Current</span>
          ) : downgrade ? (
            <span>Downgrade</span>
          ) : (
            <span>Upgrade</span>
          )}
        </button>
      </Grid>
      <Grid item xs={12} marginTop="1.8rem">
        <Text varient="body" margin="0" marginBottom={18}>
          <b>{featureTitle}</b>
        </Text>
        {features?.map((item, key) => (
          <Grid container align="center" key={key} margin="16px 0">
            {typeof item === "string" && (
              <img className={classes.tick} src={tick} alt="tick" />
            )}
            <Text varient="body" margin="0" fontSize={15} color="light">
              {item}
            </Text>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} margin={"16px 0"}>
        <Text margin="0" align="center">
          or see all features
        </Text>
      </Grid>
    </Grid>
  );
};

export default SignPriceCard;
