import React, { useState } from "react";
import Avatar from "../../../Awesome-ui/Avatar/Avatar";
import HelpSideNavBar from "../../../Awesome-ui/HelpSideNavbar";
import { ReactComponent as Help } from "../../../assets/NavigationIcon/help.svg";
import UserSideBar from "../../../Awesome-ui/UserSideBar";
import { CLICK } from "../../../constants";
/**
 * For the user avatar preview and dorp down in the for the other user
 * related details or links like  go to accounts app / refer and log out etc.
 *
 */
const User = ({
  userDetails,
  showUserDropDown,
  setShowUserDropDown,
  logoutHandler,
  track,
}) => {
  const [showHelp, setShowHelp] = useState(false);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <Help
        style={{
          width: "30px",
          height: "30px",
          marginBottom: "-2px",
          marginRight: "1rem",
          cursor: "pointer",
        }}
        onClick={() => setShowHelp(true)}
      /> */}
      <Avatar
        user={userDetails?.first_name ? userDetails.first_name : userDetails?.email_id}
        onClick={() => {
          track(CLICK, { title: "user side nav.", });
          setShowUserDropDown((prev) => !prev);
        }}
        icon={userDetails && userDetails.picture}
        fontSize="0.9rem"
      />
      <div>
        {/* <UserDropDown
          name={
            userDetails && `${userDetails.first_name} ${userDetails.last_name}`
          }
          logoutHandler={logoutHandler}
          userImage={userDetails && userDetails.picture}
          email={userDetails && userDetails.email_id}
          showUserDropDown={showUserDropDown}
          closeUserDropDown={setShowUserDropDown}
        /> */}
        <UserSideBar
          // sideBarType={sideBarType}
          // setSideBarType={setSideBarType}
          // sideBarColor={sideBarColor}
          // setSideBarColor={setSideBarColor}
          signOut={logoutHandler}
          showUserDropDown={showUserDropDown}
          setShowUserDropDown={setShowUserDropDown}
          userDetails={userDetails}
        />
      </div>

      <HelpSideNavBar helpOpen={showHelp} closeSideBar={setShowHelp} />
    </div>
  );
};

export default User;
