import React from "react";
import "./Avatar.scss";

const colorsMapping = {
  A: { back: "#fedaa3", text: "#ffa51f" },
  B: { back: "#ffc0ce", text: "#fb5779" },
  C: { back: "#ffecc7", text: "#ffa800" },
  D: { back: "#b4ffdb", text: "#19db7e" },
  E: { back: "#a9fffa", text: "#00d4c8" },
  F: { back: "#ecd3ff", text: "#9f46e4" },
  G: { back: "#d4d1ff", text: "#6457f9" },
  H: { back: "#d2e8ff", text: "#5a7896" },
  I: { back: "#fedaa3", text: "#ffa51f" },
  J: { back: "#ffc0ce", text: "#fb5779" },
  K: { back: "#ffecc7", text: "#ffa800" },
  L: { back: "#b4ffdb", text: "#19db7e" },
  M: { back: "#b4ffdb", text: "#19db7e" },
  N: { back: "#a9fffa", text: "#00d4c8" },
  O: { back: "#d4d1ff", text: "#6457f9" },
  P: { back: "#ecd3ff", text: "#9f46e4" },
  Q: { back: "#d2e8ff", text: "#5a7896" },
  R: { back: "#ffc0ce", text: "#fb5779" },
  S: { back: "#ffecc7", text: "#ffa800" },
  T: { back: "#d4d1ff", text: "#6457f9" },
  U: { back: "#d4d1ff", text: "#6457f9" },
  V: { back: "#ecd3ff", text: "#9f46e4" },
  W: { back: "#ffc0ce", text: "#fb5779" },
  X: { back: "#d2e8ff", text: "#5a7896" },
  Y: { back: "#a9fffa", text: "#00d4c8" },
  Z: { back: "#fedaa3", text: "#ffa51f" },
};

/**
 * Create a user avater by using this avater Component
 * it will take img src or name to show on avatar
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} icon
 * @param {number} height
 * @param {number} width
 * @param {number} fontSize
 *
 */
const Avatar = ({
  user,
  width,
  height,
  fontSize,
  icon,
  rightGutter,
  style,
  ...props
}) => {
  const name = user && user.toUpperCase().slice(0, 1);
  return (
    <div className="user" {...props}>
      <div
        className="avatar"
        style={{
          width: width,
          height: height,
          fontSize: fontSize,
          backgroundColor: name && colorsMapping[name] && colorsMapping[name].back,
          color: name && colorsMapping[name] && colorsMapping[name].text,
          marginRight: rightGutter ? "5px" : "0px",
          ...style,
        }}
      >
        {user && !icon && name}
        {icon && <img src={icon} alt={user} height="100%" width="100%" />}
      </div>
      {/* <p>{userName}</p> */}
    </div>
  );
};

export default Avatar;
