import React, { useEffect } from "react";
import "./SessionLogs.scss";

import Widget from "../../Awesome-ui/widget/Widget";
import WidgetTitle from "../../Awesome-ui/WidgetTitle/WidgetTitle";
import WidgetContent from "../../Awesome-ui/WidgetContent/WidgetContent";
import Table from "../../Awesome-ui/Table/Table";
import TableRow from "../../Awesome-ui/TableRow/TableRow";
import TableCell from "../../Awesome-ui/TableCell/TableCell";
import TableHeader from "../../Awesome-ui/TableHeader/TableHeader";
import TableBottom from "../../Awesome-ui/TableBottom";
import Chip from "../../Awesome-ui/Chip/Chip";

import activityIcon from "../../assets/bannerSvg/activity-log.svg";
import { connect } from "react-redux";
import Loader from "../../Awesome-ui/Loader/Loader";
import { loadSession } from "../../Actions/UserActions";
import { PAGE_VIEW } from "../../constants";

function getColor(status) {
  if (status === 1) return "primary";
  if (status === 0) return "danger";
}
function getLabel(status) {
  if (status === 1) return "User Active";
  if (status === 0) return "Session end";
}

function getAgent(agent) {
  const seprator = agent.indexOf("/");
  return agent.slice(0, seprator);
}

const SessionLogs = ({ track, ...props }) => {
  const { token, session, activeSession } = props;
  const [loading, setLoading] = React.useState(true);
  const [paginationKey, setPaginationKey] = React.useState(1);

  useEffect(() => track(PAGE_VIEW), []);

  useEffect(() => {
    async function load() {
      await loadSession();
      setLoading(false);
    }
    if (session.length < 1) {
      setLoading(true);
      load();
    } else setLoading(false);

    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="sessions">
      <div className="sessions-main">
        <div className="sessions-content">
          <Widget width={"80%"} height={"auto"} style={{ padding: "0" }}>
            <WidgetTitle
              style={{
                border: "none",
                padding: "14px 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="text-content-big">
                <h2>Active sessions</h2>
                <p>
                  All the accounts in this organization that you have access to.
                  Click to open
                </p>
              </div>
              <div className="image">
                <img src={activityIcon} alt="activity" />
              </div>
            </WidgetTitle>
            <WidgetContent
              alignItems={"center"}
              style={{ flexDirection: "column" }}
            >
              <Table>
                <TableRow>
                  <TableHeader align="left">Device</TableHeader>
                  <TableHeader align="left">Email-Id</TableHeader>
                  <TableHeader align="left">Date</TableHeader>
                  <TableHeader align="left">Ip Address</TableHeader>
                  <TableHeader align="center">Session</TableHeader>
                </TableRow>
                {activeSession &&
                  activeSession.map((req) => (
                    <TableRow key={req.id}>
                      <TableCell>
                        {getAgent(req.RequestAgent.requestAgent)}
                      </TableCell>
                      <TableCell>
                        {req.emailId &&
                          (req.emailId.length > 0 ? req.emailId : "---")}
                      </TableCell>
                      <TableCell align="left">
                        {new Date(req.updatedOn).toDateString()}
                      </TableCell>
                      <TableCell align="left">{req.requestIp}</TableCell>
                      <TableCell>
                        <Chip
                          label={getLabel(req.active)}
                          color={getColor(req.active)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {(!activeSession || activeSession.length < 1) && !loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p>No Sessions Yet</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </WidgetContent>
          </Widget>
        </div>
        <div className="sessions-content">
          <Widget width={"80%"} height={"auto"} style={{ padding: "0" }}>
            <WidgetTitle style={{ border: "none", padding: "14px 16px" }}>
              <div className="text-content-big">
                <h2>Activity History</h2>
                <p>
                  All the accounts in this organisation that you have access to.
                  Click to open
                </p>
              </div>
            </WidgetTitle>
            <WidgetContent
              alignItems={"center"}
              style={{ flexDirection: "column" }}
            >
              <Table>
                <TableRow>
                  <TableHeader align="left">Performed by</TableHeader>
                  <TableHeader align="left">Action</TableHeader>
                  <TableHeader align="left">Additional Information</TableHeader>
                  <TableHeader align="center">Date/Time</TableHeader>
                  <TableHeader align="center">IP Address</TableHeader>
                </TableRow>
                {session &&
                  session.length > 1 &&
                  session
                    .slice(
                      session.length > 10 ? paginationKey * 10 : 0,
                      session.length > 10 ? paginationKey * 10 + 10 : 10
                    )
                    .map((req) => (
                      <TableRow key={req.id}>
                        <TableCell>
                          {getAgent(req.RequestAgent.requestAgent)}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={getLabel(req.active)}
                            color={getColor(req.active)}
                          />
                        </TableCell>
                        <TableCell>
                          {req.emailId && req.emailId.length !== 0
                            ? req.emailId
                            : "---"}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(req.updatedOn).toDateString()}
                        </TableCell>
                        <TableCell align="center">{req.requestIp}</TableCell>
                      </TableRow>
                    ))}
                <TableBottom
                  activePage={paginationKey}
                  totalPages={session.length}
                  pageChangeHandler={(page) => {
                    setPaginationKey(page);
                  }}
                />
                {(!session || session.length < 1) && !loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p>No Sessions Yet</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </WidgetContent>
          </Widget>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.UserReducer.token,
  session: state.UserReducer.session,
  activeSession: state.UserReducer.activeSession,
});

export default connect(mapStateToProps)(SessionLogs);
