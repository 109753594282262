import React from "react";
import { Link } from "react-router-dom";
import InputBox from "../../Awesome-ui/InputBox";
import Loader from "../../Awesome-ui/Loader/Loader";

import EmailIcons from "../../assets/LoginAndSignUpIcon/email.svg";
import PasswordIcon from "../../assets/LoginAndSignUpIcon/password.svg";
import { ReactComponent as GoogleIcon } from "../../assets/LoginIcon/google.svg";
import { signWithGoogle } from "../../configuration/firebase";
import { DANGER } from "../../ActionType/MessageActionTypes";
import { createMessage } from "../../Actions/MessageAction";

function UniverselLogin({
  loading,
  service,
  submitApiHandler,
  btnColor,
  oAuthSignIn,
  logo,
  redirectQuery,
}) {
  const [email, setEmail] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [curActive, setcurActive] = React.useState(0);

  function changeActive(val) {
    setcurActive(val);
  }
  function changeEmail(val) {
    setEmail(val);
  }
  function changePassword(val) {
    setpassword(val);
  }

  async function submitHandler() {
    if (!email.trim().length)
      return createMessage(DANGER, "Please enter your email.");
    if (!password.trim().length)
      return createMessage(DANGER, "Please enter your password.");
    await submitApiHandler(email, password);
  }

  return (
    <div className="form">
      {logo ? <img src={logo} alt="srvice" /> : <div style={{ height: 50 }} />}
      <h2 style={{ textAlign: "center", margin: 0 }}>👋 Welcome back</h2>
      <p style={{ textAlign: "center", fontSize: 12, margin: 0 }}>
        Good to see you again!
      </p>

      <form
        className="formSpace"
        onKeyDown={(e) => {
          if (e.key === "Enter") submitHandler();
        }}
      >
        <InputBox
          placeHolder="Email"
          complete={"off"}
          type="text"
          name="email"
          value={email}
          req={true}
          src={EmailIcons}
          changeFunc={(val) => changeEmail(val)}
          ind={0}
          onFocus={() => changeActive(0)}
          onBlur={() => changeActive(null)}
          focus={curActive}
        />
        <InputBox
          placeHolder="Password"
          complete={"off"}
          type="password"
          name="password"
          value={password}
          req={true}
          src={PasswordIcon}
          changeFunc={(val) => changePassword(val)}
          ind={1}
          onFocus={() => changeActive(1)}
          onBlur={() => changeActive(null)}
          focus={curActive}
        />
        <p>
          <Link to={`/forgotpass${window.location.search}`}>Forgot your password?</Link>
        </p>
        <div
          className="button"
          tabIndex="0"
          onClick={submitHandler}
          style={{ backgroundColor: btnColor }}
        >
          SIGN IN
          {loading && (
            <span>
              {"  "}
              <Loader
                height="14px"
                width="14px"
                border="2px solid #fff"
                borderTop="2px solid #d3d3d3"
              />
            </span>
          )}
        </div>
      </form>

      <div onClick={() => signWithGoogle(oAuthSignIn)}>
        <div
          tabindex="0"
          role="button"
          aria-labelledby="button-label"
          className="google-btn"
        >
          <div className="google-btn-sps"></div>
          <div className="google-btn-spa">
            <div className="google-btn-spb">
              <div className="google-btn-img">
                <GoogleIcon />
              </div>
            </div>
            <span className="google-btn-txt">Sign in with Google</span>
            <span style={{ display: "none" }} id="button-label">
              Sign in with Google
            </span>
          </div>
        </div>
      </div>

      <div className="noAcc">
        Don't have an Account?{" "}
        <Link
          to={
            service ? `/signup?service=${service}&${redirectQuery}` : "/signup"
          }
        >
          Sign Up Here
        </Link>
      </div>
    </div>
  );
}

export default UniverselLogin;
