import React from 'react';
import "./Loader.scss";

const Loader = (props) => {
    return (
        <div className="loader" style={{...props}}>
        </div>
    )
}

export default Loader;
