import React, { useEffect, useState } from "react";

import "./Message.scss";
import { clearMessage } from "../../Actions/MessageAction";
import { connect } from "react-redux";

const Message = ({ message, type }) => {
  const [vis, toggle] = useState(true);

  useEffect(() => {
    if (message) {
      let tim1 = setTimeout(() => {
        toggle(false);
      }, 2500);
      let tim2 = setTimeout(() => {
        clearMessage();
      }, 3000);
      return () => {
        clearTimeout(tim1);
        clearTimeout(tim2);
      };
    }
  }, [message, type]);

  const deleteMsg = () => {
    toggle(false);
    let tim1 = setTimeout(() => {
      clearMessage();
    }, 500);
    return () => clearTimeout(tim1);
  };

  if(!message) return null;

  return (
    <div className={`error`}>
      <div className={`msg  ${type}  ${vis ? "slideDown" : "slideUp"}`}>
        <section>
          <img
            src={require(`../../assets/messageIcons//${type.toUpperCase()}.svg`)}
            alt={type}
          />{" "}
          {/* require(`../../icons/${type}.svg`) */}
          <div>{message && message}</div>
        </section>
        <div>
          <span onClick={deleteMsg}>&#10005;</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ messageReducer }) => ({
  message: messageReducer.message,
  type: messageReducer.type,
});

export default connect(mapStateToProps)(Message);
