import { useCallback } from "react"

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const useBasicUtils = () => {
    /**
     * formate the text
     * @param {string} name text which needs to be formatted
     */
    const formatName = useCallback((name) => {
        if (!name) return name
        const newName = name
            .split("_")
            .map((item) => {
                return item[0] + item.slice(1).toLowerCase()
            })
            .join(" ")

        return newName
    }, [])

    const splitText = useCallback((text, range) => {
        if (!text) return text

        let length = 30
        if (range) length = range

        if (text.length >= length + 3) return text.substring(0, length) + "..."

        return text
    }, [])

    /**
     * get formatted Date
     * @param {*} value date in milliseconds
     */
    const getDate = (value) => {
        return new Date(value).toLocaleDateString("en-GB")
    }

    /**
     *  returns named date
     * @param {*} val 
     */
    const namedDate = (val) => {
        const date = new Date(val);

        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }

    return { formatName, getDate, splitText, namedDate  }
}
