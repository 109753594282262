import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { UserReducer } from "./UserReducer";
import { RoleReducer } from "./RoleReducer";
import transactionReducer from "./transactionReducer";
import messageReducer from "./MessageReducer";
import { CLEAN_ALL_USER_DATA } from "../ActionType/UserActionTypes";

const appReducer = combineReducers({
  UserReducer,
  transactionReducer,
  messageReducer,
  RoleReducer,
});

const rootReducer = (state, action) => {
  if(action.type === CLEAN_ALL_USER_DATA && state && state["RoleReducer"])
    state.RoleReducer.currentRole = null;

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["RoleReducer"],
};

export default persistReducer(persistConfig, rootReducer);
