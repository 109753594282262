import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid";
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text";
import React, { useEffect, useState } from "react";

import { createUseStyles } from "react-jss";
import { getPromoterDetails } from "../../Actions/UserActions";
import { connect } from "react-redux";

import { ReactComponent as TrophyIcon } from "../../assets/refer/trophy.svg";
import { ReactComponent as CopyIcon } from "../../assets/refer/copy.svg";
import { productMapping } from "../../Data/productsMapping";
import Loader from "../../Awesome-ui/Loader/Loader";

const useStyles = createUseStyles((theme) => ({
  text: {
    border: "1px solid #CBD6E2",
    borderRadius: 4,
    background: "#EAF0F6",
    width: 250,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  launchCopyBtn: {
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginLeft: 8,
    padding: "10px 16px",
    color: "#7C98B6",
    background: "#fff",
    border: "1px solid #CBD6E2",
  },
  img: {
    marginRight: 18,
  },
  icon: {
    position: "absolute",
    right: 30,
    top: 20,
  },
  comp: {
    position: "relative",
  },
  promoterLink: {
    textDecoration: "none",
    color: "#fff",
    background: "#283C52",
    borderRadius: 5,
    padding: "12px 24px",
  },
}));

const Refer = ({ token }) => {
  const clx = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [copy, setCopy] = useState({ txt: "Copy" });

  useEffect(() => {
    async function init() {
      if (!token) return;
      let resp = await getPromoterDetails();
      if (!resp) return setLoading(false);
      setData(resp.referral_id);
      setLoading(false);
    }
    init();
  }, [token]);

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopy({ txt: "Copied", id: link });
    setTimeout(() => setCopy({ txt: "Copy" }), 1000);
  };

  if (loading) {
    return (
      <Grid
        container
        item
        xs={12}
        align="center"
        justify="center"
        height="100%"
      >
        <Loader width="40px" height="40px" />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12} justify="center" align="center" height="100%">
      <Grid
        item
        xs={7}
        sm={5}
        radius={16}
        padding={"50px 56px"}
        background="#fff"
        className={clx.comp}
        border="1px solid #CBD6E2"
      >
        <Text varient="h3" marginBottom={30}>
          Become a Promoter
        </Text>
        <Text varient="h5" marginBottom={30}>
          💸 Get 40% on your referrals first 6 months
        </Text>

        <Text varient="body-blog" fontSize={16} marginBottom={30}>
          Join our community and become a promoter! Share the love for
          AwesomeSuite and earn rewards. Earn 40% commissions for the first 6
          months by referring our platform to your network, empowering them to
          grow their social channels.
        </Text>

        <Grid
          container
          item
          xs={12}
          align="center"
          justify="center"
          marginBottom={18}
        >
          <Text
            varient="subText"
            padding={"8px 10px"}
            align="center"
            className={clx.text}
            fontSize={13.5}
          >
            {data}
          </Text>
        </Grid>
        <Text varient="h6" marginBottom={30} align="center">
          Your affiliate links
        </Text>

        {productMapping.map((item) => (
          <Grid
            container
            item
            key={item.id}
            xs={12}
            align="center"
            justify="center"
          >
            <img
              className={clx.img}
              src={item.Icon}
              width="50px"
              height="60px"
            />
            <Text
              varient="subText"
              padding={"8px 10px"}
              className={clx.text}
              fontSize={13.5}
            >
              {item.referLink(data)}
            </Text>
            <button
              className={clx.launchCopyBtn}
              onClick={() => handleCopy(item.referLink(data))}
            >
              <CopyIcon height={20} />
              <Text varient="subText" fontSize={13} color="#7C98B6">
                {item.referLink(data) === copy.id ? copy.txt : "Copy"}
              </Text>
            </button>
          </Grid>
        ))}
        <div className={clx.icon}>
          <TrophyIcon />
        </div>
      </Grid>
      <Grid
        container
        item
        xs={12}
        align="center"
        justify="center"
        padding="2rem"
      >
        <a
          href="https://awesomesuite.firstpromoter.com/login"
          className={clx.promoterLink}
          target="blank"
        >
          Referral dashboard
        </a>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ UserReducer }) => ({
  token: UserReducer.token,
});

export default connect(mapStateToProps)(Refer);
