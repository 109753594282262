export const LOAD_CUSTOMER_DETAILS = "LOAD_CUSTOMER_DETAILS";
export const LOAD_CUSTOMER_DETAILS_SUCCESS = "LOAD_CUSTOMER_DETAILS_SUCCESS";
export const LOAD_CUSTOMER_DETAILS_FAILED = "LOAD_CUSTOMER_DETAILS_FAILED";
export const LOAD_TRANSACTION_DETAILS = "Load_TRANSACTION_DETAILS";
export const LOAD_TRANSACTION_SUCCESS = "LOAD_TRANSACTION_SUCCESS";
export const LOAD_TRANSACTION_FAILED = "LOAD_TRANSACTION_FAILED";
export const LOAD_SUBSCRIPTION_DETAILS = "LOAD_SUBSCRIPTION_DETAILS";
export const LOAD_SUBSCRIPTION_DETAILS_SUCCESS = "LOAD_SUBSCRIPTION_DETAILS_SUCCESS";
export const LOAD_SUBSCRIPTION_DETAILS_FAILED = "LOAD_SUBSCRIPTION_DETAILS_FAILED";
export const LOAD_PLAN_DETAILS_LOADING = "LOAD_PLAN_DETAILS_LOADING";
export const LOAD_PLAN_DETAILS_SUCCESS = "LOAD_PLAN_DETAILS_SUCCESS";
export const LOAD_PLAN_DETAILS_FAILED = "LOAD_PLAN_DETAILS_FAILED";

export const LOAD_USER_INVOICES_LOADING = "LOAD_USER_INVOICES_LOADING";
export const LOAD_USER_INVOICES_SUCCESS = "LOAD_USER_INVOICES_SUCCESS";
export const LOAD_USER_INVOICES_FAILED = "LOAD_USER_INVOICES_FAILED";

export const REMOVE_CARD = "REMOVE_CARD";