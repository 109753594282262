import React from 'react';

const Policy = () => {
    return (
        <div className="bottom-policy">
          <p>©2021 Awesomesuite, Inc. All Rights Reserved.</p>
          <a href="https://awesomesuite.com/privacy" style={{textDecoration:"none"}} target="_blank" className="aui-policy-link">
             <h4>Privacy Policy</h4>
          </a>
        </div>
    )
}

export default Policy
