import React from "react";
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import { createUseStyles } from "react-jss";
import tick from "../../assets/pricing/tick.svg";

const useStyles = createUseStyles((theme) => ({
  priceCard: {
    maxWidth: 390,
    border: "1px solid #DFE3EB",
    borderRadius: 4,
    borderTop: (props) => `10px solid ${props.color}`,
    "&:hover": {
      border: (props) => `1px solid ${props.color}`,
      borderTop: (props) => `10px solid ${props.color}`,
    },
  },
  higilight: {
    "& button": {
      color: "#fff",
      border: "2px solid #fff",
    },
  },
  popular: {
    width: "fit-content",
    padding: "6px 12px",
    borderRadius: 18,
    background: "#00F1B1",
    color: "#004053",
    fontSize: "12px",
    marginLeft: "auto",
  },
  tick: {
    margin: "auto 0",
    marginRight: 12,
  },
  priceImg: {
    marginRight: 12,
  },
  btn: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    width: "100%",
    padding: "14px",
    border: "2px solid #2D3E50",
    fontSize: 16,
    background: "transparent",
    margin: "12px 0",
    cursor: "pointer",
    "&:hover": {
      "& span": {
        color: "#ffff !important",
      },
      border: (props) => `2px solid ${props.color}`,
      background: (props) => props.color,
    },
  },
}));

const DialPriceCard = ({ data, isActive, onClick, xs, sm, md }) => {
  const { plan, amount, img, color, background, featureTitle, features } = data;
  const { description, isPopular, connect, calText } = data;
  const classes = useStyles({ color: color, background: background });

  return (
    <Grid
      container
      item
      xs={xs}
      sm={sm}
      md={md}
      padding={"12px 28px"}
      background={background}
      className={`${classes.priceCard} ${isPopular ? classes.higilight : ""}`}
    >
      <Grid container item xs={12} align="center">
        {img && <img className={classes.priceImg} src={img} alt={plan} />}
        <Text varient="h4" color={color} margin="1.5rem 0 1rem 0">
          {plan}
        </Text>
      </Grid>
      <Grid height={104}>
        <Text
          varient="subText"
          color={isPopular ? "#fff" : "light"}
          marginBottom="1.5rem"
        >
          {description}
        </Text>
      </Grid>
      <Grid item xs={connect ? 12 : 6}>
        {connect ? (
          <Text varient="h3" margin="0">
            {connect}
          </Text>
        ) : (
          <Text varient="h2" color={isPopular && "#fff"} margin="0">
            $ {amount}
          </Text>
        )}
      </Grid>
      <Grid item xs={6}>
        {isPopular && <div className={classes.popular}>Most popular</div>}
      </Grid>
      <Grid item xs={12} margin="12px 0">
        <Text varient="body" color={isPopular ? "#fff" : "light"} margin="0">
          {calText}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <button
          className={`${classes.btn} ${isActive ? classes.btnSelected : ""}`}
          onClick={onClick}
        >
          {isActive ? <span>Current</span> : <span>Upgrade</span>}
        </button>
      </Grid>
      <Grid item xs={12} marginTop="1.8rem">
        <Text
          varient="body"
          color={isPopular && "#fff"}
          margin="0"
          marginBottom={18}
        >
          <b>{featureTitle}</b>
        </Text>
        {features.map((item, key) => (
          <Grid container align="center" key={key} margin="16px 0">
            {typeof item === "string" && (
              <img className={classes.tick} src={tick} alt="tick" />
            )}
            <Text
              varient="body"
              margin="0"
              fontSize={15}
              color={isPopular ? "#fff" : "light"}
            >
              {item}
            </Text>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} margin={"16px 0"}>
        <Text margin="0" align="center" color={isPopular && "#fff"}>
          or see all features
        </Text>
      </Grid>
    </Grid>
  );
};

export default DialPriceCard;
