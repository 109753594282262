import {
  LOAD_CUSTOMER_DETAILS,
  LOAD_CUSTOMER_DETAILS_SUCCESS,
  LOAD_CUSTOMER_DETAILS_FAILED,
  LOAD_SUBSCRIPTION_DETAILS,
  LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
  LOAD_SUBSCRIPTION_DETAILS_FAILED,
  LOAD_TRANSACTION_DETAILS,
  LOAD_TRANSACTION_SUCCESS,
  LOAD_TRANSACTION_FAILED,
  LOAD_PLAN_DETAILS_FAILED,
  LOAD_PLAN_DETAILS_LOADING,
  LOAD_PLAN_DETAILS_SUCCESS,
  LOAD_USER_INVOICES_LOADING,
  LOAD_USER_INVOICES_SUCCESS,
  LOAD_USER_INVOICES_FAILED,
  REMOVE_CARD,
} from "../ActionType/transactionActionTypes";

const initialState = {
  card: null,
  userDetails: null,
  subscriptionMapping: {},
  subscription: null,
  transactionList: null,
  plans: null,
  planPrice: null,
  loading: false,
  transactionLoading: false,
  subscriptionLoading: false,
  plansLoading: false,
  fill: false,
  invoices: null,
  invoicesLoading: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_DETAILS:
      return { ...state, loading: true };
    case LOAD_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionLoading: true,
      };
    case LOAD_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionLoading: true,
      };
    case LOAD_PLAN_DETAILS_LOADING:
      return {
        ...state,
        plansLoading: true,
      };
    case LOAD_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case LOAD_TRANSACTION_FAILED:
      return {
        ...state,
        transactionLoading: false,
      };
    case LOAD_SUBSCRIPTION_DETAILS_FAILED:
      return {
        ...state,
        subscriptionLoading: false,
      };
    case LOAD_PLAN_DETAILS_FAILED:
      return {
        ...state,
        plansLoading: false,
      };
    case LOAD_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        fill: true,
        userDetails: action.payload.customer,
        card: action.payload.card,
      };
    case LOAD_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionList: action.payload.transaction,
        transactionLoading: false,
      };
    case LOAD_SUBSCRIPTION_DETAILS_SUCCESS:
      return handleSubscription(state, action.payload);
    case LOAD_PLAN_DETAILS_SUCCESS:
      return handlePrice(state, action.payload);
    case LOAD_USER_INVOICES_LOADING:
      return {
        ...state,
        invoicesLoading: true,
      };
    case LOAD_USER_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.payload.invoices,
      };
    case LOAD_USER_INVOICES_FAILED:
      return {
        ...state,
        invoicesLoading: false,
      };
    case REMOVE_CARD:
      return {
        ...state,
        card: null,
      };
    default:
      return state;
  }
};

export default transactionReducer;

function handlePrice(state, params) {
  const { productId, planPrice, plans } = params;

  return {
    ...state,
    planPrice: {
      ...state.planPrice,
      [productId]: planPrice,
    },
    plans: {
      ...state.plans,
      [productId]: plans,
    },
  };
}

function handleSubscription(state, payload) {
  let subProdMap = {};
  if (payload.subscription)
    payload.subscription.forEach((item) => (subProdMap[item.productId] = item));

  return {
    ...state,
    subscriptionMapping: subProdMap,
    subscription: payload.subscription,
    subscriptionLoading: false,
  };
}
