import React from "react";
import TopLogo from "./TopLogo";
import Policy from "./Policy";
import "./Template.scss";

const Template = ({ children, ...props }) => {
  return (
    <div className="aui-center-form-wrapper">
      <div className="aui-center-form">
        <TopLogo />
        {children}
        <Policy />
      </div>
    </div>
  );
};

export default Template;
