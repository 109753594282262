import React, { useEffect } from "react";

import Chip from "../../../Awesome-ui/Chip/Chip";
import Table from "../../../Awesome-ui/Table/Table";
import TableRow from "../../../Awesome-ui/TableRow/TableRow";
import TableHeader from "../../../Awesome-ui/TableHeader/TableHeader";
import TableCell from "../../../Awesome-ui/TableCell/TableCell";
import { connect } from "react-redux";
import { getUserInvoiceDoc, getUserInvoices } from "../../../Actions/transactionAction";
import { ReactComponent as EyeIcon } from "../../../assets/utilIcons/eye.svg";
import { createUseStyles } from "react-jss";
import { useBasicUtils } from "../../../hooks/BasicUtility/basicUtils";

const useStyles = createUseStyles((theme) => ({
  view: {
    width: "fit-content",
    borderRadius: 16,
    border: "1px solid #AABCD0",
    padding: "6px 12px",
    cursor: "pointer",
    margin: "0 auto"
  },
  space: {
    marginLeft: 4
  }
}));

/**
 * gets the chip color based on the status of the
 * invoice.
 * @param {["success", "pending"]} status
 * @returns
 */
function getChipColor(status) {
  if (status === "paid") return "primary";
  if (status === "failed") return "danger";
  else return "pending";
}

/**
 * {@link Int} api is use to format the amount and it will
 * add ',' to the value
 * @param {number} amount
 * @returns @example 20,000
 */
function getAmount(amount) {
  const val = parseInt(amount);
  const format = new Intl.NumberFormat("es-US");
  return format.format(val);
}

const Invoices = ({ invoices }) => {
  const classes = useStyles();
  const { namedDate } = useBasicUtils();

  useEffect(() => {
    if (!invoices) {
      getUserInvoices();
    }
  }, [invoices]);

  return (
    <div>
      <div className="subscription-title" style={{ padding: "12px" }}>
        <h5>Invoices</h5>
      </div>
      <div className="transaction-table">
        <Table border>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader align="center">Amount</TableHeader>
            <TableHeader align="center">Receipt</TableHeader>
            <TableHeader align="center">Status</TableHeader>
          </TableRow>
          {invoices && invoices.length > 0 ? (
            invoices.map((item, index) => (
              <TableRow dark={!(index % 2) && true} key={index}>
                <TableCell align="center">{namedDate(item.date * 1000)}</TableCell>
                <TableCell align="center">
                    {item.description || "-"}
                </TableCell>
                <TableCell align="center">
                  {item.currency_code} {getAmount(item.total)}
                </TableCell>
                <TableCell>
                  <div className={classes.view} onClick={() => getUserInvoiceDoc(item.id)}>
                    <EyeIcon />
                    <span className={classes.space}>recipt</span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={item.status.toUpperCase()}
                    size="medium"
                    color={getChipColor(item.status)}
                  />
                </TableCell>

              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No Invoices Yet
              </TableCell>
            </TableRow>
          )}
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ transactionReducer }) => ({
  invoices: transactionReducer.invoices,
});

export default connect(mapStateToProps)(Invoices);
