import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import React from "react";
import { createUseStyles } from "react-jss";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel-icon.svg";

const useStyles = createUseStyles(() => ({
  backgroundCompound: {
    position: "fixed",
    height: "100%",
    width: "100%",
    background: "rgba(45,62,80,0.72)",
    top: 0,
    left: 0,
    zIndex: 1000000000000000,
  },
  createFolderModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "400px",
    maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : "auto"),
    backgroundColor: "#fff",
    borderRadius: ({ noBorder }) => (noBorder ? 0 : 6),
  },
  modalHeader: {
    background: ({ color }) => {
      if (color === "warn")
        return "linear-gradient(90deg, rgb(255 194 21), rgb(222 163 0))";
      if (color === "danger") return "linear-gradient(90deg,#E5484E,#F37277)";
      return "linear-gradient(90deg,#2cd5f8,#20cbfa,#25c1fa,#36b6f9,#49abf5,#55a3f1,#629bed,#6d93e7,#758ce2,#7d86dc,#847fd6,#8b78cf)";
    },
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ({ noBorder }) => (noBorder ? 0 : "6px 6px 0 0"),
  },
  cancelLogo: {
    marginRight: "1rem",
    padding: "0.5rem",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.24)",
    },
  },
  modalContent: {
    padding: "1rem 1.5rem",
    borderRadius: "0 0 6px 6px",
    border: "1px solid var(--border-color)",
  },
  folderSelector: {
    border: "1px solid var(--border-color)",
    width: "80%",
    aspectRatio: 1,
    marginTop: "auto",
    borderRadius: "4px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  folderSelectorCurrent: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  folderSelectorMap: {
    width: "180px",
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translateY(calc(100% + 4px))",
    borderRadius: "4px",
    border: "1px solid var(--border-color)",
    padding: "1rem",
    backgroundColor: "#fff",
  },
}));

const SmallActionModel = ({
  open,
  close,
  title,
  children,
  maxWidth,
  noBorder,
  color = "blue",
}) => {
  const classes = useStyles({ color, maxWidth, noBorder });

  if (!open) {
    return null;
  }

  return (
    <div className={classes.backgroundCompound}>
      <div className={classes.createFolderModal}>
        <div className={classes.modalHeader}>
          <Text color="#fff" marginLeft="1rem">
            {title}
          </Text>
          <div className={classes.cancelLogo} onClick={close}>
            <CancelIcon />
          </div>
        </div>
        <div className={classes.modalContent}>
          <Grid container spacing={"10"}>
            {children}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SmallActionModel;
