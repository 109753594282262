import { ADD_USER_ROLES } from "../ActionType/UserActionTypes";

const initialState = {
  currentRole: null,
  roles: null,
  organizations: null,
  orgName: null
};

export const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_ROLES:
      return handleUserRole(state, action.payload);
    default:
      return state;
  }
};

function handleUserRole(state, payload) {
  const { organizations, orgName } = payload;
  let roles = { ...state.roles };
  let org = { ...state.organizations };
  let currentRole = state.currentRole;

  if (organizations) {
    org = organizations;
    organizations.map((item) => (roles[item.orgId] = item.role));
    currentRole = organizations[0]?.role;
  }

  return {
    ...state,
    roles,
    organizations: org,
    currentRole,
    orgName
  };
}
