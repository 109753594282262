export const OWNER = "owner";
export const ANY_ROLE = "ANY_ROLE";

//tracking types
export const PAGE_VIEW = "PAGE_VIEW";
export const CLICK = "CLICK";
export const PRODUCT_TRY = "PRODUCT_TRY";
export const APP_REDIRECT = "APP_REDIRECT";
export const LOGIN = "LOGIN";

// login challenges
export const REGISTER = "REGISTER";
export const EMAIL_OTP = "EMAIL_OTP";
export const PASSWORD = "PASSWORD";
export const REGISTER_ORG = "REGISTER_ORG";


export const VERIFY_PLAN = "VERIFY_PLAN";
export const COST_CALC = "COST_CALC";