import track from "react-tracking";
import { analyticApi } from "../api/axios";
import App from "../App";
import { store } from "../store";

const { getState } = store;

const DataTracker = track(
  { app: "accounts" },
  {
    dispatch: trackingHandler,
  }
)(App);

export default DataTracker;

const failedEvents = [];

/**
 * All evetns for the data tracking comes here to get publish
 * @param {*} event user intraction data {type: , page, app, title: };
 */
function trackingHandler(event) {
  const { UserReducer } = getState();
  const { user } = UserReducer;

  if (!user) {
    failedEvents.push(event);
    setTimeout(() => {
      trackingHandler(event);
    }, 2000);
    return;
  }

  event.count = 1;

  const data = {
    productId: 0,
    severity: "ANALYTICAL",
    object: event.page,
    eventType: event.type,
    message: JSON.stringify(event),
    isDeleted: "",
    currentUser: user.id,
  };

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  };

  analyticApi.post("", data, options);
}
