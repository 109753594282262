import React, { useEffect, useState } from "react";
import "./Subscription.scss";

import Widget from "../../Awesome-ui/widget/Widget";
import WidgetTitle from "../../Awesome-ui/WidgetTitle/WidgetTitle";
import WidgetContent from "../../Awesome-ui/WidgetContent/WidgetContent";
import Tabs from "../../Awesome-ui/Tabs";
import TabPanel from "../../Awesome-ui/TabPanel";
import Loader from "../../Awesome-ui/Loader/Loader";

import Transaction from "./Components/Transaction";
import BillingDetails from "./Components/BillingDetails";
import {
  getCustomerDetails,
  getSubscribedPlan,
  getPlans,
  getUserTransactions,
} from "../../Actions/transactionAction";
import { ReactComponent as SubscriptionImage } from "../../assets/bannerSvg/Subscription-Image.svg";
import { connect } from "react-redux";
import Plans from "./Components/Plans";
import { PAGE_VIEW, CLICK } from "../../constants";
import Invoices from "./Components/Invoices";
import PaymentMethod from "./Components/PaymentMethod";
import { useHistory } from "react-router-dom";

const tabsName = {
  0: "Plans",
  1: "Billing details",
  2: "Transaction History",
  3: "Invoices",
  4: "Payment Methods",
};

const tabsNameParmas = {
  plans: 0,
  "billing-details": 1,
  "transaction-history": 2,
  invoices: 3,
  "payment-methods": 4,
};

const Subscription = ({
  track,
  token,
  detailsPresent,
  transaction,
  userDetails,
  subscription,
  plans,
  plansLoading,
  user,
  getDetailsLoading,
  subscriptionLoading,
  transactionLoading,
  ...props
}) => {
  const viewKey = props.match;
  const [value, setValue] = useState(0);
  const history = useHistory();

  useEffect(() => track(PAGE_VIEW), []);

  useEffect(() => {
    if (viewKey.params.view) {
      setValue(tabsNameParmas[viewKey.params.view]);
    }
  }, [viewKey.params?.view]);

  useEffect(() => {
    if (!plans) getPlans();

    if (!subscription) getSubscribedPlan();

    if (!userDetails && !getDetailsLoading) getCustomerDetails();

    if (!transaction && !transactionLoading) getUserTransactions();
  }, [token, plans, subscription, transaction, userDetails, user]);

  if (subscriptionLoading && !subscription) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader width="40px" height="40px" />
      </div>
    );
  }

  return (
    <div className="subscription">
      <div className="subscription-main">
        <div className="subscription-content">
          <Widget width={"90%"} height={"auto"} style={{ padding: "0" }}>
            <WidgetTitle
              style={{
                border: "none",
                padding: "14px 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="text-content-big">
                <h2>Accounts and Billing</h2>
                <p>
                  All the accounts in this organization that you have access to.
                  Click to open
                </p>
              </div>
              <div className="image">
                <SubscriptionImage />
              </div>
            </WidgetTitle>
            <WidgetContent
              alignItems={"center"}
              style={{ flexDirection: "column" }}
            >
              <Tabs
                noRadius
                noPadding
                value={value}
                tabChange={(index) => {
                  const arr = Object.keys(tabsNameParmas);
                  track(CLICK, { title: `tab click ${tabsName[index]}` });
                  setValue(index);
                  if (arr[index]) {
                    history.push(arr[index]);
                  }
                }}
              >
                <TabPanel label="Product" index={0}>
                  <Plans />
                </TabPanel>
                <TabPanel label="Billing details" index={1}>
                  <BillingDetails />
                </TabPanel>
                <TabPanel label="Transaction History" index={2}>
                  <Transaction />
                </TabPanel>
                <TabPanel label="Invoices" index={3}>
                  <Invoices />
                </TabPanel>
                <TabPanel label="Payment Methods" index={4}>
                  <PaymentMethod />
                </TabPanel>
              </Tabs>
            </WidgetContent>
          </Widget>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ UserReducer, transactionReducer }) => ({
  token: UserReducer.token,
  user: UserReducer.user,
  detailsPresent: transactionReducer.fill,
  transaction: transactionReducer.transactionList,
  userDetails: transactionReducer.userDetails,
  plans: transactionReducer.plans,
  subscription: transactionReducer.subscription,
  getDetailsLoading: transactionReducer.loading,
  transactionLoading: transactionReducer.transactionLoading,
  subscriptionLoading: transactionReducer.subscriptionLoading,
  plansLoading: transactionReducer.plansLoading,
});

export default connect(mapStateToProps)(Subscription);
