import { store } from "../store";
import {
  LOGIN_USER_BYPASS,
  LOAD_USER_DATA,
  CLEAN_ALL_USER_DATA,
  FORGOT_PASS_ADD_EMAIL,
  FORGOT_PASS_ADD_OTP,
  LOAD_USER_SESSION,
  ADD_SIGNUP_DATA,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_LOADING,
  GET_ORGANIZATION_FAILED,
  GET_ALL_USERS_IN_ORG_FAILED,
  GET_ALL_USERS_IN_ORGANIZATION,
  GET_ALL_USERS_IN_ORG_LOADING,
  ADD_USER_ROLES,
  GLOBAL_LOADING,
} from "../ActionType/UserActionTypes";
import { userApi, api, APIS, workspaceApi } from "../api/axios";
import { createMessage } from "./MessageAction";
import { DANGER, SUCCESS } from "../ActionType/MessageActionTypes";
import { getSubscribedPlan, getCustomerDetails } from "./transactionAction";
import Axios from "axios";
import firebase from "../configuration/firebase";
import { getLinkToService } from "../Data/productsMapping";

import {
  EMAIL_OTP,
  OWNER,
  REGISTER,
  PASSWORD,
  REGISTER_ORG,
} from "../constants";

/**
 * get headers with token
 */
export function getHeaders(params) {
  const { token } = store.getState().UserReducer;

  return {
    params: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}

const loadOtherDetails = async () => {
  const { RoleReducer } = store;
  if (RoleReducer?.currentRole === OWNER) {
    loadSession();
    getCustomerDetails();
  }
  getOrganization();
  getSubscribedPlan();
};

/**
 * login user with the token after
 * @param {*} payload
 */
export const LogUserWithToken = async (payload) => {
  if (!payload) return;
  localStorage.setItem("auth_token", payload.token);
  localStorage.setItem("access-token", payload.accessToken);
  store.dispatch({ type: LOGIN_USER_BYPASS, payload: payload });
  await getOrganization();
  await UpdateUserData();
};

/**
 * oAuth login with the google token and get auth token of our app.
 * @param {*} gToken
 * @param {*} reqAddress
 * @returns
 */
export const oAuthLogin = async (gToken, reqAddress, data, query) => {
  if (reqAddress !== "login") return;

  const googleLogin = await userApi.get(
    `/getToken?token=${gToken}&action=google`
  );

  if (googleLogin.data.code === 200) {
    const obj = googleLogin.data?.model;

    let doRedirect = true;

    if (obj.challenge || obj.challenge.length > 1) {
      doRedirect = false;
      handleChallenges(obj.challenge, null, {
        service: data.service,
        email: data.email,
      });
    }

    const token = obj.auth_token;
    const org = obj.organisations;

    addUserRole(org);

    if (data.service && doRedirect && !data.redirectionURL)
      redirectToService(data.service, token, null, null, query);
    else redirectToPage(token, null, data.redirectionURL, null, query);

    LogUserWithToken({ token: token, accessToken: token });
  } else {
    createMessage(
      DANGER,
      googleLogin.data.msg || "Something went wrong while authorizing."
    );
  }
};

export const oAuthLoginGsuite = async (gToken, query) => {
  const googleLogin = await userApi.get(
    `/getToken?token=${gToken}&action=google`
  );

  if (googleLogin.data.code === 200) {
    const obj = googleLogin.data?.model;
    window.location = `${query.redirect_uri}?${query.response_type}=${obj.auth_token}&state=${query.state}`;
  } else {
    createMessage(
      DANGER,
      googleLogin.data.msg || "Something went wrong while authorizing."
    );
  }
};

/**
 * Add user roles based on the role in organization
 * we will show the dashboard.
 *
 * @param {} organizations - organization.
 */
export const addUserRole = (organizations) => {
  const data = {
    organizations,
  };
  store.dispatch({ type: ADD_USER_ROLES, payload: data });
};

/**
 * after signup we need to create organization and
 * work space for social. call this after google signup and opt verification.
 *
 * @param {*} companyName - company name
 * @param {*} email - email
 * @param {*} phoneNumber - phone number
 */
export const completeSignUpFlow = async (
  email,
  companyName,
  phoneNumber,
  token,
  product_id,
  promoCode
) => {
  try {
    const orgCreated = await createOrganization(
      companyName,
      email,
      phoneNumber,
      token,
      product_id,
      promoCode
    );
  } catch (error) {
    throw error;
  }
};

export async function getAccessToken(token) {
  try {
    setGlobalLoading(true);
    const resp = await workspaceApi.get("/accessToken", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setGlobalLoading(false);
    if (resp.data.code !== 200) return null;

    return resp.data.model.auth_token;
  } catch (err) {
    setGlobalLoading(false);
    return null;
  }
}

export async function logout() {
  try {
    const token = store.getState().UserReducer.token;
    if (!token) return;
    const resp = await userApi.post("/logout", null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.data.code !== 200) return null;

    return resp.data.model;
  } catch (err) {
    return null;
  }
}

/**
 * helper function for the login and signup.
 * redirect to any service specified in the url parameters
 *
 * @param {*} serviceName - service is reffed as product name
 * @param {*} serviceLink - service link to which we need to make a request
 * @param {*} token - token to be included in the parameters
 * @param {*} workspaceId  - workspace id
 */
export const redirectToService = async (
  serviceName,
  token,
  onboard,
  generateToken,
  params = ""
) => {
  if (generateToken) {
    token = await getAccessToken(token);
  }

  const link = getLinkToService(serviceName);
  const encodedToken = encodeURIComponent(token);
  let newLinkWithLink = `${link}?p=${encodedToken}&${params}`;

  if (onboard) {
    newLinkWithLink = `${newLinkWithLink}&onboard=true&${params}`;
  }

  localStorage.setItem("auth_token", token);
  window.location.assign(newLinkWithLink);
};

/**
 * helper function to handle user sign up and simple redirect to account
 */
export const redirectToPage = async (
  token,
  history,
  url,
  generateToken,
  params = ""
) => {
  if (generateToken) {
    token = await getAccessToken(token);
  }
  localStorage.setItem("auth_token", token);
  localStorage.setItem("access-token", token);
  LogUserWithToken({ token: token, accessToken: token });
  if (url) window.location.assign(`${url}/login?p=${token}&${params}`);
  else window.location.href = "/";
};

export const UpdateUserData = async () => {
  const token = store.getState().UserReducer.token;
  if (!token) return;
  try {
    const header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const result = await userApi.get("/user", {
      headers: header,
    });
    if (result.data.code === 200) {
      store.dispatch({ type: LOAD_USER_DATA, payload: result.data.model });
      loadOtherDetails();
    } else {
      store.dispatch({ type: CLEAN_ALL_USER_DATA });
      createMessage(DANGER, result.data.msg);
    }
  } catch (error) {
    if (error.response) createMessage(DANGER, error.response.data);
    else
      createMessage(
        DANGER,
        "Something went wrong while getting the user data."
      );
  }
};

export const logoutUser = async (callApi = true) => {
  firebase.auth().signOut();
  localStorage.clear();
  if (callApi) logout();
  await store.dispatch({ type: CLEAN_ALL_USER_DATA });
};

export const forgotpassAddEmail = async (email, action) => {
  try {
    const result = await userApi.post(
      `login/forget?email=${email}&action=${action}`
    );
    if (result.data.code === 200) {
      store.dispatch({ type: FORGOT_PASS_ADD_EMAIL, payload: { email } });
      return true;
    } else {
      createMessage(DANGER, result.data.msg);
    }
  } catch (error) {
    if (error.response) createMessage(DANGER, error.response.data);
    else createMessage(DANGER, "Failed to get otp for given.");
  }
};

export const forgotpassAddtoken = async (otp, token) => {
  store.dispatch({ type: FORGOT_PASS_ADD_OTP, payload: { otp, token } });
};

export const forgetPassReset = async (newPass, confirmPass, optionalToken) => {
  const { token, otp } = store.getState().UserReducer.forgotPassFlow;

  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token || optionalToken}`,
  };

  const data = {
    newPassword: newPass,
    confirmPassword: confirmPass,
    oldPassword: otp,
  };

  const result = await userApi.put("/user?action=reset", data, {
    headers: header,
  });

  if (result.data.code === 200) {
    LogUserWithToken({ token: token });
    return token;
  } else {
    createMessage(DANGER, "Failed to Reset Your password.");
    store.dispatch({ type: CLEAN_ALL_USER_DATA });
  }
};

export const loadSession = async () => {
  const { token, globalLoading } = store.getState().UserReducer;
  if (globalLoading) return;
  if (token) {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const result = await api.get("/session", {
        headers: header,
      });
      if (result.data.model) {
        store.dispatch({
          type: LOAD_USER_SESSION,
          payload: {
            session: result.data.model.filter((item) => item.active !== 1),
            activeSession: result.data.model.filter(
              (item) => item.active === 1
            ),
          },
        });
      } else {
        createMessage(DANGER, result.data.msg);
      }
    } catch (error) {
      if (error.response) {
        createMessage(DANGER, error.response.data);
      } else {
        createMessage(DANGER, "Failed to get User Session.");
      }
    }
  }
};

export const addSignupData = async (email, company, phoneNumber) => {
  if (!email || !company || !phoneNumber) return;

  const data = {
    companyName: company,
    phoneNumber: phoneNumber,
    email: email,
  };

  store.dispatch({
    type: ADD_SIGNUP_DATA,
    payload: data,
  });
};

export const createOrganization = async (
  companyName,
  email,
  phoneNumber,
  token,
  product_id,
  promoCode
) => {
  if (!companyName || !email || !token) {
    return;
  }

  try {
    const data = {
      name: companyName,
      contactEmail: email,
      contactNumber: phoneNumber,
      logo: "",
      product_id: product_id || null,
    };
    let query = "";
    if (product_id) {
      query = `product_id=${product_id}`;
    }

    if (promoCode) {
      query += `&promotional_code=${promoCode}`;
    }

    const header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const result = await userApi.post(`/organisation?${query}`, data, {
      headers: header,
    });
    if (result.data.code === 200) {
      let respData = result.data.model;
      respData.role = "owner";
      const orgs = [{ orgId: respData.id, role: respData.role }];

      store.dispatch({
        type: ADD_USER_ROLES,
        payload: { organizations: orgs, orgName: respData.name },
      });

      return true;
    } else {
      throw new Error(result.data?.msg);
    }
  } catch (error) {
    throw error;
  }
};

export const getOrganization = async () => {
  try {
    const { token, globalLoading } = store.getState().UserReducer;
    if (globalLoading) return;
    if (token) {
      store.dispatch({ type: GET_ORGANIZATION_LOADING });
      const res = await userApi.get("/organisation", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const respData = res.data.model;
      if (res.data.code !== 200) {
        return createMessage(DANGER, res.data.msg);
      }
      if (respData) {
        const orgs = [{ orgId: respData.id, role: respData.role }];

        store.dispatch({
          type: ADD_USER_ROLES,
          payload: { organizations: orgs, orgName: respData.name },
        });

        store.dispatch({
          type: GET_ORGANIZATION_DETAILS,
          payload: respData,
        });
      } else {
        if (!window.location.pathname?.includes("challenge/org"))
          window.location = "/challenge/org";
      }
    }
  } catch (err) {
    if (err.response) {
      store.dispatch({ type: GET_ORGANIZATION_FAILED });
      createMessage(DANGER, err.response.data);
    } else {
      createMessage(DANGER, "Failed to get the organization details.");
    }
  }
};

export const updateUserData = async (data) => {
  try {
    const { id } = store.getState().UserReducer.user;
    const { token } = store.getState().UserReducer;

    const dataToSend = {
      id: id,
      email_id: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      picture: data.picture,
      user_name: data.userName,
    };

    const result = await userApi.put("/user", dataToSend, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.code === 200) {
      store.dispatch({
        type: LOAD_USER_DATA,
        payload: { ...result.data.model },
      });
    } else {
      createMessage(DANGER, "Failed To Update Your Details.");
    }
  } catch (error) {
    if (error.response) {
      createMessage(DANGER, error.response.data);
    } else {
      createMessage(DANGER, "Failed To Update Your Details.");
    }
  }
};

export const UpdatePasswordWithOld = async (old, newPass, confirmPass) => {
  const { token } = store.getState().UserReducer;

  try {
    const header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const data = {
      newPassword: newPass,
      confirmPassword: confirmPass,
      oldPassword: old,
    };

    const result = await userApi.put("/user?action=reset", data, {
      headers: header,
    });

    if (result.data.code === 200) {
      createMessage(SUCCESS, "Password Reset Successfully !");
      return true;
    } else {
      createMessage(DANGER, "Failed to Reset Your password.");
      return false;
    }
  } catch (error) {
    if (error.response) {
      createMessage(DANGER, error.response.data);
    } else {
      createMessage(DANGER, "Failed To Update Your Details.");
    }
  }
};

/**
 * Api to all the user's connected with the organization
 * 
 * @example 
 * {
    "organisationName": "FCUBE",
    "contactEmail": "manishkp220@fcure.com",
    "contactNumber": "9001708601",
    "logo": "",
    "users": [
        {
            "id": 12,
            "user_name": "pkpanery",
            "first_name": "manish",
            "last_name": "panery",
            "email_id": "manishk22120@gmail.com",
            "phone_number": "9001708601",
            "picture": "https://learnerimagesbucket.s3.amazonaws.com/hacker.png",
            "verified_email": 0,
            "enable": 1
        }]
  } 
 */
export const getAllUsersInOrganization = async () => {
  try {
    const { token, organization: org } = store.getState().UserReducer;

    if (!org?.id) return;
    store.dispatch({ type: GET_ALL_USERS_IN_ORG_LOADING });
    const res = await userApi.get(`/organisation?org_id=${org.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.model) {
      store.dispatch({
        type: GET_ALL_USERS_IN_ORGANIZATION,
        payload: { data: res.data.model.users },
      });
    } else createMessage(DANGER, res.data.msg);
  } catch (err) {
    if (err.response) {
      store.dispatch({ type: GET_ALL_USERS_IN_ORG_FAILED });
      createMessage(DANGER, err.response.data);
    }
  }
};

// {
//     "name": "awesomesocial",
//     "code": "AwesomeSocial",
//     "timezone": "Asia/Kolkata",
//     "emailId": "awesomesocial@pwc.com",
//     "address": "Jaipur, India",
//     "enable": 1
// }
export const createWorkspace = async (data, token) => {
  try {
    const res = await Axios.post(`${APIS.WORKSPACE_API}/company`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.model) {
      return res.data.model.id;
    } else {
      createMessage(DANGER, res.data.msg);
      return null;
    }
  } catch (err) {
    if (err.response) {
      createMessage(DANGER, err.response.data);
    }
  }
  return;
};

/**
 * Handle all the challenges occured during signn up or sign in
 */
export function handleChallenges(challenge, history, data) {
  let redirectTo = null;
  switch (challenge) {
    case REGISTER:
      // add action here
      break;
    case EMAIL_OTP:
      redirectTo = "/signup/confirm?email=" + data.email;
      if (data.service) {
        redirectTo += "&service=" + data.service;
      }
      break;
    case PASSWORD:
      break;
    case REGISTER_ORG:
      LogUserWithToken({ token: data.token, accessToken: "" });
      redirectTo = "/challenge/org";
      break;
    default:
      return;
  }
  if (redirectTo) {
    if (history) {
      history.push(redirectTo);
    } else {
      window.location.pathname = redirectTo;
    }
  }
}

/**
 * User detete Apis
 */

/**
 * Delete user token.
 */
export async function userDelete() {
  try {
    let headers = getHeaders();

    let resp = await userApi.delete("/user", headers);
    if (resp.data.code < 400) {
      logoutUser();
      return resp.data.model;
    } else {
      createMessage(DANGER, resp.data.msg);
    }
  } catch (error) {
    if (error.response) {
      return {
        status: "fail",
        msg: error.response.data?.msg,
      };
    }
    return {
      status: "fail",
      msg: "something went wrong",
    };
  }
}

/**
 * Add new email address to user Api. this will allow us to
 * change user email address
 */
async function addNewEmailAddress(email) {
  try {
    let params = {
      action: "change_email",
      email: email,
    };
    let headers = getHeaders(params);

    let resp = await userApi.put("/user", {}, headers);
    if (resp.data.code < 400) {
      return {
        status: "success",
        data: resp.data.model,
      };
    } else {
      return {
        status: "fail",
        msg: resp.data.msg,
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: "fail",
        msg: error.response.data?.msg,
      };
    }
    return {
      status: "fail",
      msg: "something went wrong",
    };
  }
}

/**
 * Add new email address to user Api. this will allow us to
 * change user email address
 */
async function verifyNewEmailAddress(otp) {
  try {
    let params = {
      action: "verify_email_otp",
      otp: otp,
    };
    let headers = getHeaders(params);

    let resp = await userApi.put("/user", {}, headers);
    if (resp.data.code < 400) {
      return {
        status: "success",
        data: resp.data.model,
      };
    } else {
      return {
        status: "fail",
        msg: resp.data.msg,
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: "fail",
        msg: error.response.data?.msg,
      };
    }
    return {
      status: "fail",
      msg: "something went wrong",
    };
  }
}

/**
 * Add new email address to user Api. this will allow us to
 * change user email address
 */
async function emailChangeConfirmationCode(otp) {
  try {
    let params = {
      action: "final_verification",
      otp: otp,
    };
    let headers = getHeaders(params);

    let resp = await userApi.put("/user", {}, headers);
    if (resp.data.code < 400) {
      logoutUser();
      return {
        status: "success",
        data: resp.data.model,
      };
    } else {
      return {
        status: "fail",
        msg: resp.data.msg,
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: "fail",
        msg: error.response.data?.msg,
      };
    }
    return {
      status: "fail",
      msg: "something went wrong",
    };
  }
}

export function setGlobalLoading(enable = false) {
  store.dispatch({ type: GLOBAL_LOADING, payload: { enable } });
}

export const userEmailChange = {
  addNewEmailAddress: addNewEmailAddress,
  verifyNewEmailAddress: verifyNewEmailAddress,
  emailChangeConfirmationCode: emailChangeConfirmationCode,
};

export async function getPromoterDetails() {
  try {
    const resp = await userApi.post("/promoters/create", {}, getHeaders());
    if (resp.data.code !== 200)
      return;
    return resp.data.model;
  } catch (err) {
    return null;
  }
}