import React, { useState } from "react";
import { createMessage } from "../../../../Actions/MessageAction";
import {
  UpdatePasswordWithOld,
  userDelete,
  userEmailChange,
} from "../../../../Actions/UserActions";
import { DANGER } from "../../../../ActionType/MessageActionTypes";
import Input from "../../../../Awesome-ui/Input/Input";
import Loader from "../../../../Awesome-ui/Loader/Loader";
import SmallActionModel from "../../../../Awesome-ui/SmallActionModel/SmallActionModel";
import { CLICK } from "../../../../constants";
import { Text } from "@awesomesuite-frontend/awesome-nebula";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/error.svg";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  btnBase: {
    height: "fit-content",
    outline: "none",
    background: "#fff",
    border: "1px solid #CBD6E2",
    padding: "12px 24px",
    cursor: "pointer",
    borderRadius: 4,
  },
  dangerBtn: {
    border: "1px solid #F2545B",
    background: "#F2545B",
    color: "#fff",
  },
  blueBtn: {
    border: "1px solid #20D1F2",
    background: "#20D1F2",
    color: "#fff",
  },
  flex: {
    display: "flex",
    gap: 12,
    alignItems: "end",
  },
  warning: {
    display: "flex",
    alignItems: "center",
    background: "#FDF3E1",
    border: "1px solid #FAE0B5",
    padding: "12px 6px",
  },
  warningIcon: {
    height: 50,
    display: "flex",
    padding: "0px 5px",
    borderRight: "1px solid #FAE0B5",
    alignItems: "center",
    marginRight: 10,
  },
  disableBar: {
    width: "100%",
    margin: "0 auto",
  },
  disableBtn: {
    background: "#EAF0F6",
    color: "#3E5164",
  },
  checks: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    transformOrigin: "top",
    padding: "1rem 1rem 0 1rem",
    animation: "incSize 0.5s ease",
    marginBottom: "3rem",

    "@media (max-width: 1200px)": {
      padding: "0.75rem 0.75rem 0 0.75rem",
    },
    "@media (max-width: 768px)": {
      padding: "0.5rem 0.5rem 0 0.5rem",
      fontSize: "0.8rem",
    },
    "@media (max-width: 678px)": {
      padding: "0.5rem 0.5rem 0 0.5rem",
    },
  },
  check: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "50%",
    margin: "0.25rem 0",
  },
  radio: {
    marginRight: "0.5rem",
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    border: "2px solid #cbd6e2",
    padding: "0.2rem",

    "& div": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      background: "#999999",
    },
  },
  done: {
    borderColor: "#00bda5",
    background: "#00bda5",

    "& div": {
      background: "white",
    },
  },
}));

const ProfileSecurity = ({ saving, setsaving, user, track }) => {
  const classes = useStyles();
  const [oldPass, setOldPass] = React.useState("");
  const [newPassWord, setNewPassWord] = React.useState("");
  const [confirmPassWord, setConfirmPassWord] = React.useState("");
  const [openModel, setopenModel] = useState(false);
  const [changeEmailStep, setchangeEmailStep] = useState("NEW_EMAIL");
  const [openDeleteAcc, setopenDeleteAcc] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [isDeleteEnabled, setisDeleteEnabled] = useState(false);

  const [checks, addChecks] = useState(false);
  const [countCheck, updateCheck] = useState({
    len: false,
    upp: false,
    low: false,
    spe: false,
  });
  let checkData = (password) => {
    let len = false,
      upp = false,
      low = false,
      spe = false;
    if (password.length >= 8) len = true;
    for (let i = 0; i < password.length; i++) {
      if (password[i] >= "A" && password[i] <= "Z") upp = true;
      if (password[i] >= "a" && password[i] <= "z") low = true;
      if (
        !(
          (password[i] >= "a" && password[i] <= "z") ||
          (password[i] >= "A" && password[i] <= "Z") ||
          (password[i] >= "0" && password[i] <= "9")
        )
      )
        spe = true;
    }
    const ansCheck = {
      len,
      upp,
      low,
      spe,
    };
    return ansCheck;
  };

  async function handelUpdatePassword() {
    if (!newPassWord || !oldPass || !confirmPassWord)
      return createMessage(DANGER, "Fields Can not be empty !");

    track(CLICK, { title: "edit user data save." });
    setsaving(true);

    if (
      newPassWord.trim() === "" ||
      confirmPassWord.trim() === "" ||
      oldPass.trim() === ""
    ) {
      return createMessage(DANGER, "Fields Can not be empty !");
    }

    await UpdatePasswordWithOld(oldPass, newPassWord, confirmPassWord);
    setsaving(false);
    setConfirmPassWord("");
    setNewPassWord("");
    setOldPass("");
  }

  function handleEmailDelete(value) {
    if (value == user?.email_id) {
      setisDeleteEnabled(true);
    } else {
      setisDeleteEnabled(false);
    }
    setDeleteEmail(value);
  }

  return (
    <>
      <div className="profile-security" style={{ marginTop: "1.5rem" }}>
        <div className={classes.flex}>
          <Input
            label="Email Address"
            type="text"
            value={user?.email_id}
            changeHandler={(e) => {}}
            noEye
            disabled
          />
          <button
            className={`${classes.btnBase} ${classes.disableBtn}`}
            onClick={() => setopenModel(true)}
          >
            Edit email address
          </button>
          <SmallActionModel
            open={openModel}
            close={() => changeEmailStep === "NEW_EMAIL" && setopenModel(false)}
            maxWidth={500}
            noBorder
            title="Change your email address"
          >
            {changeEmailStep === "NEW_EMAIL" && (
              <EmailChangeOne
                classes={classes}
                setChangeEmailStep={setchangeEmailStep}
                setopenModel={setopenModel}
              />
            )}
            {changeEmailStep === "VERIFY_EMAIL" && (
              <EmailChangeTwo
                classes={classes}
                setChangeEmailStep={setchangeEmailStep}
              />
            )}
            {changeEmailStep === "CONFIRM_EMAIL" && (
              <EmailChangeThree
                classes={classes}
                setChangeEmailStep={setchangeEmailStep}
              />
            )}
          </SmallActionModel>
        </div>
      </div>
      <div className="profile-security" style={{ marginTop: "1rem" }}>
        <div className="profile-change-password">
          <div className="security-inputs">
            <Input
              label="old Password"
              type="password"
              value={oldPass}
              changeHandler={(e) => setOldPass(e)}
              style={{ margin: "0 auto", marginTop: "12px" }}
            />
          </div>
          <div>
            <Input
              label="New Password"
              type="password"
              value={newPassWord}
              changeHandler={(e) => {
                addChecks(true);
                setNewPassWord(e);
                let ans = checkData(e);
                updateCheck(ans);
              }}
              noEye
              style={{ margin: "0 auto", marginTop: "12px" }}
            />
          </div>
          <div>
            <Input
              label="Repeat Password"
              type="password"
              value={confirmPassWord}
              changeHandler={(e) => setConfirmPassWord(e)}
              noEye
              style={{ margin: "0 auto", marginTop: "12px" }}
            />
          </div>

          <div>
            {checks && (
              <div className={`${classes.checks} ${checks && "inc"}`}>
                <div className={classes.check}>
                  <div
                    className={`${classes.radio} ${
                      countCheck.len ? classes.done : ""
                    }`}
                  >
                    <div></div>
                  </div>
                  <div className="sentence">At least 8 characters long</div>
                </div>
                <div className={classes.check}>
                  <div
                    className={`${classes.radio} ${
                      countCheck.upp ? classes.done : ""
                    }`}
                  >
                    <div></div>
                  </div>
                  <div className="sentence">One UPPERCASE character</div>
                </div>
                <div className={classes.check}>
                  <div
                    className={`${classes.radio} ${
                      countCheck.low ? classes.done : ""
                    }`}
                  >
                    <div></div>
                  </div>
                  <div className="sentence">One lowercase character</div>
                </div>
                <div className={classes.check}>
                  <div
                    className={`${classes.radio} ${
                      countCheck.spe ? classes.done : ""
                    }`}
                  >
                    <div></div>
                  </div>
                  <div className="sentence">One Special Character</div>
                </div>
              </div>
            )}
          </div>

          <div className="security-save-btn" onClick={handelUpdatePassword}>
            Save Change
            {saving && (
              <span>
                {"  "}
                <Loader
                  height="14px"
                  width="14px"
                  border="2px solid #fff"
                  borderTop="2px solid #DFE3EB"
                  marginLeft="12px"
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="profile-security" style={{ marginTop: "1rem" }}>
        <div className={classes.disableBar}>
          <button
            className={`${classes.btnBase} ${classes.dangerBtn}`}
            onClick={() => setopenDeleteAcc(true)}
          >
            Deactivate user
          </button>
        </div>
        <SmallActionModel
          open={openDeleteAcc}
          close={() => setopenDeleteAcc(false)}
          maxWidth={500}
          color="danger"
          noBorder
          title="Permanently delete your account"
        >
          <Text varient="subText" margin={0} marginBottom={12}>
            You're about to permanently delete your account {user?.email_id}.
            You will no longer be able to log in to any AwesomeSutie account
            with this email address. This can't be undone.
          </Text>
          <Input
            label="Type your email address below to delete your user."
            type="text"
            value={deleteEmail}
            changeHandler={handleEmailDelete}
            noEye
          />
          <button
            className={`${classes.btnBase} ${
              isDeleteEnabled ? classes.dangerBtn : classes.disableBtn
            }`}
            onClick={userDelete}
          >
            Delete user
          </button>
          <button
            className={`${classes.btnBase}`}
            onClick={() => setopenDeleteAcc(false)}
          >
            Cancel
          </button>
        </SmallActionModel>
      </div>
    </>
  );
};

export default ProfileSecurity;

const EmailChangeOne = ({ classes, setopenModel, setChangeEmailStep }) => {
  const [isEnabled, setisEnabled] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const [msg, setmsg] = useState(null);

  async function handleEmailNew() {
    setisEnabled(false);
    let res = await userEmailChange.addNewEmailAddress(newEmail);
    if (res.status != "fail") {
      setChangeEmailStep("VERIFY_EMAIL");
    } else {
      setmsg(res.msg);
    }
    setisEnabled(true);
  }

  return (
    <>
      <div className={classes.warning}>
        <div className={classes.warningIcon}>
          <ErrorIcon />
        </div>
        <Text varient="subText" fontSize={12} margin={0} marginBottom={12}>
          After changing your email you will not be able to use your old email
          for login. and you will have to verify the email in order to change it
          with the OTP we sent to your new email address.
        </Text>
      </div>
      <Input
        label="Email address"
        type="text"
        value={newEmail}
        changeHandler={setNewEmail}
        noEye
      />
      <div>
        {msg && (
          <Text varient="subText" fontSize={12} margin={0} color="#F2545B">
            {msg}
          </Text>
        )}
      </div>
      <button
        className={`${classes.btnBase} ${
          isEnabled ? classes.blueBtn : classes.disableBtn
        }`}
        disabled={!isEnabled}
        onClick={handleEmailNew}
      >
        Confirm
      </button>
      <button
        className={`${classes.btnBase}`}
        onClick={() => setopenModel(false)}
        disabled={!isEnabled}
      >
        Cancel
      </button>
    </>
  );
};

const EmailChangeTwo = ({ classes, setChangeEmailStep }) => {
  const [otp, setOtp] = useState("");
  const [msg, setmsg] = useState(null);
  const [isEnabled, setisEnabled] = useState(true);

  async function handleEmailNew() {
    setisEnabled(false);
    let res = await userEmailChange.verifyNewEmailAddress(otp);
    if (res.status != "fail") {
      setChangeEmailStep("CONFIRM_EMAIL");
    } else {
      setmsg(res.msg);
    }
    setisEnabled(true);
  }

  return (
    <>
      <div className={classes.warning}>
        <div className={classes.warningIcon}>
          <ErrorIcon />
        </div>
        <Text varient="subText" fontSize={12} margin={0} marginBottom={12}>
          Verify New Email address. please check your new email address for the
          OTP.
        </Text>
      </div>
      <Input
        label="Enter email verifiction OTP "
        type="text"
        value={otp}
        changeHandler={setOtp}
        noEye
      />
      <div>
        {msg && (
          <Text varient="subText" fontSize={12} margin={0} color="#F2545B">
            {msg}
          </Text>
        )}
      </div>
      <button
        className={`${classes.btnBase} ${
          isEnabled ? classes.blueBtn : classes.disableBtn
        }`}
        onClick={handleEmailNew}
        disabled={!isEnabled}
      >
        Confirm
      </button>
    </>
  );
};

const EmailChangeThree = ({ classes }) => {
  const [otp, setOtp] = useState("");
  const [msg, setmsg] = useState(null);
  const [isEnabled, setisEnabled] = useState(true);

  async function handleEmailNew() {
    setisEnabled(false);
    const res = await userEmailChange.emailChangeConfirmationCode(otp);
    if (res.status != "fail") {
      //logout
    } else {
      setmsg(res.msg);
    }
    setisEnabled(true);
  }

  return (
    <>
      <div className={classes.warning}>
        <div className={classes.warningIcon}>
          <ErrorIcon />
        </div>
        <Text varient="subText" fontSize={12} margin={0} marginBottom={12}>
          You will recive an OTP on old email. On confirmation, you will be
          logged out immediately. and then you can login using new email
          address.
        </Text>
      </div>
      <Input
        label="Enter OTP for old email"
        type="password"
        value={otp}
        changeHandler={setOtp}
        noEye
      />
      <div>
        {msg && (
          <Text varient="subText" fontSize={12} margin={0} color="#F2545B">
            {msg}
          </Text>
        )}
      </div>
      <button
        className={`${classes.btnBase} ${
          isEnabled ? classes.blueBtn : classes.disableBtn
        }`}
        onClick={handleEmailNew}
        disabled={!isEnabled}
      >
        Confirm
      </button>
    </>
  );
};
