import React from "react";
import "./Widget.scss";

/**
 *
 * @param {*} param0
 */
const Widget = ({ title, height, width, gutters, style, ...props }) => {
  return (
    <div
      className={`main-widget ${gutters && "gutter-" + gutters}`}
      style={{ height: height, width: width, ...style }}
    >
    {props.children}
    </div>
  );
};

export default Widget;
