import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import InputBox from "../../Awesome-ui/InputBox";
import Loader from "../../Awesome-ui/Loader/Loader";

import EmailIcon from "../../assets/LoginAndSignUpIcon/email.svg";
import PhoneIcon from "../../assets/LoginAndSignUpIcon/mobile.svg";
import PasswordIcon from "../../assets/LoginAndSignUpIcon/password.svg";
import UserIcon from "../../assets/LoginAndSignUpIcon/user.svg";
import OrgIcon from "../../assets/LoginAndSignUpIcon/organisation.svg";
import { ReactComponent as GoogleIcon } from "../../assets/LoginIcon/google.svg";
import { signWithGoogle } from "../../configuration/firebase";
import { createMessage } from "../../Actions/MessageAction";
import { DANGER } from "../../ActionType/MessageActionTypes";
import { handleCoupon } from "../../Actions/transactionAction";

let codeMapping = {
  appsumo: true,
  dealmirror: true,
  dealfuel: true,
};
let codeMappingName = {
  appsumo: "Appsumo",
  dealmirror: "Dealmirror",
  dealfuel: "DealFuel",
};

const Form = ({
  loading,
  btnColor,
  Icon,
  state,
  dispatcher,
  oAuth,
  submitHandler,
  oAuthSignUp,
  error,
  signupVoucher,
  couponCode,
  setCouponCode,
}) => {
  const { first, last, email, company, phone, password } = state;
  const [checks, addChecks] = useState(false);
  const [curActive, changeActive] = useState("");
  const [couponCodeSuccess, setCouponCodeSuccess] = useState(false);
  const history = useHistory();
  const { code } = useParams();

  function inputHandler(name, value) {
    dispatcher({ type: "UPDATE_DATA", payload: { name, value } });
  }

  const [countCheck, updateCheck] = useState({
    len: false,
    upp: false,
    low: false,
    spe: false,
  });
  if (signupVoucher) {
    if (!code || !codeMapping[code?.toLowerCase()]) {
      history.push("/signup");
    }
  }
  let check = (password) => {
    let len = false,
      upp = false,
      low = false,
      spe = false;
    if (password.length >= 8) len = true;
    for (let i = 0; i < password.length; i++) {
      if (password[i] >= "A" && password[i] <= "Z") upp = true;
      if (password[i] >= "a" && password[i] <= "z") low = true;
      if (
        !(
          (password[i] >= "a" && password[i] <= "z") ||
          (password[i] >= "A" && password[i] <= "Z") ||
          (password[i] >= "0" && password[i] <= "9")
        )
      )
        spe = true;
    }
    const ansCheck = {
      len,
      upp,
      low,
      spe,
    };
    return ansCheck;
  };

  function checkPass(pass) {
    let obj = check(pass || "");
    let isValid = true;

    Object.values(obj).forEach((i) => {
      if (!i) isValid = false;
    });
    return isValid;
  }

  /**
   * Submit form form here
   */
  function submitForm() {
    if (
      !(countCheck.len && countCheck.spe && countCheck.low && countCheck.upp)
    ) {
      return createMessage(DANGER, "Please enter the password.");
    }
    if (!company) {
      return createMessage(DANGER, "Please enter the company name.");
    }
    if (phone?.length < 6 || phone?.length > 14) {
      return createMessage(
        DANGER,
        "Phone number cannot be less then 6 digit and more then 14 digit."
      );
    }
    if (couponCode && couponCode.length > 0 && !couponCodeSuccess) {
      return createMessage(DANGER, "Entered coupon is not verified.");
    }
    submitHandler();
  }

  const handleCouponCode = async (e) => {
    e.preventDefault();
    let a = await handleCoupon(couponCode);
    if (a) {
      setCouponCodeSuccess(true);
    }
  };

  return (
    <div className="formBox">
      {Icon && (
        <div className="iconContainer">
          <div className="icon">
            <img src={Icon} alt="Awesome app" />
          </div>
        </div>
      )}
      <form
        className="formSpace"
        onKeyDown={(e) => {
          if (e.key === "Enter") submitHandler();
        }}
        onSubmit={submitHandler}
      >
        {oAuth && (
          <div className="oAuto-email">
            <b>{email}</b> is your email.
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        {!oAuth && (
          <div className="name">
            <InputBox
              placeHolder="First Name*"
              complete="off"
              type="text"
              src={UserIcon}
              name="first"
              value={first}
              changeFunc={(val) => inputHandler("first", val)}
              ind={0}
              onFocus={() => changeActive(0)}
              onBlur={() => changeActive(null)}
              focus={curActive}
              req={true}
            />
            <InputBox
              placeHolder="Last Name*"
              complete="off"
              type="text"
              name="last"
              value={last}
              src={UserIcon}
              changeFunc={(val) => inputHandler("last", val)}
              ind={1}
              onFocus={() => changeActive(1)}
              onBlur={() => changeActive(null)}
              focus={curActive}
              req={true}
            />
          </div>
        )}
        {!oAuth && (
          <InputBox
            placeHolder="Email*"
            complete="off"
            type="email"
            name="email"
            value={email}
            src={EmailIcon}
            changeFunc={(val) => inputHandler("email", val)}
            ind={2}
            onFocus={() => changeActive(2)}
            onBlur={() => changeActive(null)}
            focus={curActive}
            req={true}
            style={{ marginTop: "8px" }}
          />
        )}
        <InputBox
          placeHolder="Company*"
          complete="off"
          type="text"
          name="company"
          value={company}
          src={OrgIcon}
          ind={3}
          onFocus={() => changeActive(3)}
          onBlur={() => changeActive(null)}
          focus={curActive}
          changeFunc={(val) => inputHandler("company", val)}
          req={true}
          style={{ marginTop: "8px" }}
        />
        <InputBox
          placeHolder="Phone No.*"
          complete="off"
          type="number"
          name="telephone"
          value={phone}
          src={PhoneIcon}
          changeFunc={(val) => inputHandler("phone", val)}
          ind={4}
          onFocus={() => changeActive(4)}
          onBlur={() => changeActive(null)}
          focus={curActive}
          req={true}
          style={{ marginTop: "8px" }}
        />
        <InputBox
          placeHolder="Password*"
          complete="off"
          type="password"
          name="password"
          value={password}
          src={PasswordIcon}
          ind={5}
          onFocus={() => changeActive(5)}
          onBlur={() => changeActive(null)}
          focus={curActive}
          brdr={
            checks
              ? countCheck.len &&
                countCheck.upp &&
                countCheck.low &&
                countCheck.spe
                ? "rgba(128, 128, 128, 0.35)"
                : "red"
              : null
          }
          changeFunc={(val) => {
            addChecks(true);
            inputHandler("password", val);
            let ans = check(val);
            updateCheck(ans);
          }}
          req={true}
          style={{ marginTop: "8px" }}
        />
        {checks && (
          <div className={`checks ${checks && "inc"}`}>
            <div className="check">
              <div className={`radio ${countCheck.len ? "done" : ""}`}>
                <div></div>
              </div>
              <div className="sentence">At least 8 characters long</div>
            </div>
            <div className="check">
              <div className={`radio ${countCheck.upp ? "done" : ""}`}>
                <div></div>
              </div>
              <div className="sentence">One UPPERCASE character</div>
            </div>
            <div className="check">
              <div className={`radio ${countCheck.low ? "done" : ""}`}>
                <div></div>
              </div>
              <div className="sentence">One lowercase character</div>
            </div>
            <div className="check">
              <div className={`radio ${countCheck.spe ? "done" : ""}`}>
                <div></div>
              </div>
              <div className="sentence">One Special Character</div>
            </div>
          </div>
        )}
        <div
          style={{
            display: `${
              signupVoucher ? `${couponCodeSuccess ? "none" : "flex"}` : "none"
            }`,
            marginTop: "8px",
            justifyContent: "space-between",
          }}
        >
          <div className="couponInputClass" style={{ width: "70%" }}>
            <input
              placeHolder={`Your ${
                signupVoucher ? codeMappingName[code?.toLowerCase()] : ""
              } Code`}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          <div className="couponButtonClass">
            <button onClick={handleCouponCode}>Redeem</button>
          </div>
        </div>
        <div
          style={{
            display: `${
              signupVoucher ? `${couponCodeSuccess ? "flex" : "none"}` : "none"
            }`,
            marginTop: "8px",
            background: "#D4FBEC",
            padding: 10,
            textAlign: "center",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#2AC689",
            borderRadius: "6px",
          }}
        >
          <div>
            🎉 Yay! you've successfully redeemed your{" "}
            {signupVoucher ? codeMappingName[code?.toLowerCase()] : ""} deal.
          </div>
        </div>
        <div
          className="button"
          tabIndex="0"
          type="submit"
          onClick={() => checkPass(state?.password) && submitForm(countCheck)}
          style={{
            backgroundColor: checkPass(state?.password) ? btnColor : "#DFE3EB",
          }}
        >
          SIGN UP FOR FREE
          {loading && (
            <span>
              {"  "}
              <Loader
                h="1rem"
                w="1rem"
                b="2px"
                clr="white"
                bk="rgba(255,255,255,0.35)"
                t="1s"
              />
            </span>
          )}
        </div>
        <p>
          By creating an account, you’re opting-in to receive marketing and
          product updates by email. You can always unsubscribe, any time. By
          creating an account you agree to our{" "}
          <a
            href="https://awesomesuite.com/terms"
            target="_blank"
            className="green-clr"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            href="https://awesomesuite.com/privacy"
            target="_blank"
            className="green-clr"
          >
            Privacy Policy
          </a>
        </p>

        {!oAuth && (
          <div onClick={() => signWithGoogle(oAuthSignUp)}>
            <div
              tabindex="0"
              role="button"
              aria-labelledby="button-label"
              className="google-btn"
            >
              <div className="google-btn-sps"></div>
              <div className="google-btn-spa">
                <div className="google-btn-spb">
                  <div className="google-btn-img">
                    <GoogleIcon />
                  </div>
                </div>
                <span className="google-btn-txt">Continue with Google</span>
                <span style={{ display: "none" }} id="button-label">
                  Continue with Google
                </span>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Form;
