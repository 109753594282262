import { store } from "../store";
import axios from "axios";
import {
  LOAD_CUSTOMER_DETAILS,
  LOAD_CUSTOMER_DETAILS_SUCCESS,
  LOAD_CUSTOMER_DETAILS_FAILED,
  LOAD_SUBSCRIPTION_DETAILS,
  LOAD_SUBSCRIPTION_DETAILS_FAILED,
  LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
  LOAD_TRANSACTION_DETAILS,
  LOAD_TRANSACTION_FAILED,
  LOAD_TRANSACTION_SUCCESS,
  LOAD_PLAN_DETAILS_FAILED,
  LOAD_PLAN_DETAILS_LOADING,
  LOAD_PLAN_DETAILS_SUCCESS,
  LOAD_USER_INVOICES_LOADING,
  LOAD_USER_INVOICES_SUCCESS,
  LOAD_USER_INVOICES_FAILED,
  REMOVE_CARD,
} from "../ActionType/transactionActionTypes";
import { DANGER, SUCCESS } from "../ActionType/MessageActionTypes";
import { createMessage } from "./MessageAction";
import { Request } from "../helper/RequestHandler";
import { APIS, TRANSACTION_API, paymentApi } from "../api/axios";
import { getHeaders } from "./UserActions";

const { dispatch, getState } = store;

// const api = axios.create({
//   baseURL: "https://x51g83npcb.execute-api.ap-south-1.amazonaws.com/default",
// });

const subscriptionApi = axios.create({
  baseURL: APIS.SUBSCRIPTION_API,
});

export const getCustomerDetails = async () => {
  const { token, globalLoading } = store.getState().UserReducer;
  if (globalLoading) return;

  dispatch({ type: LOAD_CUSTOMER_DETAILS });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const request = new Request(
    `${TRANSACTION_API}/chargebee`,
    "get",
    headers,
    `chargeBee-`
  );

  try {
    const response = await request.makeRequest();

    if (response?.code === 200) {
      dispatch({
        type: LOAD_CUSTOMER_DETAILS_SUCCESS,
        payload: {
          ...response.model,
        },
      });
    } else {
      dispatch({ type: LOAD_CUSTOMER_DETAILS_FAILED });
    }
  } catch (error) {
    if (axios.isCancel(error)) return;
    if (error.response) {
      dispatch({ type: LOAD_CUSTOMER_DETAILS_FAILED });
      createMessage(DANGER, error.response.data);
    } else {
      createMessage(DANGER, "Failed to Load customer details.");
    }
  }
};

export const getUserTransactions = async () => {
  dispatch({ type: LOAD_TRANSACTION_DETAILS });

  if (getState().UserReducer.user) {
    const userId = getState().UserReducer.user.id;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    };

    const request = new Request(
      `${TRANSACTION_API}/transaction?userId=${userId}`,
      "get",
      headers,
      `trans-${userId}`
    );
    let count = 0;

    try {
      if (count > 5) return;
      count++;
      const response = await request.makeRequest();

      if (response.code === 200) {
        dispatch({
          type: LOAD_TRANSACTION_SUCCESS,
          payload: {
            transaction: response.model?.sort(
              (a, b) =>
                new Date(b.updatedOn).getTime() -
                new Date(a.updatedOn).getTime()
            ),
          },
        });
      } else dispatch({ type: LOAD_TRANSACTION_FAILED });
    } catch (error) {
      if (axios.isCancel(error)) return;
      if (error.response) {
        createMessage(DANGER, error.response.data);
      } else {
        createMessage(DANGER, "Failed to Load Transaction Data.");
      }
    }
  }
};

export const getSubscribedPlan = async () => {
  const { token, globalLoading } = store.getState().UserReducer;
  if (globalLoading) return;

  dispatch({ type: LOAD_SUBSCRIPTION_DETAILS });

  try {
    const subscription = await subscriptionApi.get(`/subscription`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (subscription.data.model) {
      dispatch({
        type: LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
        payload: {
          subscription: subscription.data.model,
        },
      });
      return subscription.data.model;
    } else {
      dispatch({ type: LOAD_SUBSCRIPTION_DETAILS_FAILED });
    }
  } catch (error) {
    dispatch({ type: LOAD_SUBSCRIPTION_DETAILS_FAILED });
    if (error.response) {
      createMessage(DANGER, error.response.data);
    } else {
      createMessage(DANGER, "Failed to Load the Subscription Data.");
    }
  }
};

export const getPlans = async (product = 1) => {
  if (!product) return;
  const { token, globalLoading } = store.getState().UserReducer;
  if (globalLoading) return;
  dispatch({ type: LOAD_PLAN_DETAILS_LOADING });

  try {
    const plans = await subscriptionApi.get(
      `/v2/subscription?product_id=${product}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (plans.data.code === 200 && plans.data.model) {
      // group data by plan name
      const data = {};

      plans.data.model.forEach((element) => {
        if (!data[element.title]) {
          data[element.title] = [];
        }

        data[element.title].push(element);
      });

      dispatch({
        type: LOAD_PLAN_DETAILS_SUCCESS,
        payload: {
          productId: product,
          planPrice: data,
          plans: plans.data.model,
        },
      });
      return plans;
    } else {
      dispatch({ type: LOAD_PLAN_DETAILS_FAILED });
    }
  } catch (error) {
    if (error.response) {
      createMessage(DANGER, error.response.data?.message);
    } else {
      createMessage(DANGER, "Failed to Load Plans.");
    }
  }
};

export const getUserInvoices = async () => {
  dispatch({ type: LOAD_USER_INVOICES_LOADING });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  };

  const request = new Request(
    `${TRANSACTION_API}/invoice`,
    "get",
    headers,
    `invoices`
  );
  let count = 0;

  try {
    if (count > 5) return;
    count++;
    const response = await request.makeRequest();

    if (response.code === 200) {
      const data = response.model.chargebee;

      dispatch({
        type: LOAD_USER_INVOICES_SUCCESS,
        payload: {
          invoices: data,
        },
      });
    } else dispatch({ type: LOAD_USER_INVOICES_FAILED });
  } catch (error) {
    if (axios.isCancel(error)) return;
    if (error.response) {
      createMessage(DANGER, error.response.data?.message);
    } else {
      createMessage(DANGER, "Failed to Load invoice Data.");
    }
  }
};

export const getUserInvoiceDoc = async (id) => {
  if (!id) return;

  try {
    const response = await axios.get(
      `${TRANSACTION_API}/invoice?type=chargebee&id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );

    if (response.data.code === 200) {
      const url = response.data.model.download_url;
      window.open(url, "blank");
    }
  } catch (error) {
    if (error.response) {
      createMessage(DANGER, error.response.data?.message);
    } else {
      createMessage(DANGER, "Can not get invoice.");
    }
  }
};

export const getPlansByProductId = async (id) => {
  try {
    let token = getHeaders();
    const response = await subscriptionApi.get(`plan?product_id=${id}`, token);

    if (response.data.code === 200) {
      return response.data.model;
    }
    return null;
  } catch (error) {}
};

export const getPlanDetailsById = async (id, product_id) => {
  try {
    let token = getHeaders();
    const response = await subscriptionApi.get(
      `/v2/subscription?id=${id}&product_id=${product_id}`,
      token
    );

    if (response.data.code === 200) {
      return response.data.model;
    }
    return null;
  } catch (error) {}
};

export const deleteCard = async () => {
  try {
    let headers = getHeaders();

    const card = await axios.delete(`${TRANSACTION_API}/chargebee`, headers);

    if (card.data.code === 200) {
      dispatch({ type: REMOVE_CARD });
      return createMessage(SUCCESS, "Successfully removed card.");
    }
    createMessage(DANGER, card.data.msg);
  } catch (error) {
    createMessage(DANGER, "Something went wrong while removing card.");
  }
};

export const subscribeFreePlan = async (productId, orgId, userId, voucher) => {
  try {
    let data = {
      productId: productId,
      orgId: orgId,
      userId: userId,
      planId: 1,
      purchaseId: "",
      voucherCode: voucher,
    };

    let headers = getHeaders();

    const subscription = await subscriptionApi.post(
      `/subscription`,
      data,
      headers
    );

    if (subscription.data.code === 200) {
      getSubscribedPlan();
      return true;
    } else {
      createMessage(DANGER, subscription.data.msg);
    }
  } catch (error) {
    createMessage(DANGER, "Something went wrong while selecting the plan !");
  }
};

/**
 * /subscription ->put
 * {referenceNextPlanId:1}
 * @param {*} productId
 * @param {*} orgId
 * @param {*} userId
 * @param {*} planId
 */
export const subscribeNextPlan = async (productId, orgId, userId, planId) => {
  try {
    let data = {
      productId: productId,
      organisationId: orgId,
      userId: userId,
      referenceNextPlanId: planId,
    };

    let headers = getHeaders();

    const subscription = await subscriptionApi.put(
      `/subscription`,
      data,
      headers
    );

    if (subscription.data.code === 200) {
      createMessage(SUCCESS, "Successfully subscribed next plan.");
      getSubscribedPlan();
      return true;
    } else {
      createMessage(DANGER, subscription.data.msg);
      return false;
    }
  } catch (error) {
    createMessage(DANGER, "Something went wrong while selecting the plan !");
    return false;
  }
};

/**
 * get coupon details
 *
 * @param {*} coupon
 * @param {*} planId
 * @returns
 * ```js
 * {
 *    "couponCode": "TESTCP51",
 *    "totalAmount": 1900,
 *    "discount": 0,
 *    "bestPriceAmount": 0
 * }
 *
 * ```
 */
export const getCouponDetails = async (coupon, planId) => {
  try {
    let options = getHeaders();
    options.params = {
      coupon_code: coupon,
      plan_id: planId,
    };
    const details = await axios.get(
      `${TRANSACTION_API}/chargebee/coupon`,
      options
    );

    if (details.data.code === 200) {
      return details.data.model;
    }
    createMessage(DANGER, details.data.model?.message || details.data.msg);
    return null;
  } catch (error) {
    createMessage(DANGER, "Could not apply coupon !");
    return null;
  }
};

export async function subscriptionAction(action, data) {
  try {
    let params = {
      productId: data.productId,
      org_id: data.orgId,
      new_plan_id: data.newPlanId,
      action: action,
      quantityPlan: data.quantityPlan,
    };

    let headers = getHeaders(params);

    const resp = await subscriptionApi.get(`/subscription/actions`, headers);

    if (resp.data.code !== 200) {
      createMessage(DANGER, resp.data.msg);
      return null;
    }
    // if (!resp.data.model.verifiedPlan) {
    //   createMessage(DANGER, resp.data.model.errorMessage);
    // }
    return resp.data.model;
  } catch (error) {
    createMessage(DANGER, "Could not get best amount !");
    return null;
  }
}

export async function getBestPrice(data) {
  try {
    let body = {
      productId: data.productId,
      orgId: data.organisationId,
      planId: data.planId,
      couponCode: data.code,
      isCreditAmountApply: data.creditApply,
      isSubscriptionEnable: true,
      quantityPlan: data.quantityPlan,
      addonPlans: data.addonPlans,
    };

    let headers = getHeaders();

    const resp = await paymentApi.post(`/best-price`, body, headers);

    if (resp.data.code !== 200) {
      createMessage(DANGER, resp.data.msg);
      return null;
    }

    if (resp.data.model.addOnPlans) {
      let obj = {};
      resp.data.model.addOnPlans.map((i) => (obj[i.addOnPlanId] = i));
      resp.data.model.addOnPlans = Object.values(obj);
    }
    return resp.data.model;
  } catch (error) {
    createMessage(DANGER, "Could not get best amount !");
    return null;
  }
}

export async function getCreditAmount() {
  try {
    let headers = getHeaders();

    const resp = await paymentApi.get(`/credits`, headers);

    if (resp.data.code !== 200) {
      createMessage(DANGER, resp.data.msg);
      return null;
    }
    return resp.data.model;
  } catch (error) {
    createMessage(DANGER, "Could not get best amount !");
    return null;
  }
}

export const handleCoupon = async (couponCode) => {
  try {
    if (!couponCode) return;
    const res = await subscriptionApi.get(`/promotional?code=${couponCode}`);
    if (res.data.code === 200) {
      return res.data.model;
    } else {
      createMessage(DANGER, res.data.msg);
      return null;
    }
  } catch (err) {
    createMessage(DANGER, "Check your connection");
    return false;
  }
};
