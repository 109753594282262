import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Chip from "../../../Awesome-ui/Chip/Chip";
import ProfileWithName from "../../../Awesome-ui/ProfileWithName/ProfileWithName";
import Social from "../../../assets/serivceIcons/social/social-icon-1.svg";

import Table from "../../../Awesome-ui/Table/Table";
import TableRow from "../../../Awesome-ui/TableRow/TableRow";
import TableHeader from "../../../Awesome-ui/TableHeader/TableHeader";
import TableCell from "../../../Awesome-ui/TableCell/TableCell";
import { useHistory } from "react-router-dom";
import { servicesMapping } from "../../../Data/productsMapping";
import { useBasicUtils } from "../../../hooks/BasicUtility/basicUtils";

import { getPlanDetailsById } from "../../../Actions/transactionAction";
import { PageLoader } from "@awesomesuite-frontend/awesome-nebula";

const getDate = (date) => {
  const d = new Date(date);

  return `${d.getDate() + 1}-${d.getMonth() + 1}-${d.getFullYear()}`;
};

const Plans = ({ subscription, subscriptionMapping = {} }) => {
  const { namedDate } = useBasicUtils();

  useEffect(() => {}, [subscription]);

  return (
    <div className="product-box">
      <Table border>
        <TableRow style={{ boxShadow: "0 2px 4px #DFE3EB" }}>
          <TableHeader width="10%" padding="18px">
            Products and apps
          </TableHeader>
          <TableHeader padding="18px">Plan</TableHeader>
          <TableHeader padding="18px" align="center">
            Users
          </TableHeader>
          <TableHeader padding="18px" align="center">
            Price
          </TableHeader>
          <TableHeader width="5%" padding="18px" align="center">
            Billing frequency
          </TableHeader>
          <TableHeader padding="18px">Next bill date</TableHeader>
          {/* <TableHeader padding="18px">Next Plan Details</TableHeader> */}
          <TableHeader padding="18px"></TableHeader>
        </TableRow>
        {subscription &&
          subscription.map((item, key) => {
            const data = Object.values(servicesMapping).find(
              (a) => a.id === item.productId
            );
            return (
              <SubscriptionTableRow
                {...data}
                namedDate={namedDate}
                subscription={
                  subscriptionMapping && subscriptionMapping[item.productId]
                }
                key={key}
              />
            );
          })}
      </Table>
    </div>
  );
};

const mapStateToProps = ({ transactionReducer }) => ({
  subscription: transactionReducer.subscription,
  subscriptionMapping: transactionReducer.subscriptionMapping,
});

export default connect(mapStateToProps)(Plans);

const SubscriptionTableRow = ({
  id,
  IconSmall,
  name,
  subscription,
  plans,
  namedDate,
}) => {
  const history = useHistory();
  const [loading, setloading] = useState(true);
  const [status, setStatus] = React.useState(false);
  const [currentPlan, setcurrentPlan] = useState(null);

  React.useEffect(() => {
    if (!subscription || currentPlan) return;

    async function init() {
      let details = await getPlanDetailsById(
        subscription.planId,
        subscription.productId
      );
      setloading(false);
      setStatus(true);
      setcurrentPlan(details);
    }
    init();
  }, [subscription]);

  return (
    <TableRow>
      <TableCell
        align="center"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ProfileWithName
          imgHeight="40px"
          imgWidth="40px"
          fontSize="32"
          image={IconSmall}
          title={name}
        />
        <div>
          {loading ? (
            <PageLoader
              size="10px"
              circleColor="	#0096FF"
              interval="1750ms"
              style={{ marginLeft: "3rem" }}
            />
          ) : (
            <Chip
              label={currentPlan ? "Active" : "Inactive"}
              color={status ? "primary" : "danger"}
              size="medium"
            />
          )}
        </div>
      </TableCell>
      <TableCell align="center">{currentPlan?.title || "-"}</TableCell>
      <TableCell align="center">
        {subscription && subscription?.numberOfUser}
      </TableCell>
      <TableCell align="center">
        {currentPlan?.amount} &nbsp; {currentPlan?.currency || "-"}
      </TableCell>
      <TableCell align="center">{currentPlan?.planValidity || "-"}</TableCell>
      <TableCell align="center">
        {(subscription && namedDate(subscription?.endDate)) || "-"}
      </TableCell>
      {/* <TableCell align="center">
        <b>{nextPlan?.title || "-"}</b> <br />
        {nextPlan && namedDate(subscription.endDate)}
      </TableCell> */}
      <TableCell>
        <p
          className="product-action"
          onClick={() => {
            history.push(`/billing?service=${name?.toLowerCase()}`);
          }}
        >
          Manage
        </p>
      </TableCell>
    </TableRow>
  );
};
