import React from "react";
import "./TableCell.scss";

const TableCell = ({ align, width, padding, colSpan, style, ...props }) => {
  return (
    <td
      className="tableCell"
      colSpan={colSpan}
      align={align}
      style={{ width: width, padding, ...style }}
    >
      {props.children}
    </td>
  );
};

export default TableCell;
