import React from "react";

import ProfileWithName from "../../../../Awesome-ui/ProfileWithName/ProfileWithName";
import Avatar from "../../../../Awesome-ui/Avatar/Avatar";

const index = ({ users, orgUser, handlerUserChange, activeUser }) => {
  return (
    <div className="profile-sidebar">
      <div className={`sidebar-menu ${orgUser && orgUser.id === activeUser && "selected"}`} onClick={() => {handlerUserChange(orgUser.id)}}>
      </div>
      { users &&
        users.map((user) => (
          <div
            className={`sidebar-menu ${user.id === activeUser && "selected"}`}
            key={user.id}
            onClick={() => {handlerUserChange(user.id)}}
          >
            <ProfileWithName
              image={
                user.picture && user.picture.trim() !== "" ? user.picture : null
              }
              Avatar={
                (!user.picture || user.picture.trim() === "") && (
                  <Avatar user={user.first_name || user.email_id} width="40px" height="40px" />
                )
              }
              title={user.first_name}
              subText={user.email_id}
              imgVarient="round"
              imgHeight="40px"
              imgWidth="40px"
              fontSize={13}

              style={{ margin: 0, padding: "8px 6px" }}
              subTextStyle={{ marginTop: "6px" }}
              maxLength={24}
            />
          </div>
        ))}
    </div>
  );
};

export default index;
