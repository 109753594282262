import React, { useEffect } from "react";
import "./BillingDetails.scss";

import InputBox from "../../../Awesome-ui/NormInput/index";
import { connect } from "react-redux";
import { getLinkToService } from "../../../Data/productsMapping";

const BillingDetails = ({ card, token, userDetails }) => {
  const [company, setCompany] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");

  useEffect(() => {
    if (userDetails) {
      setCompany(userDetails.billing_address?.first_name);
      setCountry(userDetails.billing_address.country);
      setCity(userDetails.billing_address.city);
      setState(userDetails.billing_address.state);
      setAddress(userDetails.billing_address.line1);
    }
  }, [card, userDetails]);

  function handlerAddCardButton() {
    if (token) {
      const checkouturl = getLinkToService("checkout");
      window.open(
        `${checkouturl}/details/?token=${token}&redirect=${window.location.origin}/subscription/billing-details`
      );
    }
  }

  return (
    <div className="Billing-details-page">
      <div className="billing-form">
        <div className="subscription-title">
          <h5>Billing Details</h5>
        </div>
        <div className="billing-form-fields">
          <div className="billing-input">
            <span>Company name*</span>
            <InputBox
              placeHolder=""
              name="company"
              type="text"
              value={company}
              ind={0}
              focus={""}
              disable
              changeFunc={(val) => {}}
              complete="on"
              reverse
              req={false}
              style={{
                height: "3rem",
                padding: "12px 16px",
              }}
            />
          </div>
          <div className="billing-input">
            <span>Country</span>
            <InputBox
              placeHolder=""
              name="country"
              type="text"
              value={country}
              ind={0}
              focus={""}
              disable
              changeFunc={(val) => {}}
              complete="on"
              reverse
              req={false}
              style={{
                height: "3rem",
                padding: "12px 16px",
              }}
            />
          </div>
          <div className="billing-input">
            <span>Address*</span>
            <InputBox
              placeHolder=""
              name="Address"
              type="text"
              value={address}
              ind={0}
              focus={""}
              disable
              changeFunc={(val) => {}}
              complete="on"
              reverse
              req={false}
              style={{
                height: "3rem",
                padding: "12px 16px",
              }}
            />
          </div>
          <div className="billing-input">
            <span>City/Town/Village*</span>
            <InputBox
              placeHolder=""
              name="search"
              type="text"
              value={city}
              ind={0}
              focus={""}
              disable
              changeFunc={(val) => {}}
              complete="on"
              reverse
              req={false}
              style={{
                height: "3rem",
                padding: "12px 16px",
              }}
            />
          </div>
          <div className="billing-input">
            <span>State/Province/Region</span>
            <InputBox
              placeHolder=""
              name="state"
              type="text"
              value={state}
              ind={0}
              focus={""}
              disable
              changeFunc={(val) => {}}
              complete="on"
              reverse
              req={false}
              style={{
                height: "3rem",
                padding: "12px 16px",
              }}
            />
          </div>
          <div className="billing-button" onClick={handlerAddCardButton}>
            <span>Edit</span>
          </div>
        </div>
      </div>
      <div className="billing-cards"></div>
    </div>
  );
};

const mapStateToProps = ({ transactionReducer, UserReducer }) => ({
  card: transactionReducer.card,
  userDetails: transactionReducer.userDetails,
  token: UserReducer.token,
});

export default connect(mapStateToProps)(BillingDetails);
