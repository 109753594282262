import React from "react";
import "./GetOtp.scss";
import Input from "../../../Awesome-ui/Input/Input";
import Button from "../../../Awesome-ui/Button/Button";
import Template from "../components/Template";
import { forgotpassAddEmail } from "../../../Actions/UserActions";
import { useHistory } from "react-router-dom";
import reset from "../../../assets/LoginAndSignUpIcon/Passwordreset.svg";

const ForgotPassword = ({ track, ...props }) => {
  const history = useHistory();
  const [email, setEmail] = React.useState("");

  async function handlerRequest() {
    if (
      email !== "" &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      track("RESET_PASSWORD", { title: `user reset password`, email: email });
      const isResetingPassword = await forgotpassAddEmail(email);
      let search =
        window.location.search.length > 1
          ? window.location.search.slice(1)
          : "";
      if (isResetingPassword)
        history.push(`/forgotpass/confirm?email=${email}&${search}`);
    }
  }

  return (
    <Template>
      <div className="forgot-pass-main-form">
        <div>
          <div className="forgot-pass-title">
            <h1>
              Reset your password
              <span
                className="img-emoji"
                role="img"
                aria-label="reset-password"
              >
                {" "}
                🔑
              </span>
            </h1>
            <p>
              Please enter the email address you'd like your password reset
              information sent to.
            </p>
          </div>
          <div>
            <Input
              style={{ width: "460px", marginBottom: "1.2rem" }}
              id="email"
              value={email}
              placeHolder="your email"
              label="Email address"
              changeHandler={(val) => {
                setEmail(val);
              }}
            />
          </div>
          <Button
            color="primary"
            disabled={
              email === "" ||
              !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
              )
            }
            onClick={handlerRequest}
            varient="large"
            round
            style={{
              width: "100%",
              height: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>Reset your password</span>
          </Button>
        </div>
      </div>
      <div className="side-image">
        <img src={reset} alt="reset" />
      </div>
    </Template>
  );
};

export default ForgotPassword;
