import React from "react";
import "./HelpSupportContainer.scss";

/**
 * User Help Supporting Items Container Used In HelpSideNavbar Component
 *
 * Help Suppoert Container
 *
 */

const HelpSupportContainer = () => {
  return (
    <div className="help-support-container">
      <div>
        <h6>Support</h6>
        <div className="supports">
          {supports.map((support, index) => (
            <div key={index}>
              <div className="icon"></div>
              <p>{support}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelpSupportContainer;

const supports = ["Contact Support", "Contact Support", "Submit a bug"];
