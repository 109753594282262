import React from "react";
import "./ProfileWithName.scss";

const ProfileWithName = ({
  title,
  image,
  subText,
  imgWidth,
  imgHeight,
  imgVarient,
  style,
  fontSize = 16,
  textStyle,
  imgStyle,
  subTextStyle,
  maxLength,
  Avatar,
  ...porps
}) => {
  return (
    <div className="profile-with-name" style={style}>
      <div
        className={`avatar-img ${imgVarient && imgVarient} ${
          !image && "noImg"
        }`}
        style={{
          width: imgWidth,
          height: imgHeight,
          minHeight: imgHeight,
          minWidth: imgWidth,
          ...imgStyle,
        }}
      >
        {image && (
          <img
            src={image}
            height="100%"
            width="100%"
            alt={title && title}
          ></img>
        )}
        {Avatar && Avatar}
      </div>
      <div className="avatar-text">
        <h4 style={{ fontSize: fontSize + "px", ...textStyle }}>
          {title && title}
        </h4>
        {subText && (
          <p style={{ fontSize: fontSize - 2 + "px", ...subTextStyle }}>
            {subText && maxLength
              ? subText.substring(0, maxLength) + ".."
              : subText}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProfileWithName;
