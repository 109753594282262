import React from "react";
import Arrow from "../HelpSideNavbar/images/right-arrow.svg";
import "./ResourceCenterCard.scss";

/**
 * Resource Card Used In ResourceCenterContainer Component
 *
 * @param {String} name
 * @param {String} description
 *
 */

const ResourceCenterCard = ({ name, description }) => {
  return (
    <div className="resorce-center-card">
      <div>
        <h6>{name}</h6>
        <p>{description}</p>
      </div>
      <img src={Arrow} alt="arrow" />
    </div>
  );
};

export default ResourceCenterCard;
