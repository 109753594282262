import Social from "../assets/serivceIcons/social/Signin-Social.webp";
import SocialIcon from "../assets/serivceIcons/social/awesomesocial.svg";
import SocialIconSmall from "../assets/serivceIcons/social/social-icon-1.svg";
import SocialSignUp from "../assets/serivceIcons/social/social-signup.webp";
import SocialOTPImg from "../assets/serivceIcons/social/no-message.webp";

import Sign from "../assets/serivceIcons/sign/sign.svg";
import SignIconSmall from "../assets/serivceIcons/sign/signicon.svg";
import SignIcon from "../assets/serivceIcons/sign/awesomesign-full.svg";

import Dial from "../assets/serivceIcons/dial/dial.webp";
import DialIcon from "../assets/serivceIcons/dial/awesomedial.svg";
import AwesomeDialIcon from "../assets/serivceIcons/dial/awesomedialIcon.svg";

import Send from "../assets/serivceIcons/send/send.webp";

import DeskIconSmall from "../assets/serivceIcons/desk/desk-logo.svg";
import DeskIcon from "../assets/serivceIcons/desk/awesomedesk.svg";
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula";
import React from "react";

export const getLinkToService = (serviceName) => {
  const varient = process.env.REACT_APP_ENV;
  console.log(varient);
  const normal = serviceName?.toLowerCase();

  switch (varient) {
    case "production":
      return getProdLinks(normal);
    case "release":
      return getReleaseLinks(normal);
    case "testing":
      return getReleaseLinks(normal);
    case "dev":
      return getDevLinks(normal);
    case "dev-local":
      return getDevLocalLinks(normal);
    default:
      return getDevLinks(normal);
  }
};

const getProdLinks = (serviceName) => {
  switch (serviceName) {
    case "social":
      return "https://social.byawesome.com/login";
    case "sign":
      return "https://sign.byawesome.com/login";
    case "desk":
      return "https://desk.byawesome.com/login";
    case "dial":
      return "https://dial.byawesome.com/login";
    case "checkout":
      return "https://billing.awesomesuite.com";
    case "accounts":
      return "https://my.awesomesuite.com/login";
    case "mainsite":
      return "https://www.awesomesuite.com";
    default:
      return "/";
  }
};

const getReleaseLinks = (serviceName) => {
  switch (serviceName) {
    case "social":
      return "https://social.betaawesome.com/login";
    case "sign":
      return "https://sign.betaawesome.com/login";
    case "send":
      return "https://send.betaawesome.com/login";
    case "desk":
      return "https://desk.betaawesome.com/login";
    case "dial":
      return "https://dial.betaawesome.com/login";
    case "checkout":
      return "https://billing.betaawesome.com";
    case "accounts":
      return "https://my.betaawesome.com/login";
    case "mainsite":
      return "https://obliqmainnext.netlify.app";
    default:
      return "/";
  }
};

const getDevLinks = (serviceName) => {
  switch (serviceName) {
    case "social":
      return "https://social-dev.betaawesome.com/login";
    case "sign":
      return "https://sign-dev.betaawesome.com/login";
    case "send":
      return "https://send-dev.betaawesome.com/login";
    case "desk":
      return "https://desk-dev.betaawesome.com/login";
    case "dial":
      return "https://dial-dev.betaawesome.com/login";
    case "checkout":
      return "https://billing-dev.betaawesome.com";
    case "accounts":
      return "https://my-dev.betaawesome.com/login";
    case "mainsite":
      return "https://obliqmainnext.netlify.app";
    default:
      return "/";
  }
};

const getDevLocalLinks = (serviceName) => {
  switch (serviceName) {
    case "social":
      return "http://localhost:3000/login";
    case "sign":
      return "http://localhost:3000/login";
    case "desk":
      return "http://localhost:3000/login";
    case "dial":
      return "http://localhost:3000/login";
    case "checkout":
      return "http://localhost:3000";
    default:
      return "/";
  }
};

export const servicesMapping = {
  social: {
    id: 1,
    enable: true,
    link: "https://social-dev.betaawesome.com/login",
    Logo: Social,
    name: "Social",
    color: "#33475B",
    back: "#F5CA32",
    Icon: SocialIcon,
    SignUpIcon: SocialSignUp,
    IconSmall: SocialIconSmall,
    SocialOTPImg: SocialOTPImg,
    CustomBackground: (
      <Grid container justify="center" height="100%" align="center">
        <Grid item xs={10}>
          <img src={Social} alt={"Social"} />
        </Grid>
      </Grid>
    ),
  },
  sign: {
    id: 2,
    enable: true,
    link: "https://sign-dev.betaawesome.com/login",
    Logo: Sign,
    name: "Sign",
    color: "#0082AB",
    Icon: SignIcon,
    SignUpIcon: null,
    IconSmall: SignIconSmall,
    SocialOTPImg: null,
    back: "#E8EAF6",
    CustomBackground: (
      <Grid container justify="center" height="100%" align="center">
        <Grid item xs={6}>
          <img src={Sign} alt={"Sign"} />
        </Grid>
      </Grid>
    ),
  },
  desk: {
    id: 3,
    enable: false,
    name: "Desk",
    link: "https://desk-dev.betaawesome.com/login",
    Logo: null,
    color: "#10aa50",
    Icon: DeskIcon,
    SignUpIcon: null,
    SocialOTPImg: null,
    back: "#004053",
    CustomBackground: null,
  },
  dial: {
    id: 4,
    enable: false,
    name: "Dial",
    link: "https://dial-dev.betaawesome.com/login",
    Logo: Dial,
    color: "#1ec6d8",
    back: "#004053",
    Icon: DialIcon,
    SignUpIcon: null,
    SocialOTPImg: null,
    CustomBackground: (
      <Grid container justify="center" height="100%" align="center">
        <Grid item xs={6}>
          <img src={Dial} alt={"Sign"} />
        </Grid>
      </Grid>
    ),
  },
  send: {
    id: 5,
    enable: false,
    name: "Dial",
    link: "https://obliqdial.netlify.app/login",
    Logo: null,
    color: "#253342",
    back: "#10151C",
    Icon: null,
    SignUpIcon: null,
    SocialOTPImg: null,
    CustomBackground: (
      <Grid container justify="left" height="100%" align="center">
        <Grid item xs={6}>
          <img src={Send} alt={"Send"} />
        </Grid>
        <Grid item xs={6} style={{ position: "relative", left: "-70px" }}>
          <Text varient="h3" color={"#fff"}>
            {"Create & Send Beautiful emails."}
          </Text>
        </Grid>
      </Grid>
    ),
  },
};

export const productMapping = [
  {
    id: 1,
    name: "Social",
    Icon: SocialIconSmall,
    color: "#ff406e",
    description: "Social Media Management Tool.",
    link: "https://social-dev.betaawesome.com/login",
    referLink: (id) =>
      `${getLinkToService("mainsite")}/social/?refer=${id}`,
  },
  {
    id: 2,
    name: "Sign",
    Icon: SignIconSmall,
    color: "#0299FF",
    description: "E-Sign App for e-signature your documents.",
    link: "https://sign-dev.betaawesome.com/login",
    referLink: (id) =>
      `${getLinkToService("mainsite")}/sign?refer=${id}`,
  },
  // {
  //     id: 3,
  //     name: "Desk",
  //     Icon: "",
  //     color: "#ff406e",
  //     description: "Tickets Management software.",
  //     link: "https://desk-dev.betaawesome.com/login"
  // },
//   {
//     id: 4,
//     name: "Dial",
//     Icon: AwesomeDialIcon,
//     color: "#ff406e",
//     description: "Customer support App.",
//     link: "https://dial-dev.betaawesome.com/login",
//     referLink: (id) =>
//       `${getLinkToService("mainsite")}/dial?&refer=${id}`,
//   },
];
