import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Camera } from "../../assets/icons//Brand-profile.svg";
import { createUseStyles } from "react-jss";

import classes from "./imageSelector.module.scss";

const useStyles = createUseStyles({
    image_selector: ({ width, height, color, borderRadius }) => ({
        width: width ?? "100%",
        height: height ?? "100%",
        borderRadius: borderRadius ?? "50%",

        "& path": {
            fill: color ?? "gray",
        },
    }),
    section: ({ small }) => ({
        width: small ? "2rem" : "3rem",
        height: small ? "2rem" : "3rem",
        fontSize: small ? "1.25rem" : "2rem",

        "& svg": {
            width: small ? "1.25rem" : "2rem",
        },
    }),
});

const ImageSelector = ({ src, setSrc, text, disabled, style, ...props }) => {
    const dynamicStyledClasses = useStyles(props);
    const inputRef = useRef(null);
    const [preview, setPreview] = useState(
        src ? (typeof src === "string" ? src : URL.createObjectURL(src)) : null
    );

    useEffect(() => {
        if (!src) {
            setPreview(null);
            return;
        }
        let objectUrl;
        if (typeof src === "string") objectUrl = src;
        else objectUrl = URL.createObjectURL(src);
        setPreview(objectUrl);
    }, [src]);

    const ImageHandler = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSrc(null);
            return;
        }
        setSrc(e.target.files[0]);
    };

    const handleClick = (e) => {
        inputRef.current.click();
    };

    return (
        <div
            className={`${dynamicStyledClasses.image_selector} ${classes.image_selector}`}
            style={{...style}}
            // onClick={handleClick}
        >
            <div
                className={classes.file_label_div}
                style={{
                    backgroundImage: src ? `url(${preview})` : "",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: props.borderRadius ?? "50%",
                }}
            >
                {!src && <div className={classes.div}>{text}</div>}
                {!src ? (
                    <div
                        className={`${dynamicStyledClasses.section} ${classes.section}`}
                        onClick={handleClick}
                    >
                        <Camera />
                    </div>
                ) : (
                    <div
                        className={`${dynamicStyledClasses.section} ${classes.section}`}
                        onClick={(e) => {
                            if (disabled) return;
                            setSrc(null);
                            e.stopPropagation();
                        }}
                    >
                        X
                    </div>
                )}
            </div>
            <input
                id="file"
                type="file"
                name="image"
                accept="image/png, image/jpg, image/jpeg, image/webp"
                disabled={disabled}
                ref={inputRef}
                onChange={ImageHandler}
            />
        </div>
    );
};

export default ImageSelector;
