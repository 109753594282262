import React, { useState, useCallback, useReducer, useEffect } from "react";

import AppBar from "../../Awesome-ui/AppBar/AppBar";
import Form from "./OneSignUp";
import { userApi } from "../../api/axios";
import { servicesMapping } from "../../Data/productsMapping";

import "./OneSignUp.scss";
import { createMessage } from "../../Actions/MessageAction";
import { DANGER } from "../../ActionType/MessageActionTypes";
import AwesomeSuite from "../../assets/platformLogo/awesomesuite_full.svg";
import {
  addSignupData,
  completeSignUpFlow,
  redirectToPage,
  redirectToService,
} from "../../Actions/UserActions";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import firebase from "../../configuration/firebase";
import { PAGE_VIEW } from "../../constants";

const initialState = {
  name: "",
  first: "",
  last: "",
  email: "",
  company: "",
  phone: "",
  password: "",
};

const reducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case "UPDATE_DATA":
      const newState = { ...state };
      newState[payload.name] = payload.value;
      return newState;
    case "SET_DATA":
      return payload.data;
    default:
      return state;
  }
};

const SignUp = ({ track, token: oldToken, signupVoucher, ...props }) => {
  const [state, dispatcher] = useReducer(reducer, initialState);
  const history = useHistory();
  const [service, setService] = useState(null);
  const [referId, setReferId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoader, setpageLoader] = useState(true);
  const [oAuth, setOAuth] = useState(false);
  const [oAuthDetails, setOAuthDetails] = useState(null);
  const { first, last, email, company, phone, password } = state;
  const [token, setToken] = useState(null);
  const [error, seterror] = useState(null);
  const [redirecting, setredirecting] = useState(null);
  const [redirectQuery, setredirectQuery] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const checkLogin = useCallback(() => {
    if (!oldToken) return;
    return history.push(`/`);
  }, [oldToken]);

  useEffect(() => {
    function checkForUrl() {
      const search = props.location.search;
      let parsedUrl;
      if (search) parsedUrl = new URLSearchParams(search);

      let rdParams = "";
      if (parsedUrl) {
        parsedUrl.forEach((val, key, _) => {
          if (key != "service" && key != "rd") {
            rdParams = rdParams + `${key}=${val}&`;
          }
        });
      }
      setredirectQuery(rdParams.slice(0, -1));

      if (parsedUrl && parsedUrl.get("service")) {
        if (servicesMapping[parsedUrl.get("service")]) {
          setService(parsedUrl.get("service").toLowerCase());
          track(PAGE_VIEW, { key: parsedUrl.get("service") });
        } else {
          track(PAGE_VIEW, { key: "accounts" });
          setService(null);
        }
      }

      if (parsedUrl && parsedUrl.get("refer"))
        setReferId(parsedUrl.get("refer"));

      setpageLoader(false);
    }
    checkLogin();
    checkForUrl();
  }, []);

  function oAuthSignUp(user) {
    if (!user) return;
    if (oAuthDetails && oAuthDetails.firebaseId === user.uid) return;
    let provider = user.credential.providerId;
    if (provider === "google.com") provider = "GOOGLE";
    const profile = user.additionalUserInfo.profile;

    const details = {
      user_name: profile.given_name,
      password: profile.id,
      first_name: profile.given_name,
      last_name: profile.family_name,
      email_id: profile.email,
      phone_number: profile.phoneNumber,
      picture: profile.picture,
      firebase_id: user.user.uid,
      provider,
    };

    dispatcher({
      type: "SET_DATA",
      payload: {
        data: {
          name: profile.given_name,
          first: profile.given_name,
          last: profile.family_name,
          email: profile.email,
          company: profile.given_name,
          phone: profile.phoneNumber,
          picture: profile.picture,
          password: "",
        },
      },
    });

    setOAuth(true);
    setOAuthDetails(details);
  }

  /**
   * handle oAuth sign up. available clients | google |
   * sigUp user on our service and then redirect
   * to either on account or to a service if user
   * wants to go to any service.
   */
  const onSubmitOAuth = async (data, details, setPassword) => {
    setLoading(true);

    let provider = details.provider;

    const reqData = {
      user_name: data.displayName,
      password: data.password,
      first_name: details.first_name,
      last_name: data.last_name,
      email_id: details.email_id,
      phone_number: data.phoneNumber,
      firebase_id: details.firebase_id,
      picture: details.picture,
    };

    if (service && servicesMapping[service]) {
      reqData.product_id = servicesMapping[service].id;
    }

    if (referId) reqData.referral_id = referId;

    let resultToken = token;

    if (!resultToken) {
      try {
        const result = await userApi.post(
          `/oauth/signup?action=${provider}`,
          reqData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.code !== 200) {
          firebase.auth().signOut();
          return createMessage(DANGER, result.data.msg);
        }

        await completeSignUpFlow(
          data.email_id,
          data.user_name || data.email_id,
          data.phone_number,
          result.data.model?.auth_token,
          reqData.product_id,
          couponCode
        );

        addSignupData(data.email_id, data.user_name, data.phone_number);
        resultToken = result.data.model.auth_token;
        setToken(resultToken);
      } catch (error) {
        console.log(error);
        setLoading(false);
        firebase.auth().signOut();
        if (error.response) createMessage(DANGER, error.response.data);
        else createMessage(DANGER, "Something went wrong while signup.");
      }
    }

    try {
      setredirecting(true);

      if (setPassword) {
        history.push(
          `/setpassword?p=${resultToken}&service=${service}&${redirectQuery}`
        );
      } else {
        servicesMapping[service]
          ? redirectToService(
              service,
              resultToken,
              "onboard",
              true,
              redirectQuery
            )
          : redirectToPage(resultToken, history, null, true, redirectQuery);
      }

      setLoading(false);
    } catch (error) {
      setredirecting(false);
      if (error.response?.data.msg) {
        seterror(error.response.data?.msg);
      } else {
        seterror(error.message);
      }
      return setLoading(false);
    }
  };

  /**
   * It will call the normal signup form. and send to the verification of email page
   *
   * @param {*} data user name, email, company.
   */
  const onSubmitForm = async (data) => {
    setLoading(true);

    try {
      if (service && servicesMapping[service]) {
        data.product_id = servicesMapping[service].id;
      }

      if (referId) data.referral_id = referId;

      const result = await userApi.put(`/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (result.data.code !== 200) {
        setLoading(false);
        return createMessage(DANGER, result.data.msg);
      }

      addSignupData(data.email_id, data.user_name, data.phone_number);
      if (service) {
        let url = `/signup/confirm?email=${data.email_id}&service=${service}&${redirectQuery}`;
        if (redirectQuery) url += `&${redirectQuery}`;
        if (couponCode) url += `&couponCode=${couponCode}`;

        history.push(url);
      } else {
        let url = `/signup/confirm?email=${data.email_id}`;
        if (couponCode) url += `&couponCode=${couponCode}`;
        if (redirectQuery) url += `&${redirectQuery}`;

        history.push(url);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) createMessage(DANGER, error.response?.data?.message);
      else createMessage(DANGER, "Something went wrong while signup.");
    }
  };

  /**
   * atomic submit functon to submit the sign data.
   *
   * @param {*} countCheck validation checks.
   */
  const submitHandler = (setpassword) => {
    const newEmail = email.trim();
    const newFirst = first?.trim();
    const newLast = last?.trim();
    const newCompany = company.trim();
    const newPhone = phone && phone.trim();

    if (
      newEmail.length === 0 ||
      (newFirst.length === 0 && newLast?.length === 0)
    ) {
      return createMessage(DANGER, "Enter all the Fields");
    }

    const body = {
      user_name: newCompany,
      password: password,
      first_name: newFirst,
      last_name: newLast,
      email_id: email,
      phone_number: newPhone,
    };

    if (oAuth) onSubmitOAuth(body, oAuthDetails, setpassword);
    else onSubmitForm(body);
  };

  if (pageLoader) {
    return null;
  }

  return (
    <div className="signUp" style={{ display: redirecting && "none" }}>
      <AppBar justify="space-between">
        <div style={{ width: "250px" }}>
          <img
            src={AwesomeSuite}
            width="100%"
            height="100%"
            alt={"awesome social"}
          />
        </div>
        <div>
          <div className="noAcc">
            Already have an Account?{" "}
            <Link
              to={service ? `/login?service=${service}` : "/login"}
              className="login-btn"
            >
              Login
            </Link>
          </div>
        </div>
      </AppBar>
      <Form
        error={error}
        Icon={service ? servicesMapping[service].Icon : null}
        btnColor={service ? servicesMapping[service].color : "#33475B"}
        loading={loading}
        onSubmitForm={onSubmitForm}
        onSubmitOAuth={onSubmitOAuth}
        oAuthSignUp={oAuthSignUp}
        state={state}
        oAuth={oAuth}
        dispatcher={dispatcher}
        submitHandler={submitHandler}
        signupVoucher={signupVoucher}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
      />
      {service && servicesMapping[service].SignUpIcon && (
        <div className="productImage">
          <img
            src={servicesMapping[service].SignUpIcon}
            alt={service}
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default SignUp;
