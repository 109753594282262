import {
  LOGIN_USER_BYPASS,
  LOAD_USER_DATA,
  CLEAN_ALL_USER_DATA,
  FORGOT_PASS_ADD_EMAIL,
  FORGOT_PASS_ADD_OTP,
  FORGOT_PASS_ADD_TOKEN,
  LOAD_USER_SESSION,
  ADD_SIGNUP_DATA,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_LOADING,
  GET_ORGANIZATION_FAILED,
  GET_ALL_USERS_IN_ORGANIZATION,
  GET_ALL_USERS_IN_ORG_FAILED,
  GET_ALL_USERS_IN_ORG_LOADING,
  GLOBAL_LOADING
} from "../ActionType/UserActionTypes";

const initialState = {
  globalLoading: true,
  user: null,
  token: localStorage.getItem("auth_token"),
  accessToken: localStorage.getItem("access-token"),
  session: [],
  activeSession: [],
  organization: null,
  organizationLoading: false,
  organizationUsersLoading: false,
  organizationUsers: null,
  forgotPassFlow: {
    email: null,
    otp: null,
    token: null,
  },
  signUpData: {
    companyName: null,
    email: null,
    phoneNumber: null,
  },
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_BYPASS:
      return {
        ...state,
        token: action.payload.token,
        accessToken: action.payload.accessToken,
      };
    case LOAD_USER_DATA:
      return { ...state, user: action.payload };
    case CLEAN_ALL_USER_DATA:
      localStorage.removeItem("auth_token");
      localStorage.removeItem("access-token");
      return { ...state, user: null, token: null };
    case FORGOT_PASS_ADD_EMAIL:
      return {
        ...state,
        forgotPassFlow: {
          ...state.forgotPassFlow,
          email: action.payload.email,
        },
      };
    case FORGOT_PASS_ADD_OTP:
      return {
        ...state,
        forgotPassFlow: {
          ...state.forgotPassFlow,
          otp: action.payload.otp,
          token: action.payload.token,
        },
      };
    case FORGOT_PASS_ADD_TOKEN:
      return {
        ...state,
        forgotPassFlow: {
          ...state.forgotPassFlow,
          token: action.payload.token,
        },
      };
    case LOAD_USER_SESSION:
      return {
        ...state,
        session: action.payload.session,
        activeSession: action.payload.activeSession,
      };
    case ADD_SIGNUP_DATA:
      return {
        ...state,
        signUpData: {
          email: action.payload.email,
          companyName: action.payload.companyName,
          phoneNumber: action.payload.phoneNumber,
        },
      };
    case GET_ORGANIZATION_DETAILS:
      return {
        ...state,
        organization: action.payload,
        organizationLoading: false,
      };
    case GET_ORGANIZATION_LOADING:
      return {
        ...state,
        organizationLoading: true,
      };
    case GET_ORGANIZATION_FAILED:
      return {
        ...state,
        organization: null,
        organizationLoading: false,
      };
    case GET_ALL_USERS_IN_ORG_LOADING:
      return {
        ...state,
        organizationUsersLoading: true,
      };
    case GET_ALL_USERS_IN_ORGANIZATION:
      return {
        ...state,
        organizationUsersLoading: false,
        organizationUsers: action.payload.data,
      };
    case GET_ALL_USERS_IN_ORG_FAILED:
      return {
        ...state,
        organizationUsersLoading: false,
      };
    case GLOBAL_LOADING: 
      return {
        ...state,
        globalLoading: !!action.payload.enable
      }
    default:
      return state;
  }
};
