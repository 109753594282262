import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import Form from "./UniverselLogin";
import "./UniverselLogin.scss";
import { userApi } from "../../api/axios";
import {
  LogUserWithToken,
  oAuthLogin,
  redirectToPage,
  forgotpassAddEmail,
  redirectToService,
  addUserRole,
  handleChallenges,
  getAccessToken,
  logoutUser,
} from "../../Actions/UserActions";
import Awesome from "../../assets/LoginIcon/account-signin.svg";
import { createMessage } from "../../Actions/MessageAction";
import { DANGER } from "../../ActionType/MessageActionTypes";
import Awesomesuite from "../../assets/platformLogo/awesomesuite_full.svg";
import firebase, {
  signWithGoogle,
  signInWithToken,
} from "../../configuration/firebase";
import { useHistory } from "react-router-dom";
import { LOGIN, PAGE_VIEW } from "../../constants";
import { servicesMapping } from "../../Data/productsMapping";
import googleOneTap from "google-one-tap";

const options = {
  client_id: process.env.REACT_APP_ONE_TAP_CLIENT,
  auto_select: false,
  cancel_on_tap_outside: false,
  context: "signin",
};

let isMounted = false;
let loginSuccess = false;
const SignInPage = ({ track, token, ...props }) => {
  const [uiLoading, setuiLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState(false);
  const [redirectionURL, setredirectionURL] = useState(null);
  const history = useHistory();
  const [redirectQuery, setredirectQuery] = useState("");

  useLayoutEffect(() => {
    track(PAGE_VIEW, { page: "login" });
    try {
      googleOneTap(options, async (response) => {
        if (!response.credential || token) return;
        let data = await signInWithToken(response.credential);
        oAuthSignIn(data);
      });
    } catch (error) {}
  }, []);

  async function oAuthSignIn(user) {
    if (!user) return;
    const tokens = await firebase.auth()?.currentUser?.getIdToken();
    const email = user?.additionalUserInfo?.profile?.email;
    track(LOGIN, {
      title: "using login",
      email: email,
      method: "OAUTH",
    });
    loginSuccess = true;
    oAuthLogin(tokens, "login", { service, email, redirectionURL });
  }

  const loginOrRedirectWithToken = async (app, rd, rdParams, d) => {
    if (loginSuccess) return;

    if (!token) {
      setuiLoading(false);
      return (
        window.location.search.includes("101") &&
        (window.location.search = window.location.search.replace("d=101", ""))
      );
    }

    if (loading) return;

    if (d == "101") {
      setuiLoading(false);
      logoutUser();
      return (
        window.location.search.includes("101") &&
        (window.location.search = window.location.search.replace("d=101", ""))
      );
    }

    let newToken = await getAccessToken(token);
    if (!newToken) {
      return logoutUser();
    }

    if (!app) {
      LogUserWithToken({ token: newToken, accessToken: "" });
      return history.push(`/`);
    }

    if (app && !rd) redirectToService(app, newToken, false, null, rdParams);
    else redirectToPage(newToken, history, rd, null, rdParams);
  };

  useEffect(() => {
    async function checkForUrl() {
      const search = props.location.search;
      if (!search) return await loginOrRedirectWithToken();

      let parsedUrl = new URLSearchParams(search);
      if (!parsedUrl) return;

      let query = {
        service: null,
        rd: null,
      };
      let rdParams = "";
      if (parsedUrl) {
        parsedUrl.forEach((val, key, _) => {
          if (key != "service" && key != "rd") {
            rdParams = rdParams + `${key}=${val}&`;
          }
        });
      }
      setredirectQuery(rdParams.slice(0, -1));

      if (parsedUrl.get("service")) {
        if (servicesMapping[parsedUrl.get("service")]) {
          setService(parsedUrl.get("service"));
          query.service = parsedUrl.get("service");
        } else setService(null);
      }
      if (parsedUrl.get("rd")) {
        setredirectionURL(decodeURIComponent(parsedUrl.get("rd")));
        query.rd = decodeURIComponent(parsedUrl.get("rd"));
      }
      if (parsedUrl.get("tool") && parsedUrl.get("tool") === "google") {
        openGoogle();
      }

      loginOrRedirectWithToken(
        query.service,
        query.rd,
        rdParams,
        parsedUrl.get("d")
      );
    }
    checkForUrl();
  }, []);

  function openGoogle() {
    if (isMounted) return;
    signWithGoogle(oAuthSignIn);
    isMounted = true;
  }

  /**
   * Redirection handler
   * @param {*} email - email
   * @param {*} password - password
   */
  const submitHandler = async (email, password) => {
    setLoading(true);
    try {
      const result = await userApi.get(
        `/login?email=${email}&password=${encodeURIComponent(password)}`
      );

      const obj = result.data?.model;

      if (result.data.code === 200) {
        loginSuccess = true;
        const token = obj.auth_token;
        const org = obj.organisations;

        if (obj.challenge != null && obj.challenge.length > 1) {
          return handleChallenges(obj.challenge, history, {
            email: email,
            service: service,
            token: token,
          });
        }

        addUserRole(org);
        track(LOGIN, { title: "using login", email, method: "Awesomesuite" });
        if (service && !redirectionURL)
          redirectToService(service, token, false, null, redirectQuery);
        else
          redirectToPage(token, history, redirectionURL, null, redirectQuery);

        // setLoading(false);
        LogUserWithToken({ token: token, accessToken: "" });
      } else {
        setLoading(false);
        if (result.data.msg === "Failure: account is not verified!") {
          loginSuccess = true;
          await forgotpassAddEmail(email);
          history.push({
            pathname: `/signup/confirm`,
            search: `?email=${email}`,
          });
        }
        createMessage(DANGER, result.data.msg);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) createMessage(DANGER, error.response.data);
      else createMessage(DANGER, "Something went wrong while signing in.");
    }
  };

  if (uiLoading) return null;

  return (
    <div className="signIn green-bg">
      <div
        className="right"
        style={{
          backgroundColor: service ? servicesMapping[service].back : "#eaf0f6",
        }}
      >
        {service ? (
          servicesMapping[service].CustomBackground
        ) : (
          <img src={Awesome} alt="awesome" />
        )}
      </div>
      <div className="left">
        <div className="iconContainer" style={{ marginLeft: "1rem" }}>
          <div className="icon">
            <img src={Awesomesuite} alt="SignInImage" />
          </div>
        </div>
        <Form
          btnColor={service ? servicesMapping[service].color : "#33475B"}
          serviceColor={""}
          loading={loading}
          oAuthSignIn={oAuthSignIn}
          submitApiHandler={submitHandler}
          service={service}
          redirectQuery={redirectQuery}
          logo={servicesMapping[service]?.Icon}
        />
      </div>
    </div>
  );
};

export default SignInPage;
