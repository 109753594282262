import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme) => ({
  page: {
    maxHeight: "92vh",
    overflowY: "auto",
    overflowX: "hidden"
  },
  leftBottom: {
    marginTop: "auto",
    display: "flex",
    gap: 6,
    marginBottom: 12,
  },
  btnMain: {
    color: "#fff",
    background: "#2ABBFE",
    border: "1px solid transparent",
  },
  card: {
    border: "1px solid #DBE3ED",
    boxShadow: "0px 3px 6px #DBE3ED",
    padding: 12,
    display: "flex",
    marginBottom: 12,
  },
  updatebtn: {
    marginLeft: "auto",
    color: "#00A7FD",
    padding: "6px 12px",
    height: "fit-content",
    cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
  planLeft: {
    flex: 1,
    display: "flex",
  },
  addCard: {
    width: 280,
    padding: 18,
  },
  planbtnRadio: {
    marginRight: 12,
  },
  planDescription: {
    margin: "0",
    marginRight: "2rem",
    marginBottom: "1.5rem",
  },
  planDetails: {
    width: "100%",
    marginTop: "2rem",
    display: "flex",
    gap: 12,
  },
  addControls: {
    marginTop: "1.5rem",
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  planCard: {
    width: "100%",
    border: "1px solid #DBE3ED",
    padding: 12,
    display: "flex",
    marginBottom: 12,
    alignItems: "center",
    cursor: "pointer",
  },
  sbhText: {
    color: "#8EA1B9",
    marginBottom: 12,
    fontSize: 12,
  },
  lightTxt: {
    color: "#425C77",
    fontSize: 15,
  },
  planItem: {
    border: "1px solid #DBE3ED",
    padding: 12,
    display: "flex",
    marginBottom: 12,
  },
  displayFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  displayFlexSpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkBox: {
    width: 20,
    height: 20,
    accentColor: "#169bc9",
  },
  addCardTitle: {
    color: "#33475B",
    fontSize: 18,
  },
  checkoutBox: {
    width: "100%",
    border: "1px solid #DFE3EB",
    padding: "16px 18px",
    paddingBottom: 0,
  },
  addOns: {
    padding: "8px 16px",
  },
  addonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "12px 0",
  },
  addonCard: {
    padding: "8px 12px",
    border: "1px solid #F5C16B",
    borderRadius: 4,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addOnCardTitle: {
    marginBottom: 8,
    color: "#33475B",
    fontWeight: 500,
  },
  cancleBtn: {
    width: 28,
    height: 28,
    borderRadius: "50%",
    background: "#EBF0F6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "145%",
  },
  details: {
    borderTop: "1px dashed #99ACC2",
    padding: 8,
  },
  total: {
    borderTop: "1px dashed #99ACC2",
    padding: 8,
  },
  payBtn: {
    marginTop: "1.5rem",
    fontSize: "1.1rem",
    cursor: "pointer",
    width: "100%",
    height: 50,
    border: "1px solid transparent",
    backgroundColor: "#334760",
    transition: "300ms ease-in-out",
    color: "#fff",
    "&:hover": {
      border: "1px solid #092f42",
      backgroundColor: "#233245",
    },
  },
  plansWrapper: {
    marginTop: "2rem",
    display: "flex",
  },
  plans: {
    maxWidth: "90%",
    margin: "auto",
    display: "flex",
  },
  pricingCard: {
    width: 270,
    display: "block",
    height: 500,
  },
  myAcc: {
    padding: "6px 12px",
    border: "1px solid #d3d3d3",
    borderRadius: 6,
    marginRight: 12,
    cursor: "pointer",
  },
  coupon: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  couponInput: {
    width: "72%",
    border: "1px solid #d3d3d3",
    fontSize: 18,
    height: 44,
    padding: "12px 16px",
    outline: "none",
  },
  addCouponBtn: {
    width: "22%",
    height: 44,
    border: "1px solid #24354a",
    padding: "12px 16px",
    outline: "none",
    background: "#334760",
    color: "#fff",
    cursor: "pointer",
  },
  saveBill: {
    border: "1px solid #84D8F6",
    background: "#E5F2FD",
    padding: "8px",
    justifyContent: "space-between",
  },
  couponSelect: {
    margin: "1rem 0",
    padding: 12,
    background: "#FEF8F0",
    border: "1px dashed #F5C26B",
    justifyContent: "space-between",
  },
  removeCouponBtn: {
    color: "#fff",
    cursor: "pointer",
    outline: "none",
    border: "none",
    background: "transparent",
  },
  btnBase: {
    height: "fit-content",
    outline: "none",
    background: "#fff",
    border: "1px solid #CBD6E2",
    padding: "12px 24px",
    cursor: "pointer",
    borderRadius: 4,
  },
  quantity: {
    display: "flex",
    width: 75,
    borderRadius: 4,
    alignItems: "center",
    border: "0.5px solid #d3d3d3",
    justifyContent: "space-between"
  },
  quantityBtn: {
    display: "flex",
    outline: "none",
    borderRadius: 4,
    padding: 2,
    border: "none",
    cursor: "pointer"
  },
  quantityNum: {
    padding: "0px 8px"
  },
  noMargin: {
    margin: 0
  },
  sectionMargin: {
    margin: "1rem 0px"
  }
}));
