import React from "react";
import { NavLink } from "react-router-dom";
import ToolTip from "../ToolTip/index";

/**
 * Icon for the side nav bar
 *
 * @param {String} src
 * @param {String} active
 * @param {function} clicked
 * @param {Number} ind
 * @param {String} name
 * @param {String} link
 */
const Icon = ({ src, active, clicked, SvgIcon, ind, name, link }) => {
  return (
    <NavLink
      to={link}
      exact
      // onClick={() => clicked(ind)}
      className={`icon`}
    >
      <div className="svg-icon">{SvgIcon && <SvgIcon />}</div>
      <span>{name.toUpperCase()}</span>
      <ToolTip
        direction="right"
        name={name.toUpperCase()}
        color={"#fff"}
        background="Default"
      />
    </NavLink>
  );
};

export default Icon;
