import React, { useState, useEffect } from "react";
import AppBar from "../../Awesome-ui/AppBar/AppBar";
import { servicesMapping } from "../../Data/productsMapping";
import { useTracking } from "react-tracking";
import { logoutUser } from "../../Actions/UserActions";
import { connect } from "react-redux";
import User from "../../Components/Layout/User";

import {
  getSubscribedPlan,
  getPlans,
  getPlanDetailsById,
  subscriptionAction,
  getCreditAmount,
} from "../../Actions/transactionAction";
import { useStyles } from "./style";
import BillingComponent from "./BillingComponent";
import Plans from "./Plans";
import { useHistory } from "react-router-dom";
import { service_id_mapping } from "./staticPlans";
import { COST_CALC, VERIFY_PLAN } from "../../constants";
import SmallActionModel from "../../Awesome-ui/SmallActionModel/SmallActionModel";
import { Grid, Text, PageLoader } from "@awesomesuite-frontend/awesome-nebula";

const BillingDetails = ({
  plans,
  subscription,
  plansPrice,
  subscriptionMapping,
  user,
  token,
  orgId,
  ...props
}) => {
  const [userDropDown, setUserDropDown] = useState(false);
  const { Track, trackEvent } = useTracking({ page: "billing" });
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openTab, setopenTab] = useState(false);
  const [service, setservice] = useState(false);
  const [serviceId, setserviceId] = useState(false);
  const history = useHistory();
  const [currentPlan, setcurrentPlan] = useState(null);
  const [newPlan, setnewPlan] = useState(null);
  const [nextPlanDetails, setNextPlanDetails] = useState(null);
  const [verifyNewPlan, setverifyNewPlan] = useState(null);
  const [costCalculated, setcostCalculated] = useState(null);
  const [openConfirmModel, setopenConfirmModel] = useState(null);
  const [credit, setcredit] = useState(null);
  const [changePlanLoading, setchangePlanLoading] = useState(null);

  useEffect(() => {
    async function init() {
      if (!props.location) return;
      const search = props.location.search;
      const searchParser = new URLSearchParams(search);
      if (searchParser.get("service")) {
        let id = service_id_mapping[searchParser.get("service").toLowerCase()];
        setservice(searchParser.get("service"));
        setserviceId(id);
        if (!plans || !plans[id]) await getPlans(id);
        if (!subscription) await getSubscribedPlan();
      }
    }
    init();
  }, [props.location.search, token]);

  useEffect(() => {
    async function init() {
      if (!subscription || !plans || !plans[serviceId]) return;

      let isplanSubscribed = subscription.find(
        (item) => item.productId == serviceId
      );
      if (!isplanSubscribed) setopenTab(true);
      let currentPlan = await getPlanDetailsById(
        isplanSubscribed.planId,
        isplanSubscribed.productId
      );
      currentPlan.endDate = isplanSubscribed.endDate;
      currentPlan.isActive = true;
      currentPlan.options = plansPrice[serviceId][currentPlan.title] || [];
      setcurrentPlan(currentPlan);

      plans[serviceId].map((item) => {
        if (
          isplanSubscribed?.referenceNextPlanId !== isplanSubscribed?.planId &&
          isplanSubscribed?.referenceNextPlanId === item.id
        ) {
          setNextPlanDetails(item);
        }
      });
      setLoading(false);
    }
    init();
  }, [plans, subscription, service]);

  function openPlan() {
    setopenTab(true);
  }
  function closePlan() {
    setopenTab(false);
  }

  async function selectNewPlan(planType, data, confirm) {
    if (changePlanLoading) return false;

    let planAlternative =
      plansPrice[serviceId] && plansPrice[serviceId][planType];
    let planDetails = await getPlanDetailsById(data.id, serviceId);

    let newPlan = {
      ...planDetails,
      options: planAlternative,
      amount: data.amount,
      type: data.name,
      title: planType,
      isActive: currentPlan.id === data.id,
      downgrade: data.downgrade,
    };

    setnewPlan(newPlan);
    if (data?.id === currentPlan?.id) {
      setcredit(null);
      setcostCalculated(null);
      setopenTab(false);
      setchangePlanLoading(null);
      return true;
    }

    if (!confirm) {
      return await selectNewPlanAfterConfirm(planType, data);
    }
    let costCal = await subscriptionAction(COST_CALC, {
      newPlanId: newPlan.id,
      productId: serviceId,
      orgId,
    });

    if (!costCal?.verifiedPlan) {
      return handleVerifyPlanChange(costCal);
    }

    let creditAmount = await getCreditAmount();
    setcredit(creditAmount);
    setcostCalculated(costCal);
    setopenTab(false);
    setchangePlanLoading(null);
    return true;
  }

  async function selectNewPlanAfterConfirm(planType, data) {
    setchangePlanLoading(data.id);
    let verifyData = await subscriptionAction(VERIFY_PLAN, {
      newPlanId: data.id,
      productId: serviceId,
      orgId,
      quantityPlan: 1,
    });
    if (!verifyData) {
      setchangePlanLoading(null);
      return false;
    }

    if (verifyData?.verifiedPlan) {
      setverifyNewPlan(null);
      await selectNewPlan(planType, data, true);
      setchangePlanLoading(null);
      return true;
    }
    handleVerifyPlanChange(verifyData);
    setopenConfirmModel({ planType, data, msg: verifyData.errorMessage });
    setchangePlanLoading(null);
    return false;
  }

  function handleVerifyPlanChange(verifyData) {
    let subscribedPlan = subscription.find(
      (item) => item.planId === currentPlan.id
    );

    let extraResource = {
      id: currentPlan.id,
      referenceNextPlanId: subscribedPlan?.referenceNextPlanId,
      remainingNoOfAccounts: verifyData?.difference?.accounts,
      remainingNoOfUser: verifyData?.difference?.users,
      remainingStorageLimit: verifyData?.difference?.storageLimit,
    };
    setverifyNewPlan(extraResource);
  }

  function handleConfirm() {
    selectNewPlan(openConfirmModel.planType, openConfirmModel.data, true);
    setopenConfirmModel(null);
  }

  if (!service) {
    return null;
  }

  return (
    <Track>
      <AppBar justify="space-between">
        <div>
          <img src={servicesMapping[service]?.Icon} width="100%" />
        </div>
        <div className={classes.displayFlexSpace}>
          <div className={classes.myAcc} onClick={() => history.push("/")}>
            My account
          </div>
          <User
            userDetails={user}
            logoutHandler={logoutUser}
            showUserDropDown={userDropDown}
            setShowUserDropDown={setUserDropDown}
            track={(type, data) => trackEvent({ type, ...data })}
          />
        </div>
      </AppBar>
      {loading && (
        <Grid container align="center" justify="center" height="92vh">
          <Grid item xs={1}>
            <PageLoader
              size="10px"
              circleColor="	#0096FF"
              interval="1750ms"
              style={{ marginLeft: "3rem" }}
            />
          </Grid>
        </Grid>
      )}
      {openTab ? (
        <Plans
          product={service?.toLowerCase()}
          plans={plans && plans[serviceId]}
          subscription={subscription}
          classes={classes}
          loading={changePlanLoading}
          currentPlan={currentPlan}
          closePlan={closePlan}
          upgradePlan={selectNewPlanAfterConfirm}
          nextPlanDetails={nextPlanDetails}
          isNew={newPlan !== null}
          plansPrice={plansPrice && plansPrice[serviceId]}
        />
      ) : (
        <BillingComponent
          service={service}
          classes={classes}
          token={token}
          plans={plans}
          user={user}
          serviceId={serviceId}
          nextPlanDetails={nextPlanDetails}
          updateCurrentPlan={selectNewPlan}
          currentPlan={newPlan ? newPlan : currentPlan}
          isNew={newPlan !== null}
          subscription={currentPlan}
          selectNewPlan={openPlan}
          plansPrice={plansPrice && plansPrice[serviceId]}
          orgId={orgId}
          costCalculated={costCalculated}
          extraResource={verifyNewPlan}
          credit={credit}
          changePlanLoading={changePlanLoading}
        />
      )}
      <SmallActionModel
        open={openConfirmModel}
        close={() => setopenConfirmModel(null)}
        maxWidth={500}
        color="warn"
        noBorder
        title="Plase confirm your selection"
      >
        <Text varient="subText" margin={0} marginBottom={12}>
          {openConfirmModel?.msg}
        </Text>
        <button className={`${classes.btnBase}`} onClick={handleConfirm}>
          Continue
        </button>
        <button
          className={`${classes.btnBase}`}
          onClick={() => setopenConfirmModel(null)}
        >
          Cancel
        </button>
      </SmallActionModel>
    </Track>
  );
};

const mapStateToProps = ({ UserReducer, transactionReducer }) => ({
  user: UserReducer.user,
  token: UserReducer.token,
  plans: transactionReducer.plans,
  plansPrice: transactionReducer.planPrice,
  subscription: transactionReducer.subscription,
  subscriptionMapping: transactionReducer.subscriptionMapping,
  orgId: UserReducer.organization?.id,
});

export default connect(mapStateToProps)(BillingDetails);
