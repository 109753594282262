import React from "react";
import "./Button.css";

const Button = ({ varient, color, disabled, round, style, ...props }) => {
  return (
    <button
      className={`custom-button 
      ${varient && "custom-button-" + varient}
      ${color && "color-" + color}
      ${round && "aui-btn-round"}
      ${disabled && "aui-btn-disabled"}
      `}
      style={{ ...style }}
      {...props}
    >
      <span>{props.children}</span>
    </button>
  );
};

export default Button;
