import React from "react";

import facebook from "../../assets/pricing/social/socials/facebook.svg";
import instagram from "../../assets/pricing/social/socials/instagram.svg";
import twitter from "../../assets/pricing/social/socials/twitter.svg";
import pinterest from "../../assets/pricing/social/socials/pinterest.svg";
import linkedin from "../../assets/pricing/social/socials/linkedin.svg";

import SocialPrice1 from "../../assets/pricing/social/price1.webp";
import SocialPrice2 from "../../assets/pricing/social/price2.webp";
import SocialPrice3 from "../../assets/pricing/social/price3.webp";

import SignPrice1 from "../../assets/pricing/sign/price1.svg";
import SignPrice2 from "../../assets/pricing/sign/price2.svg";

import DialPrice1 from "../../assets/pricing/dial/price1.svg";
import DialPrice2 from "../../assets/pricing/dial/price2.svg";
import DialPrice3 from "../../assets/pricing/dial/price3.svg";

export let socialPrice = [
  {
    color: "#FFB404",
    img: SocialPrice1,
    plan: "Professionals",
    description:
      "For individuals just and businesses getting started managing their social media.",
    amount: 0,
    brands: 1,
    // options: [
    //   {
    //     value: 1,
    //     name: "1 Brand",
    //   },
    //   {
    //     value: 3,
    //     name: "3 Brand",
    //   },
    //   {
    //     value: 5,
    //     name: "5 Brand",
    //   },
    // ],
    calText: "$ 7 per brand per month",
    users: 1,
    socials: [facebook, instagram, twitter, pinterest, linkedin],
    featureTitle: "Manage multiple social accounts:",
    features: [
      "100 posts /social profile",
      "Published/Scheduled posts",
      "Content scheduling",
      "Multi-channel publishing",
      "Publishing calendar",
      "👋 Hey.bio  customization",
      <>&nbsp;</>,
    ],
  },
  {
    color: "#F2547D",
    img: SocialPrice2,
    plan: "Business",
    description:
      "For teams that want to scale their social media marketing and collaboration.",
    amount: 10,
    brands: 1,
    // options: [
    //   {
    //     value: 1,
    //     name: "1 Brand",
    //   },
    //   {
    //     value: 3,
    //     name: "3 Brand",
    //   },
    //   {
    //     value: 5,
    //     name: "5 Brand",
    //   },
    // ],
    isPopular: true,
    calText: "$ 10 per brand per month",
    users: 9,
    socials: [facebook, instagram, twitter, pinterest, linkedin],
    featureTitle: "Setup workflows and collaborate:",
    features: [
      "Everything in Professional, plus:",
      "200 posts /social profile",
      "Post collaboration",
      "Instagram grid planning",
      "Invite team members",
      "Approval workflow",
      <>&nbsp;</>,
    ],
  },
  {
    color: "#635AC7",
    img: SocialPrice3,
    plan: "Agency",
    description:
      "Powerful tools for agencies with 10 or more users and social channels.",
    amount: 7,
    brands: 10,
    options: null,
    calText: "Starts at 10 Brands (70 Social Channels)",
    users: 10,
    socials: [facebook, instagram, twitter, pinterest, linkedin],
    featureTitle: "Manage clients and teams:",
    features: [
      "Everything in Team, plus:",
      "Unlimited posts",
      "Content scheduling",
      "Manage clients & team members",
      "Agency-friendly pricing    ",
      "Personalized onboarding",
      "Priority support",
    ],
  },
];

export let signPlan = [
  {
    color: "#CBD6E2",
    img: null,
    plan: "Free",
    description:
      "Sign and send documents quickly and for free.It’s free forever!",
    amount: 0,
    calText: <>&nbsp;</>,
    featureTitle: "Send Documents for signing",
    features: [
      "Single user only",
      "3 documents/month",
      "Track Overall Signing Progress",
      "Document Signing Link",
      "Templates: 0",
      "Audit trail",
    ],
  },
  {
    color: "#00B3E6",
    img: SignPrice1,
    plan: "Professionals",
    description:
      "Best for individual professionals looking to sign documents and send documents for signature.",
    amount: 5,
    isPopular: true,
    calText: "/ month",
    featureTitle: "Create signature workflows",
    features: [
      "Single User Only",
      "Unlimited eSignatures",
      "Templates: 10",
      "Signing workflows",
      "Integrations (Dropbox, Google Drive)",
      "Document and folder management",
    ],
  },
  {
    color: "#6A78D1",
    img: SignPrice2,
    plan: "Business",
    description:
      "Create documents using templates or the built-in editor. Collect eSignatures and track documents in real time.",
    amount: 15,
    calText: "/ month per user",
    featureTitle: "Invite Team Members",
    features: [
      "Everything in Professional, plus:",
      "Unlimited eSignatures",
      "Unlimited Sign Requests",
      "Templates: Unlimited",
      "Template sharing",
      "Signer Authentication",
    ],
  },
];

export let dailPlan = [
  {
    color: "#00BDA5",
    img: DialPrice1,
    plan: "Standard",
    description:
      "Business phone for Startups that’s easy to set up and running.",
    amount: 10,
    calText: "Per user/per month",
    featureTitle: "Simple Business Phone System",
    features: [
      "Buy Local Numbers",
      "Buy Toll-free Numbers",
      "Voicemail Drop",
      "Call Notes",
      "Voicemail to text transcription",
      "Call recording",
    ],
  },
  {
    color: "#00B8D1",
    background: "#004053",
    img: DialPrice2,
    plan: "Professionals",
    description:
      "Powerful solution for businesses and teams  looking for better collaboration and boost productivity and performance.",
    amount: 20,
    isPopular: true,
    calText: "Per user/per month",
    featureTitle: "The essentials to get your team talking:",
    features: [
      "Everything in Standard, plus:",
      "Business Hours",
      "Ring Groups",
      "Country Blocking",
      "Basic IVR",
      "Custom Greetings",
    ],
  },
  {
    color: "#6A78D1",
    img: DialPrice3,
    plan: "Enterprise",
    description: "For businesses and organisations with custom requirements.",
    connect: "Let's Connect",
    calText: <>&nbsp;</>,
    featureTitle: "Customized to your business needs",
    features: [
      "Custom contract and pricing",
      "Audit log",
      "Dedicated account manager",
      "Personalized Onboarding",
      "Priority support",
      <>&nbsp;</>
    ],
  },
];

export const service_id_mapping = {
  social: 1,
  sign: 2,
  dial: 3,
};
export const product_price_mapping = {
  social: socialPrice,
  sign: signPlan,
  dial: dailPlan,
};
