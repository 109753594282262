import React from "react";
import "./Tab.scss";    

/**
 * This is component for the Tabs Componet internal use 
 */
const Tab = ({ activeTab, handleChange, index, label, noBorder, ...props }) => {
    return (
    <div
      className={`tab-label-base ${activeTab === index ? "tab-label-active" : "tab-label"} ${noBorder ? "" : "tab-label-border"}`}
      onClick={() => {
        index !== null && handleChange(index);
      }}
    >
      {label}
    </div>
  );
};

export default Tab;
