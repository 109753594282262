import React, { useEffect } from "react";
import "./App.css";

import { Provider } from "react-redux";
import Layout from "./Components/Layout/Layout";
import { PersistGate } from "redux-persist/integration/react";
import { store, persister } from "./store";
import { ThemeContext } from "@awesomesuite-frontend/awesome-nebula";

function App() {
  
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "iiqlje3c2r");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persister}>
        <ThemeContext>
          <div className="App" style={{ height: "100vh" }}>
            <Layout />
          </div>
        </ThemeContext>
      </PersistGate>
    </Provider>
  );
}

export default App;
