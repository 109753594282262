import React from "react"
import SideBarHeader from "../SideBarHeader/SideBarHeader"
import ResourceCenterContainer from "../ResourceCenterContainer/ResourceCenterContainer"
import HelpSupportContainer from "../HelpSupportContainer/HelpSupportContainer"
import "./HelpSideNavbar.scss"

/**
 * Help SideNavBar for helping customers
 * component is is the root component for the help side bar.
 * and requires following components to work.
 *  - {@link ResourceCenterContainer} for the links to the help services 
 *  - {@link HelpSupportContainer} for other optional links..
 * 
 * returns component......
 */
const HelpSideNavbar = ({ helpOpen, closeSideBar }) => {
    function close() {
        closeSideBar(false)
    }

    return (
        helpOpen && (
            <div className="help-sidenavbar">
                <SideBarHeader
                    setShowBar={close}
                    title="Help & getting started"
                    color="#6A78D1"
                />
                <div className="background" />
                <ResourceCenterContainer />
                <HelpSupportContainer />
            </div>
        )
    )
}

export default HelpSideNavbar
