import React from "react";
import Awesome from "../../../assets/platformLogo/awesomesuite_full.svg";

const TopLogo = () => {
  return (
    <div className="aui-awesome-logo">
      <img src={Awesome} alt="awesomesuite.com" />
    </div>
  );
};

export default TopLogo;
